import { useCallback, useEffect, useState } from "react";

export function GetWidthWindow(elementRef: any) {
    const [width, setWidth] = useState<any>(null);

    const updateWidth = useCallback(() => {
        if (elementRef && elementRef.current) {
            const { width } = elementRef.current.getBoundingClientRect();
            setWidth(width);
        }
    }, [elementRef]);

    useEffect(() => {
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        }
    }, [updateWidth])

    return [width]
};