import authoriedAhiRequest from '../authoriedAhiRequest';

class LocalizationService {
    getLanguages = () => {
        return authoriedAhiRequest.get('/loc/languages/?application=aa');
    };
}

const localizationService = new LocalizationService();
export default localizationService;
