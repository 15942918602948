import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { cleanLocalCache } from '_core/helpers/Utils';
import { useSignalRStore } from '../../../store/Shared/signalRStore';
import { ErrorPageWrapper } from './Styled';

const Error403: FC = observer(() => {
    const signalRStore = useSignalRStore();
    const auth = useAuth();
    const { t } = useTranslation();

    const handleLogout = () => {
        signalRStore.disconnect();
        cleanLocalCache();
        auth.signoutRedirect(
            {
                state: {
                    redirect: window.location.href.replace(window.location.origin, "")
                }
            }
        );
    };

    return (
        <ErrorPageWrapper>
            <div>
                <p className="error-code">403</p>
                <h1>Forbidden</h1>
                <h4>You do not have permission for this action</h4>
                <Button type="primary" onClick={handleLogout}>
                    Logout
                </Button>
            </div>
        </ErrorPageWrapper>
    );
});

export default Error403;
