import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { observer } from 'mobx-react-lite';
import { useAuthenStore } from 'store/Auth/AuthStore';

type RequireAuthProps = {

};

export const RequireAuth: React.FC<RequireAuthProps> = observer(({ children }) => {
    const auth = useAuth();
    const authStore = useAuthenStore();

    useEffect(() => {
        const handleAccessTokenExpired = () => {
            authStore.setSessionExpired(true);
        };
        const handleAccessTokenExpiring = () => {
            // Silent renew project token
            const { id, tenantId, subscriptionId } = authStore.selectedProject;
            authStore.updateAccessToken(tenantId, subscriptionId, id);
        };
        auth.events.addAccessTokenExpiring(handleAccessTokenExpiring);
        auth.events.addAccessTokenExpired(handleAccessTokenExpired);

        return () => {
            auth.events.removeAccessTokenExpiring(handleAccessTokenExpiring);
            auth.events.removeAccessTokenExpired(handleAccessTokenExpired);
        };
    }, [auth.events, auth.signinSilent]);

    const handleRenderChildrenProps = () => {
        if (auth.isLoading) {
            return <></>;
        }

        if (auth.error) {
            return <div>{auth.error.message}</div>;
        }

        if (!auth.isAuthenticated) {
            auth.signinRedirect();
            return <></>;
        }
        return children;
    };

    return <>{handleRenderChildrenProps()}</>;
});