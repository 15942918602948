import { ThemeProvider } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { AppRoutes } from './routes';
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import { RequireAuth } from './pages/Authen/RequireAuth';
import { FallbackView } from './layouts/FallbackView';
import './assets/scss/styles.scss';
import { Suspense } from 'react';
import { APP_STORAGE } from './constants';
import { History, HistoryRef } from './pages/ErrorsPage/History';
import { lightTheme } from './layouts/Themes/lightTheme';
import { ENV_CONFIG } from './config';
import FullScreenLoadingView from '_core/components/FullScreenLoading/FullScreenLoading';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const browserRouter = createBrowserRouter(AppRoutes);

const oidcConfiguration = {
    authority: ENV_CONFIG.REACT_APP_IDP_URL,
    client_id: ENV_CONFIG.REACT_APP_SA_CLIENT_ID,
    scope: ENV_CONFIG.REACT_APP_SA_SCOPE,
    response_type: 'code',
    redirect_uri: `${ENV_CONFIG.DOMAIN_URL}${ENV_CONFIG.REDIRECT_URL}`,
    silent_redirect_uri: `${ENV_CONFIG.DOMAIN_URL}${ENV_CONFIG.SILENT_REDIRECT_URL}`,
    post_logout_redirect_uri: `${ENV_CONFIG.DOMAIN_URL}${ENV_CONFIG.LOGOUT_REDIRECT_URL}`,
    automaticSilentRenew: true
};

const onSignInCallback = (userInfo:any):void => {
    sessionStorage.setItem(APP_STORAGE.ACCESS_TOKEN, userInfo.access_token)
};

const App = observer(() => {
    let queryParams = window?.location?.search;
    const searchParams = new URLSearchParams(queryParams);
    const tenantId = searchParams.get('tenantId') ?? '';
    const subscriptionId = searchParams.get('subscriptionId') ?? '';
    if (tenantId && subscriptionId) {
        localStorage.setItem(APP_STORAGE.AHI_SELECTED_TENANT, tenantId);
        localStorage.setItem(APP_STORAGE.AHI_SELECTED_SUBSCRIPTION, subscriptionId);
    }

    return (
        <ThemeProvider theme={lightTheme}>
            <div className="App">
                <AuthProvider
                    userStore={new WebStorageStateStore({ store: window.localStorage })}
                    onSigninCallback={onSignInCallback}
                    {...oidcConfiguration}
                >
                    <RequireAuth>
                        <Suspense fallback={<FallbackView />}>
                            <RouterProvider router={browserRouter} />
                        </Suspense>
                    </RequireAuth>
                    <History ref={HistoryRef} />
                </AuthProvider>
                <FullScreenLoadingView />
            </div>
        </ThemeProvider>
    );
});

export default App;
