import React, { useImperativeHandle, useState } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { DefaultPromiseCallback, IPromiseCallback } from '../../../../models/Common/PromiseCallback';
import { IAppServiceComponentRef } from '../../../../models/AppService/AppServiceModel';

export interface IAppConfirmDialogConfig {
    header?: string;
    showCancel?: boolean;
    message: string;
}

function AppConfirmDialog(props: any, ref: any) {
    const [visible, setVisible] = useState<boolean>(false);
    const [showCancel, setShowCancel] = useState<boolean>(true);
    const [header, setHeader] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [promiseCallback, setPromiseCallback] = useState<IPromiseCallback<any>>(DefaultPromiseCallback);
    const { t } = useTranslation();

    const onAccept = () => {
        setVisible(false);
        promiseCallback.resolve(true);
    };

    const onReject = () => {
        setVisible(false);
        promiseCallback.resolve(false);
    };

    const onHide = () => {
        setVisible(false);
        promiseCallback.reject(null);
    };

    useImperativeHandle(
        ref,
        (): IAppServiceComponentRef => ({
            open: (config: IAppConfirmDialogConfig) => {
                setHeader(config.header || '');
                setMessage(config.message);
                setShowCancel(config.showCancel ?? true);
                setVisible(true);
                return new Promise((resolve, reject) => {
                    setPromiseCallback({ resolve, reject });
                });
            },
        })
    );

    const footer = (
        <div>
            {showCancel && <Button onClick={onReject}>{t('COMMON.BUTTON.CANCEL')}</Button>}
            <Button onClick={onAccept}>{t('COMMON.BUTTON.YES')}</Button>
        </div>
    );

    return (
        <ConfirmDialog
            className={'custom-dialog'}
            visible={visible}
            header={header}
            footer={footer}
            message={message}
            onHide={onHide}
        />
    );
}

export default React.forwardRef(AppConfirmDialog);
