import { createRef, forwardRef, useImperativeHandle } from 'react';
import { useRedirect } from 'hooks/useRedirect';

const History = forwardRef((props, ref) => {
    // const { redirect } = useRedirect();
    // useImperativeHandle(ref, () => ({
    //     push: (url: any) => {
    //         redirect(url);
    //     },
    //     replace: (url: any) => {
    //         redirect(url, {}, true);
    //     },
    //     pushWithParam: (param: any) => {
    //         redirect({...param});
    //     }
    // }));

    return null;
});

const HistoryRef = createRef();

export { History, HistoryRef };
