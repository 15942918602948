import { FC, useEffect, useRef } from 'react';
import { useRedirect } from 'hooks/useRedirect';
import { observer } from 'mobx-react-lite';
import { ENV_CONFIG, SIGNALR_METHODS, SIGNALR_TYPES } from 'config';
import { useSignalRStore } from 'store/Shared/signalRStore';
import { useAuthenStore } from 'store/Auth/AuthStore';
import { ProjectChangePayload, SignalRMessage } from 'models/SignalRMessage';
import { useProjectManagementStore } from 'store/Home/ProjectManagementStore';
import { APP_ROUTES, PROJECT_STATUS } from '../../constants';
import { MESSAGES } from '_core/constants/message';
import notify from '_core/helpers/notify';
import { useTranslation } from 'react-i18next';

const SubscribeProjectListChange: FC = observer(() => {
    const authStore = useAuthenStore();
    const { t } = useTranslation();
    const signalRStore = useSignalRStore();
    const projectManagementStore = useProjectManagementStore();
    const { redirect } = useRedirect();
    const prevSubscriptionId = useRef('');
    const applicationId = ENV_CONFIG.REACT_APPLICATION_ID.toLowerCase();

    useEffect(() => {
        if (!signalRStore.isConnected || !authStore.user.currentSubscriptionId) return;

        const subscriptionId = `${authStore.user.currentSubscriptionId}_${applicationId}`;
        signalRStore.registerAndReceiveMessage(
            SIGNALR_METHODS.REGISTER_PROJECT_LIST,
            subscriptionId,
            SIGNALR_METHODS.RECEIVE_PROJECT_MESSAGE,
            (response: SignalRMessage) => {
                handleProjectListChange(response);
            }
        );

        prevSubscriptionId.current = subscriptionId;
        return () => {
            if (!prevSubscriptionId.current) return;
            signalRStore.unRegisterAndReceiveMessage(
                SIGNALR_METHODS.UNREGISTER_PROJECT_LIST,
                prevSubscriptionId.current,
                SIGNALR_METHODS.RECEIVE_PROJECT_MESSAGE
            );
        };
    }, [signalRStore.isConnected, authStore.user.currentSubscriptionId]);

    const handleProjectListChange = (response: SignalRMessage) => {
        const { currentTenantId, currentSubscriptionId, currentProjectId } = authStore.user;
        if (
            SIGNALR_TYPES.PROJECT_LIST_NOTIFY !== response.type ||
            applicationId !== response.applicationId ||
            currentSubscriptionId !== response.subscriptionId
        )
            return;

        const projectChange: ProjectChangePayload = JSON.parse(response.payload);
        if (!projectChange.id) return;

        // create project
        if (projectChange.status === PROJECT_STATUS.ACTIVE) {
            // NotificationMessage({ status: NOTIFY_TYPE.ERROR, text: MESSAGES.CREATING_PROJECT });
            notify.error(t(MESSAGES.CREATING_PROJECT));

            projectManagementStore.emptyProjectList();
            projectManagementStore.getListProjectsByApplicationId(currentTenantId, currentSubscriptionId);
        } else {
            // delete/restore project
            if (
                window.location.pathname !== APP_ROUTES.DEFAULT &&
                projectChange.id === currentProjectId &&
                projectChange.status === PROJECT_STATUS.INACTIVE
            ) {
                redirect(APP_ROUTES.DEFAULT);
            }
            authStore.setPermissionChanged(true);
        }
    };

    return <div></div>;
});

export default SubscribeProjectListChange;
