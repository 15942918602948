import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import React from 'react';
import { NOTIFY_TYPE } from '_core/constants/config';

export class NotificationStore {

    // exporting: boolean = false;
    processing: boolean = false;
    // exported: boolean = false;
    imported: boolean = false;

    status: any = '';
    message: any = '';
    destroy: boolean = false;
    duration: any = 3;
    payload: any;
    isShowMessage: boolean = false;
    messageType: any = '';
    notifications: any[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    showError(text: any, duration = 3) {
        this.duration = duration;
        this.message = text;
        this.status = NOTIFY_TYPE.ERROR;
    }

    showSuccess(text: any, duration = 3) {
        this.duration = duration;
        this.message = text;
        this.status = NOTIFY_TYPE.SUCCESS;
    }

    showLoading(text: any, duration = 3) {
        this.duration = duration;
        this.message = text;
        this.status = NOTIFY_TYPE.LOADING;
    }

    showWarning(text: any, duration = 3) {
        this.duration = duration;
        this.message = text;
        this.status = NOTIFY_TYPE.WARNING;
    }

    setStatus(value: any) {
        this.status = value;
    }

    setMessage(value: any) {
        this.message = value;
    }

    setDestroy(value: any) {
        if (value) {
            this.status = '';
            this.message = '';
        }
        this.destroy = value;
    }

    setShowMessage(show: any) {
        this.isShowMessage = show;
    }

    setProcessing(value: any) {
        this.processing = value;
    }
    // setExported(value: any) {
    //     this.exported = value;
    // }

    // setImporting(value: any) {
    //     this.importing = value;
    // }
    setImported(value: any) {
        this.imported = value;
    }
    setPayload(value: any) {
        this.payload = value;
    }

    resetNotifications(value: any) {
        this.notifications = [];
    }

    setNotifications(value: any) {
        this.notifications = [...value];
    }

    addNotifications(value: any) {
        this.notifications = [...[value], ...this.notifications];
        this.notifications = _.orderBy(this.notifications, [(obj: any) => new Date(obj.CreatedUtc)], ['desc']);
    }

}
export const totificationStore = new NotificationStore();
export const storeContext = React.createContext(totificationStore);
export const useNotificationStore = (): NotificationStore => React.useContext(storeContext);