declare var window: any;
const APP_API_URL = window._env.REACT_APP_API_URL;
export const APP_CONFIG = {
    DEFAULT_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    PAGE_INDEX: 1,
    PAGE_SIZE: 20,
    DOMAIN_URL: APP_API_URL,
    MAX_INT: 2147483647,
    ERROR_404_URL: '/error/404',
    ERROR_500_URL: '/error/500',
    ERROR_403_URL: '/error/403',
    ERROR_401_URL: '/error/401',
    FORMAT_DATE_WITH_SLASH: 'YYYY/MM/DD HH:mm:ss',
    MAX_TRAFFIC: 100
};

export const REGEX_CONFIG = {
    NAME_INPUT: /^[^\\/\\\\:*?"<>|]{0,255}$/,
    NAME_ATTRIBUTE: /^[a-z\d\_\s]+$/i,
    CODE_ATTRIBUTE: /^[a-zA-Z]+$/,
    CODE_REGEX: /^[a-zA-Z0-9]*$/,
    FEATURE_NAME: /^[a-zA-Z0-9_ ]*$/,
    FLOAT: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
    FLOATING_POINT: /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
    INTEGER: /^-?[0-9]+$/,
    NAME_TABLE_TAB_START: /^[a-zA-Z_]+$/,
    NAME_TABLE_TAB_SPECIAL: /^[a-zA-Z0-9_]*$/,
    REGEX_INTERGER: /[^0-9.-]+/,
    TIMESTAMP: /[0-9]{4}(-|\/)(0[1-9]|1[0-2])(-|\/)(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/,
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    TEST_SIZE: /^([1-9][0-9]?|)$/,
    RANDOM_SEED: /^(?:[1-9][0-9]{3}|[1-9][0-9]{2}|[1-9][0-9]|[1-9]?|)$/,
    SIMPLE_VALUE_PREDICT: /^(?:1000|[1-9][0-9]{2}|[1-9][0-9]|[1-9]?|)$/,
    ENTITY_ID: /^[a-zA-Z0-9-]*$/,
    REGEX_POSITIVE_INTEGER: /^[0-9]\d*$/g,
    REGEX_INTEGER_BIGGER_THAN_ZERO: /^[1-9][0-9]*$/g,
    FUNCTION_BLOCK_ENVIRONMENT_NAME: /^[A-Za-z_-][A-Za-z0-9\-_.]*$/
}

export const NOTIFY_TYPE = {
    SUCCESS: 'success',
    LOADING: 'loading',
    ERROR: 'error',
    WARNING: 'warning'
}

export const MOD_DIALOG = {
    ADD: 'ADD_NEW',
    EDIT: 'EDIT_ITEM'
};

export const CONDITIONS_SEARCH_MASTER_DATA = {
    pageSize: APP_CONFIG.MAX_INT,
    pageIndex: 0,
    clientOverride: true
}

export const EXCEL_CSV_FILES_EXTENSION = ['.xls', '.xlsx', '.csv'];

export const HTTP_STATUS = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    LOOKUP_TYPE_CONCURRENCY_FAIL: 301001
};

export const ERROR_CODE = {
    USED_OTHE_SESSION: '901001',
    DUPLICATE: '201001',
    ENTITY_NOT_FOUND: '301001',
    ITEM_NOT_LOCK: '15101',
    ITEM_ALREADY_LOCK: '15102',
    ITEM_IS_LOCK_BY_OTHER: '15103',
    ITEM_IS_REQUESTED_TAKEN_BY_OTHER: '15104',
    CANT_DELETE_CAUSE_HAS_CHILD: '901002',
    ASSET_LOOP_CREATE: '11122',
    ATTRIBUTE_NOT_FOUND: '501001',
    DELETE_401001: '401001',
    DELETE_401002: '401002',
    DELETE_401003: '401003',
    DELETE_401101: '401101',
    DELETE_401102: '401102',
    DELETE_401103: '401103',
    DELETE_401201: '401201',
    DELETE_401202: '401202',
    DELETE_401203: '401203',
    INVALID_CONNECTION_STRING_QUERY_RAWDATA: '402001',
    INVALID_QUERY_STRING_QUERY_RAWDATA: '402002',
    INVALID_STOP_DEPLOY: '501204',
    NOTFOUND: '101001',
};

export const APPLY_REGEXT_INPUT = {
    GENERAL_RULE: 'generalRule',
    ATTRIBUTE_NAME_RULE: 'attributeNameRule',
    DEVICE_ID_RULE: 'deviceIdRule',
    METRIC_KEY_RULE: 'metricKeyRule',
    TABLE_NAME_RULE: 'tableNameRule',
    COLUMN_NAME_RULE: 'columnNameRule',

    NUMBER_ONLY: 'numberOnly',
    NUMBER_INTEGER: 'numberInteger',
    NUMBER_DECIMALS: 'numberDecimals',
    EMAIL: 'emailRole',
    PHONE_NUMBER: 'phoneNumber',
    GENERAL_CODE: 'generalCode',
    GENERAL_NAME: 'generalName',
    NAME_RULE: 'nameRule',
    ABBREVIATION_RULE: 'abbreviationRule',
    POSITIVE_INTEGER: 'positiveInteger',
    INTEGER_BIGGER_THAN_ZERO: 'integerBiggerThanZero'
}

export const TYPES_ATTRIBUTE_VALUE: any = {
    STATIC: 'static',
    DYNAMIC_DATA: 'dynamic',
    RUNTIME: 'runtime',
    ALIAS: 'alias',
    INTEGRATION: 'integration'
};
export const ATTRIBUTES_TYPES: any = [
    { label: 'Static', value: 'static' },
    { label: 'Dynamic', value: 'dynamic' },
    { label: 'Runtime', value: 'runtime' },
    { label: 'Alias', value: 'alias' },
    { label: 'Integration', value: 'integration' }
];

export const DATA_COLUMN_TYPES: any = [
    { label: 'Static', value: 'static' },
    { label: 'Dynamic', value: 'dynamic' },
    { label: 'Runtime', value: 'runtime' },
    { label: 'Alias', value: 'alias' },
    { label: 'Integration', value: 'integration' }
];

export const PRIMARY_COLUMN: any = [
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
]
export const DEFAULT_VALUE: any = [
    { label: 'No', value: 'no' },
    { label: 'Yes', value: 'yes' },
]

export const DATA_TYPES = [
    { label: 'Text', value: 'text' },
    { label: 'Bool', value: 'bool' },
    { label: 'Float', value: 'float' },
    { label: 'Int', value: 'int' }
]

export const DEVICE_TEMPLATE_CONFIG = {
    METRIC_TYPES: ['device_id', 'metric', 'timestamp', 'aggregation'],
    DEFAULT_TYPE: 'metric',
    DEVICE_ID: 'device_id',
    TIMESTAMP: 'timestamp',
    AGGREGATION: 'aggregation'
}

export const DATA_TYPES_STATIC = [
    { label: 'Text', value: 'text' },
    { label: 'Bool', value: 'bool' },
    { label: 'Number', value: 'number' },
    { label: 'DateTime Picker', value: 4 },
]

export const IDENTITY_DATA_TYPE = [
    { label: 'User', value: 'user' },
    { label: 'User Group', value: 'group' }
]

export const ASSET_IDENTITY_TYPE = {
    USER: "user",
    USER_GROUP: "group",
}

export const JS_DATA_TYPES = {
    BOOLEAN: "boolean",
    NUMBER: "number",
    STRING: "string"
}

export const APP_DATA_TYPES = {
    BOOL: "bool",
    INT: "int",
    FLOAT: "double",
    TEXT: "text",
    TIMESTAMP: "timestamp"
}

export const TABS_OF_ASSET = {
    ATTRIBUTE_TAB: 'tab_1',
    TABLE_TAB: 'tab_2',
    MEDIA_TAB: 'tab_3'
}

export const MESSAGE_TABLE = {
    SAVE_SUCCESS: 'Save successfully',
    SAVE_FAIL: ' Save unsuccessfully'
}

export const ACTION_INSERT_TABLE = {
    ADD: 'add',
    UPDATE: 'update',
    DELETE: 'delete',
    NO_ACTION: 'no'
}
export const ACTION_INSERT_COLUMNS = {
    ADD: 'add',
    UPDATE: 'update',
    DELETE: 'delete',
    NO_ACTION: 'no'
}

export const DATE_TIME_FORMAT = {
    DISPLAY_TYPE: 'momentjs',
    RAW_DATA_FILE_NAME: 'YMMDDHHmmss',
}

// need FIELD_NAME_CHECK_EXIST, DISABLED_FIELD length is 300 character, to make sure it diff with all column name (colum name max length 255)
export const TABLE_INSERT_DATA = {
    MAX_LENGTH: 199,
    ID_TEMP: '#id#',
    FIELD_NAME_CHECK_EXIST: '$_id_exist$',
    DISABLED_FIELD: '$disabled_field',
    ACTION: '$action',
    ID_ERROR: '$id_error$',
    NAME_ERROR: '$name_error$'
}

export const SIGNALR_TYPES = {
    MSG_ENTITY_USING: 'MSG_ENTITY_USING',
    DEVICE_METRICS: 'device_metrics',
    DEVICE_TEMPLATE: 'device_template',
    UOM: 'uom',
    ACTIONS: 'action',
    ASSET_TEMPLATE: 'asset_template',
    ASSET_MEDIA: 'asset_media',
    MEDIA_LIST: 'media',
    DEVICE: 'device',
    LOCK_ELEMENT: 'lock_element',
    FORCE_LOCK_ELEMENT: 'force_lock_element',
    TAKE_LOCK_ELEMENT: 'take_lock_element',
    CONFIRM_TAKEN_LOCK_ELEMENT: 'confirm_taken_lock_element',
    REJECT_TAKEN_LOCK_ELEMENT: 'reject_taken_lock_element',
    UNLOCK_ELEMENT: 'unlock_element',
    DELETE_ELEMENT: 'delete_element',
    BROKER: 'broker',
    BROKER_CHANGE: 'broker_change',
    BROKER_TEMPLATE: 'broker_template',
    ALARM: 'alarm',
    ALARM_ACTION: 'alarm_action',
    ALARM_RULE: 'alarm_rule',
    LOCK_TEMPLATE: 'lock_template',
    UNLOCK_TEMPLATE: 'unlock_template',
    FORCE_LOCK_TEMPLATE: 'force_lock_template',
    CONFIRM_TAKEN_LOCK_TEMPLATE: 'confirm_taken_lock_template',
    REJECT_TAKEN_LOCK_TEMPLATE: 'reject_taken_lock_template',
    LOOKUP_TYPE: 'lookup_type',
    LOOKUP: 'lookup',
    TAKE_LOCK_TEMPLATE: 'take_lock_template',

    ACTIVITIES_TEMPLATE: 'activities_template',

    // message type for Table Tab
    LOCK_TABLE_TAB: 'lock_table',
    UNLOCK_TABLE_TAB: 'unlock_table',
    TAKE_LOCK_TABLE_TAB: 'take_lock_table',
    REJECT_UNLOCK_TABLE_TAB: 'reject_taken_lock_table',
    CONFIRM_UNLOCK_TABLE_TAB: 'confirm_taken_lock_table',

    // message type for asset tree
    ENTITY_CHANGE: 'entity_changed',

    USER_ACCESS_CONTROL: 'user/access_control',

    PROJECT_LIST_NOTIFY: 'project_list',
}

export const SIGNALR_METHODS = {
    UNREGISTER_USER_UPN: 'UnregisterUserUpn',
    REGISTER_USER_UPN: 'RegisterUserUpn',

    REGISTER_DEVICE: 'RegisterDevice',
    UNREGISTER_DEVICE: 'UnregisterDevice',

    REGISTER_BROKER: 'RegisterBroker',
    UNREGISTER_BROKER: 'UnregisterBroker',

    REGISTER_ALARM: 'RegisterAlarm',
    UNREGISTER_ALARM: 'UnregisterAlarm',

    REGISTER_PROJECT_LIST: 'RegisterProject',
    UNREGISTER_PROJECT_LIST: 'UnregisterProject',

    REGISTER_RAW_DATA: 'RegisterRawdata',
    UNREGISTER_RAW_DATA: 'UnregisterRawdata',

    REGISTER_DATASET: 'RegisterDataset',
    UNREGISTER_DATASET: 'UnregisterDataset',

    REGISTER_DATA_MODEL: 'RegisterDataModel',
    UNREGISTER_DATA_MODEL: 'UnregisterDataModel',

    REGISTER_MODEL: 'RegisterModel',
    UNREGISTER_MODEL: 'UnregisterModel',

    REGISTER_ANALYTIC_JOB: 'RegisterAnalyticJob',
    UNREGISTER_ANALYTIC_JOB: 'UnregisterAnalyticJob',

    REGISTER_DATAFLOW_TEMPLATE_VERSION: 'RegisterDataFlowTemplateVersion',
    UNREGISTER_DATAFLOW_TEMPLATE_VERSION: 'UnregisterDataFlowTemplateVersion',

    RECEIVE_MESSAGE: 'ReceiveMessage',
    RECEIVE_IMPORT_MESSAGE: 'ReceiveImportMessage',
    RECEIVE_EXPORT_MESSAGE: 'ReceiveExportMessage',
    RECEIVE_DEVICE_MESSAGE: 'ReceiveDeviceMessage',
    RECEIVE_USER_MESSAGE: 'ReceiveUserMessage',
    RECEIVE_BROKER_MESSAGE: 'ReceiveBrokerMessage',
    RECEIVE_ALARM_MESSAGE: 'ReceiveAlarmMessage',
    RECEIVE_PROJECT_MESSAGE: 'ReceiveProjectMessage',
    RECEIVE_ONLINE_ANALYTIC_MESSAGE: 'ReceiveAnalyticMessage',
    RECEIVE_RAW_DATA_MESSAGE: 'ReceiveRawdataMessage',
    RECEIVE_DATASET_MESSAGE: 'ReceiveDatasetMessage',
    RECEIVE_TRAINING_MESSAGE: 'ReceiveTrainingMessage',
    RECEIVE_MODEL_MESSAGE: 'ReceiveModelMessage',
    RECEIVE_EXECUTE_MESSAGE: 'ReceiveExecutionMessage',
    RECEIVE_ONLINE_ANALYTIC_JOB_MESSAGE: 'ReceiveAnalyticJobMessage',
}

export const MESSAGE_SETUP_TRAINING = {
    TRAINING_SUCCESS: 'Save successfully',
    TRAINING_FAIL: 'Training unsuccessfully'
}

export const DATA_TYPE_TABLE = {
    BIGIN: 'BIGIN',
    BOOL: 'BOOL',
    INT: 'INT',
    REAL: 'REAL',
    TEXT: 'TEXT',
    TIMESTAMP: 'TIMESTAMP',
    VA25: 'VA25',
    VA255: 'VA255',
    VA50: 'VA50',
    TIME: 'TIME',
    FLOAT: 'FLOAT',
    DOUBLE: 'DOUBLE'
}

export const MATH_EXPRESSIONS = [
    'E',
    'PI',
    'Tau',
    'Abs(',
    'Acos(',
    'Acosh(',
    'Asin(',
    'Asinh(',
    'Atan(',
    'Atan2(',
    'Atanh(',
    'BigMul(',
    'BitDecrement(',
    'BitIncrement(',
    'Cbrt(',
    'Ceiling(',
    'Clamp(',
    'CopySign(',
    'Cos(',
    'Cosh(',
    'DivRem(',
    'Exp(',
    'Floor(',
    'FusedMultiplyAdd(',
    'IEEERemainder(',
    'ILogB(',
    'Log(',
    'Log10(',
    'Log2(',
    'Max(',
    'MaxMagnitude(',
    'Min(',
    'MinMagnitude(',
    'Pow(',
    'ReciprocalEstimate(',
    'ReciprocalSqrtEstimate(',
    'Round(',
    'ScaleB(',
    'Sign(',
    'Sin(',
    'SinCos(',
    'Sinh(',
    'Sqrt(',
    'Tan(',
    'Tanh(',
    'Truncate(',
];

export const DEFAULT_NUMBER_OF_PROJECT_FOR_HORIZONTAL = 4;

export const RANDOM_COLOR_FOR_PROJECT_AVATAR = ['#3399FF', '#990000', '#77D78B', '#FF9900', '#990099', '#006666', '#AAD419'];

export const STATUS_OF_PROJECT_PENDING = 'PD';

export const STATUS_OF_PROJECT_ACTIVE = 'AC';

export enum KUBEFLOW_STATUS {
    STOPPING = 'stopping',
    STOPPED = 'stopped',
    SUCCESSFUL = 'successful',
    IN_PROGRESS = 'in progress',
    ERROR = 'error',
    INITIALIZING = 'initializing',
}

export enum KUBEFLOW_STATUS_LABEL {
    STOPPING = 'Stopping',
    STOPPED = 'Stopped',
    SUCCESSFUL = 'Successful',
    IN_PROGRESS = 'Inprogress',
    ERROR = 'Error',
    INITIALIZING = 'Initializing',
}

export enum KUBE_STATUS_CODE {
    STOPPING = 'DD4F628E-A80C-41DE-BFA3-2D9C336775E9',
    STOPPED = 'AD632CDC-85AF-4373-8EE9-3AAFF945F7A1',
    SUCCESSFUL = '41EDC70E-F5EB-4EED-9022-7B419B59ABE4',
    IN_PROGRESS = '6E3B019B-DDDB-456F-8F12-C5597F517FEB',
    ERROR = '5509114C-FF85-4C30-A11F-DC09C93FC94D',
    INITIALIZING = 'C332E676-D0BE-4984-916A-31B14C322975',
}
export enum ONLINE_ANALYTIC_STATUS_CODE {
    SUCCESSFUL = '22DA06B3-EBEA-4463-85FA-AA10C40E0D36',
    ERROR = 'BA4E6D91-7B53-49A7-9620-E1F45D7144E2',
    NOT_PREDICTED = '2C86300D-8110-4077-96B6-438DB56620AF',
}

export enum ANALYTIC_STATUS {
    NOT_PREDICTED = 'not predicted',
    SUCCESSFUL = 'successful',
    ERROR = 'error',
}

export enum ANALYTIC_STATUS_LABEL {
    NOT_PREDICTED = 'Not Predicted',
    SUCCESSFUL = 'Successful',
    ERROR = 'Error',
}

export enum SORT_STATUS_KEY {
    RAW_DATA = 'KubeFlowJobStatus.SortOrder',
    DATASET = 'KubeFlowJobStatus.SortOrder',
    TRAINING_JOB = 'TrainingJob.KubeFlowJobStatus.SortOrder',
    MODEL_DEPLOYMENT = 'ModelDeployment.KubeFlowJobStatus.SortOrder',
}

export const OUTPUT_ZIP = 'zip'

export const OUTPUT_CSV = 'csv'
export const OUTPUT_JOBLIB = 'joblib'

export enum FILE_TYPE {
    CSV = '.csv',
    JOBLIB = '.joblib',
}

export enum DATASET_ACTION {
    DETAIL = 'info',
    EDIT = 'edit',
    STREAM_SERVING = 'stream-serving'
}

export enum ONLINE_ANALYTIC {
    RENAME = 'rename'
}

export enum KEYMAP {
    ENTER = 'Enter'
}

export enum CLONE_RAW_DATA_TYPE {
    UPLOAD = 'Upload',
    SPLIT = 'Split',
    CLONE_SPLIT = 'Clone Split',
    QUERY_DB = 'Query DB',
    ASSET_HEALTH = 'Asset Health'
}

export enum SOURCE_TYPE {
    AM = 'AM'
}

export enum CONFIRM_DATA_TYPE {
    QUERY = 'query',
    CLONE_QUERY = 'clone_query',
    UPLOAD = 'upload',
    ASSET_HEALTH = 'asset_health',
    CLONE_ASSET_HEALTH = 'clone_asset_health',
}

export const SERVICE_CONNECTION_TYPE = {
    ASSSET: 'asset',
    EXTERNAL_API: 'externalAPI'
}

export enum TOOLBAR_ACTION {
    DELETE = 'delete',
    ADD = 'add',
    REFRESH = 'refresh',
}

export const FEATURE_TYPE_ID_OF_FEATURE = '5a47433e-f36b-1410-84f8-00283f6ce426'
export const FEATURE_TYPE_IDS = [
    '5a47433e-f36b-1410-84f8-00283f6ce426',
    '5447433e-f36b-1410-84f8-00283f6ce426'
]
export const DEFAULT_SOURCE_TYPE_ID = {
    ASSET_MANAGEMENT: 'C989C2AB-E71C-49F7-9459-A2862948D5F8',
    MANUAL_API: '384A8C59-99CA-4525-A7F6-0BAE6528F166',
}

export const DEFAULT_SOURCE_SCHEMA_ID = {
    TENANT_ID: 'A9733789-78EE-414B-819A-A1AC7CBA91A3',
    SUBSCRIPTION_ID: 'E3E0E295-A4F7-443C-9FE6-E12686B92A77',
    PROJECT_ID: 'C95FB4BD-C156-40C7-B852-292361ACB14D',
    DATA_PERIOD_ID: '662624E6-5AB1-4917-96D6-168AD6325986',
    TIME_UNIT_ID: '8843705A-39CC-47A9-9F9D-BD175875F43F',
    AGGREGATION_TYPE_ID: '658C649B-12E2-4274-9D43-7121B62AC37C',
    TIME_INTERVAL_ID: '28722DD9-191F-4B2B-905F-CD1642FC2907',
}

export enum SOURCE_TYPE_NAME {
    ASSET_HEATH = 'Asset Health',
    QUERY_DB = 'Query DB',
    UPLOAD = 'Upload',
    EXTERNAL_SYSTEM = 'External System',
}

export enum SOURCE_SCHEMA_NAME {
    QUERY_STRING = 'query_string',
    CONNECT_STRING = 'connect_string',
    CONNECTION_ID = 'connection_id',
    ASSET_ID = 'asset_id',
    ATTRIBUTES_IDS = 'attribute_ids',
    FEATURE_KEYS = 'feature_keys',
    START_TIME = 'start_time',
    END_TIME = 'end_time',
    TIME_INTERVAL = 'time_interval',
    AGGREGATION_TYPE = 'aggregation_type',
    FILE_PATH = 'file_path',
    PROJECT_ID = 'project_id',
    TENANT_ID = 'tenant_id',
    SUBSCRIPTION_ID = 'subscription_id',
    FEATURE_NAMES = 'feature_names',
}

export enum MODEL_ACTION {
    RUN = 'run',
    STOP = 'stop',
    STOP_DIS = 'stopDis',
}

export enum ANALYTIC_ACTION {
    RUN = 'run',
    STOP = 'stop',
    RELEARNING = 'relearning',
    RENAME = 'rename',
    TEST_JOB = 'testjob',
    FORWARDING = 'forwarding',
}

export const DEFAULT_ROUNDED_DECIMAL = 5;
export const YEAR_FORMAT = 'YYYY';
export const MAX_FILE_NAME_SIZE = 255;
export const MAX_RECORD_TABLE_PREVIEW = 20;
export const NETWORK_ERROR = 'Network Error';

export const PERMISSION_OBJECTS = {
    ASSET: 'asset',
    ASSET_TEMPLATE: 'asset_template',
    MEDIA_LIST: 'media_list',
    TABLE_LIST: 'table_list',

    DEVICE: 'device',
    DEVICE_TEMPLATE: 'device_template',

    ALARM_LIST: 'alarm',
    ALARM_RULE: 'alarm_rule',
    ACTION: 'action',

    BROKER: 'broker',
    INTERGRATION: 'integration',
    LOOKUP: 'lookup',
    LOOKUP_TYPE: 'lookup_type',
    UOM: 'uom',
    ACTIVITY_LOG: 'asset_activity_log',
    SETTINGS: 'setting',
    PROJECT_LIST: 'project',

    USER: 'project',
    ROLE_LIST: 'project',
    USER_GROUP: 'project',
    API_CLIENT: 'project',

    AUDIT_LOG: 'audit_log',
    CONFIGURATION: 'asset_configuration',
    EVENT_FORWARDING: 'event',
    BLOCK_FUNCTION: 'block_function',
    BLOCK_TEMPLATE: 'block_template'
};
