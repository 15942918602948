import _ from 'lodash';
import { makeAutoObservable } from 'mobx';
import React from 'react';
import { handleError } from '_core/helpers/HandleError';
import { convertOffsetToString, getSettingConfig } from '_core/helpers/Utils';
import { ENV_CONFIG, HTTP_STATUS } from '../../config';
import { APP_STORAGE, COMMON_CONSTANT } from '../../constants';
import { ApplicationModel, IApplication, IProject, ISettings, ISubscription, IUserInfo, IUserInfoResponse, Project, UserInfo } from '../../models';
import authService from '../../services/Authen/authService';
import userInfoService from '../../services/Authen/userInfoService';

const defaultUser = new UserInfo();
const keyToPick: string[] = Object.keys(defaultUser);
const filterUserSubscriptions = (currentTenantId: string, subscriptions: ISubscription[]) => {
    const validSubscriptions = subscriptions.filter(
        (sub) =>
            sub.tenantId === currentTenantId &&
            sub.applications.some((app) => app.id === ENV_CONFIG.REACT_APPLICATION_ID.toLocaleLowerCase())
    );
    return validSubscriptions;
};

export class AuthenStore {
    subscriptionMenu: any[] = [];
    private _visibleConsent: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get visibleConsent() {
        return this._visibleConsent;
    }
    set visibleConsent(value: boolean) {
        this._visibleConsent = value;
    }

    requireLogin: boolean = false;

    setRequireLogin(isRequire: boolean) {
        this.requireLogin = isRequire;
    }

    forbidden: boolean = false;

    setForbidden(isForbidden: boolean) {
        this.forbidden = isForbidden;
    }

    permissionChanged: boolean = false;

    setPermissionChanged(isChanged: boolean) {
        this.permissionChanged = isChanged;
    }

    sessionExpired: boolean = false;

    setSessionExpired(isExpired: boolean) {
        this.sessionExpired = isExpired;
    }

    switchSubscription: boolean = false;

    setSwitchSubscription(isSwitch: boolean) {
        this.switchSubscription = isSwitch;
    }

    user: IUserInfo = defaultUser;

    setUser(user: UserInfo) {
        this.user = user;
    }

    selectedProject: IProject = new Project();

    setSelectedProject(project: Project) {
        this.selectedProject = project;
    }

    applicationInfo: IApplication = new ApplicationModel();
    setApplicationInfo(value: IApplication) {
        this.applicationInfo = value;
    }

    initUserInfo = async () => {
        const response = await userInfoService.getUserInfo();
        if (!response.isSuccess) {
            if (response.error?.statusCode === HTTP_STATUS.UNAUTHORIZED) {
                this.setRequireLogin(true);
            }
            return; // todo: handle other cases if need
        }

        const userResponse = response.data;
        const validSubscriptions = filterUserSubscriptions(userResponse.currentTenantId, userResponse.subscriptions);
        if (!validSubscriptions.length) {
            await this.loadUser(userResponse);
            this.setForbidden(true);
        } else {
            const isValid = validSubscriptions.some((sub) => sub.id === userResponse.currentSubscriptionId);
            if (isValid) {
                await this.loadUser(userResponse);
                this.updateAccessToken(
                    userResponse.currentTenantId,
                    userResponse.currentSubscriptionId,
                    userResponse.currentProjectId
                ); // always renew token when reload page
            } else {
                // change subscription to default sub and redirect to home page
                this.setSwitchSubscription(true);
                await this.updateAccessToken(validSubscriptions[0].tenantId, validSubscriptions[0].id, '', true);
            }
        }
    };

    updateAccessToken = async (tenantId: string, subscriptionId: string, projectId = '', loadUserInfo = false) => {
        if (!tenantId || !subscriptionId) return false;
        const accessToken = await authService.getAccessToken(tenantId, subscriptionId, projectId);
        sessionStorage.setItem(APP_STORAGE.ACCESS_TOKEN, accessToken);
        if (accessToken && loadUserInfo) return this.loadUser();

        return true;
    };

    loadUser = async (userResponse: IUserInfoResponse | null = null) => {
        try {
            if (!userResponse) {
                const response = await userInfoService.getUserInfo();
                if (!response.isSuccess) {
                    if (response.error?.statusCode === HTTP_STATUS.UNAUTHORIZED) {
                        this.setRequireLogin(true);
                    }
                    return false;
                }
                userResponse = response.data;
            }

            let settings: ISettings = getSettingConfig();

            if (userResponse.displayDateTimeFormat) {
                settings.datetimeFormat = userResponse.displayDateTimeFormat;
            } else {
                settings.datetimeFormat = '';
            }

            if (userResponse.timezoneDto?.offset) {
                settings.offset = convertOffsetToString(userResponse.timezoneDto.offset);
            }

            localStorage.setItem(APP_STORAGE.OBJECT_RIGHT_SHORT, JSON.stringify(userResponse.objectRightShorts));
            localStorage.setItem(APP_STORAGE.RIGHT_SHORT, JSON.stringify(userResponse.rightShorts));
            localStorage.setItem(APP_STORAGE.SETTINGS_CONFIG, JSON.stringify(settings));

            const userInfo = _.pick(userResponse, keyToPick) as IUserInfo;
            userInfo.subscriptions = filterUserSubscriptions(userResponse.currentTenantId, userResponse.subscriptions);

            let projectInfo: IProject = new Project();

            if (userResponse?.currentProjectId && userResponse?.currentProjectId !== COMMON_CONSTANT.EMPTY_GUID) {
                projectInfo = {
                    id: userResponse.currentProjectId,
                    name: '',
                    applicationId: ENV_CONFIG.REACT_APPLICATION_ID.toLowerCase(),
                    tenantId: userResponse.currentTenantId,
                    subscriptionId: userResponse.currentSubscriptionId,
                };
            }

            this.setUser(userInfo);

            // Init http client, these properties will be used for axios interceptor
            const currentSubscription = userResponse.subscriptions.find((v) => v.id === userResponse?.currentSubscriptionId);
            const applications = currentSubscription?.applications || [];
            const currentApplication = applications.find((v) => v.id === ENV_CONFIG.REACT_APPLICATION_ID.toLowerCase());
            this.setApplicationInfo(currentApplication || new ApplicationModel());
            if (currentApplication?.name) {
                document.title = currentApplication.name;
            }

            this.setSelectedProject(projectInfo);
            return true;
        } catch (error: any) {
            return false;
        }
    };

    initSettingsData = async () => {
        try {
            let settings: ISettings = getSettingConfig();
            // TO DO: get config setting not support
            // const result = await getSettingsService();

            // if (result?.data?.data?.length) {
            //     result.data.data.forEach((item: any) => {
            //         if (item.key === 'default.metric.retention.days') {
            //             settings.retentionDays = parseInt(item.value, 10);
            //         }
            //         if (item.key === 'default.search.page.size') {
            //             settings.pageSize = parseInt(item.value, 10);
            //         }
            //         if (item.key === 'force.unlock.timeout') {
            //             settings.unlockTimeout = parseInt(item.value, 10);
            //         }
            //         if (item.key === 'regex.general.rule') {
            //             settings.regex.generalRule = item.value;
            //         }
            //         if (item.key === 'regex.attribute.rule') {
            //             settings.regex.attributeRule = item.value;
            //         }
            //         if (item.key === 'regex.device.rule') {
            //             settings.regex.deviceRule = item.value;
            //         }
            //         if (item.key === 'regex.abbreviation.rule') {
            //             settings.regex.abbreviationRule = item.value;
            //         }
            //         if (item.key === 'regex.metric.rule') {
            //             settings.regex.metricRule = item.value;
            //         }
            //         if (item.key === 'regex.table.rule') {
            //             settings.regex.tableRule = item.value;
            //         }
            //         if (item.key === 'regex.code.rule') {
            //             settings.regex.codeRule = item.value;
            //         }
            //         if (item.key === 'regex.expression.rule') {
            //             settings.regex.expressRule = item.value;
            //         }
            //         if (item.key === 'regex.name.rule') {
            //             settings.regex.nameRule = item.value;
            //         }
            //
            //         if (item.key === 'FORCE_UNLOCK_SETTING') {
            //             settings.forceUnlockSetting = item.value;
            //         }
            //
            //         if (item.key === 'regex.upn.rule') {
            //             settings.regex.upnRule = item.value;
            //         }
            //     });
            // }
            localStorage.setItem(APP_STORAGE.SETTINGS_CONFIG, JSON.stringify(settings));
        } catch (error) {
            handleError(error);
        }
    };

    // todo: consider to remove
    getSubscriptionByPersonInfo = async () => {
        // This function will call the api user/info again with the param forceUpdate=true
        // to get and update all the subscription and the projects for project management screen
        try {
            // Get personal info
            const personalInfo = await userInfoService.getUserInfo(true);
            if (!personalInfo.isSuccess) return;

            const subscriptions = filterUserSubscriptions(
                personalInfo.data.currentTenantId,
                personalInfo.data.subscriptions
            );

            // Get setting from localstorage
            const settingLocalStorage = getSettingConfig();
            if (subscriptions) {
                this.subscriptionMenu = subscriptions.map((val: any) => {
                    return {
                        id: val.id,
                        to: `/homepage/${val.id}/${val.tenantId}`,
                        icon: '',
                        title: val.name,
                        fontIcon: '',
                        showTextThumb: true,
                        thumbColor: val?.color,
                        tenantID: val.tenantId,
                        subscriptionID: val.id,
                        children: [],
                    };
                });

                // Update the setting in localstorage
                localStorage.setItem(APP_STORAGE.SETTINGS_CONFIG, JSON.stringify(settingLocalStorage));
            }
        } catch (e) {
            handleError(e);
        }
    };
}

export const authStoreInternal = new AuthenStore();
export const storeContext = React.createContext(authStoreInternal);
export const useAuthenStore = (): AuthenStore => React.useContext(storeContext);