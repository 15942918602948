import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { observer } from 'mobx-react-lite';
import { useRedirect } from 'hooks/useRedirect';
import authService from '../../services/Authen/authService';
import { APP_ROUTES, APP_STORAGE } from '../../constants';
import { useAuthenStore } from 'store/Auth/AuthStore';

export const SigninCallback = observer(() => {
    const auth = useAuth();
    const authStore = useAuthenStore();
    const { redirect } = useRedirect();
    useEffect(() => {
        if (!auth.user?.access_token) return;
        const tenantId = localStorage.getItem(APP_STORAGE.AHI_SELECTED_TENANT) || '';
        const subscriptionId = localStorage.getItem(APP_STORAGE.AHI_SELECTED_SUBSCRIPTION) || '';
        // come from Asset Health, always override the token
        if (tenantId && subscriptionId) {
            localStorage.removeItem(APP_STORAGE.AHI_SELECTED_TENANT);
            localStorage.removeItem(APP_STORAGE.AHI_SELECTED_SUBSCRIPTION);
            (async () => {
                const accessToken = await authService.getAccessToken(tenantId, subscriptionId);
                if (accessToken) {
                    sessionStorage.setItem(APP_STORAGE.ACCESS_TOKEN, accessToken);
                    await authStore.initUserInfo();
                    redirect(APP_ROUTES.DEFAULT);
                } else auth.signinRedirect();
            })();
        } else {
            sessionStorage.setItem(APP_STORAGE.ACCESS_TOKEN, auth.user.access_token);
            (async () => {
                await authStore.initUserInfo();
                redirect(APP_ROUTES.DEFAULT);
            })();
        }
    }, [auth.isAuthenticated]);
    return <></>;
});
