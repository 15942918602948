import styled from 'styled-components';
import {
  Layout
} from 'antd';

const {
  Header,
  Content,
  Footer,
  Sider,
} = Layout;

// Layout overiew
export const StyledLayout = styled(Layout)`
  min-height: 100vh;
  padding: 0px 0px 0px 0px;
`;

// Menu bar
export const StyledSider = styled(Sider)`
  width: 240px;
  background: #FFFFFF;
  box-shadow: 3px 0px 6px rgba(0, 0, 0, 0.16);
`;
export const MenuTextSider = styled.span`
  
`;

// Content
export const StyledContent = styled(Content)`
  background: ${(props) => props.theme.colors.white};
  border-radius: 24px 24px 0px 0px;
  height: 820px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Header
export const StyledHeader = styled(Header)`
  background: #004F9B;
  height: 64px;
  display: inherit;
  padding: 0 28px 0 16px;
`;

// Footer
export const StyledFooter = styled(Footer)`
  height: 50px;
  padding: 24px 28px;
  box-shadow: 0px -3px 6px rgb(0 0 0 / 16%);
  background: #fff;
`;