export const lightTheme = {
  colors: {
    primary: '#07B53A',
    black1: '#1A1B21',
    black2: '#45464F',
    black3: '#86878F',
    black4: '#C6C6C6',
    black5: '#EFEFF0',
    black6: '#F8F8F8',
    black7: '#C4C4C4',
    red: '#E22F2F',
    white: '#fff',
    gradient: 'linear-gradient(180deg, #D1FFDF 8.97%, #A9FFC4 38.73%, #69DC8B 99.28%, #6FDE90 99.29%);',
  },
};