// import { ILanguage, Language } from '../ILanguage';
// import { ITimezone, Timezone } from '../ITimezone';

import { ITimezone, Timezone } from '../ITimezone';
import { ILanguage, Language } from '../ILanguage';

export interface IUserInfoResponse {
    appEndpoint: string;
    avatar: string;
    id: string;
    upn: string;
    firstName: string;
    lastName: string;
    isLocalUser: boolean;
    homeSubscriptionId: string;
    homeTenantId: string;
    currentProjectId: string;
    currentSubscriptionId: string;
    currentTenantId: string;
    dateTimeFormat: string;
    displayDateTimeFormat: string;
    subscriptions: ISubscription[];
    timezoneDto: ITimezone;
    objectRightHashes: string[];
    objectRightShorts: string[];
    preferredLanguage: ILanguage;
    rightHashes: string[];
    rightShorts: string[];
    rights: [];
}

export interface IUserInfo {
    appEndpoint: string;
    avatar: string;
    id: string;
    upn: string;
    firstName: string;
    lastName: string;
    currentProjectId: string;
    currentSubscriptionId: string;
    currentTenantId: string;
    dateTimeFormat: string;
    displayDateTimeFormat: string;
    subscriptions: ISubscription[];
    timezoneDto: ITimezone;
    preferredLanguage: ILanguage;
    isLocalUser: boolean;
    homeSubscriptionId: string;
    homeTenantId: string;
    requiredConsent: boolean;
}

export interface ISubscription {
    id: string;
    name: string;
    color: string;
    tenantId: string;
    applications: IApplication[];
}

export interface IApplication {
    id: string;
    name: string;
    color: string;
    endpoint: string;
    groups: any[];
    projects: any[];
}

export class ApplicationModel implements IApplication {
    color: string = '';
    endpoint: string = '';
    groups: any[] = [];
    id: string = '';
    name: string = '';
    projects: any[] = [];
}

export class UserInfo implements IUserInfo {
    appEndpoint = '';
    avatar = '';
    id = '';
    upn = '';
    firstName = '';
    lastName = '';
    currentProjectId = '';
    currentSubscriptionId = '';
    currentTenantId = '';
    dateTimeFormat = '';
    displayDateTimeFormat = '';
    subscriptions: ISubscription[] = [];
    timezoneDto = new Timezone();
    preferredLanguage = new Language();
    isLocalUser = false;
    homeSubscriptionId = '';
    homeTenantId = '';
    requiredConsent = false;
}

export class Subscription implements ISubscription {
    id = '';
    name = '';
    color = '';
    tenantId = '';
    applications = [];

    static Create(id: string, tenantId: string) {
        const sub = new Subscription();
        sub.id = id;
        sub.tenantId = tenantId;
        return sub;
    }
}
