import { observer } from 'mobx-react-lite';
import React, { PropsWithChildren, Suspense } from 'react';
import Error403 from '../pages/ErrorsPage/components/Error403';
import { useAuthenStore } from '../store/Auth/AuthStore';
import { useHasPermissionByEntity } from '../utils/PermissionUtils';
import { FallbackView } from 'layouts/FallbackView';

interface IAuthenticatedGuardProps {
    entity?: string;
    rights?: string[];
    content: any;

    [x: string]: any;
}

const AuthorizedPage: React.FC<PropsWithChildren<IAuthenticatedGuardProps>> = observer((props) => {
    const { entity, rights, content: ProtectedPage } = props;
    const authStore = useAuthenStore();
    const hasPermission = useHasPermissionByEntity(rights, entity, authStore.selectedProject);

    // const content = () => {
    //     if (!hasPermission && authStore.user.upn) return <Error403 />;
    //     if (hasPermission) return <ProtectedPage />;
    //     if (authStore.forbidden) return <Error403 />; //todo: remove
    //     return <></>;
    // };

    const content = () => {
        return <ProtectedPage />;
    }

    return <Suspense fallback={<FallbackView />}>{content()}</Suspense>;
});


export default AuthorizedPage;
