import { IProject } from 'models';
import { APP_STORAGE } from './../../../src/constants';

const getRightsFromStorage = () => {
    const rightShorts: string = localStorage.getItem(APP_STORAGE.RIGHT_SHORTS) || '';
    const rightShortsArr: string[] = rightShorts !== '' ? JSON.parse(rightShorts) : [];
    return rightShortsArr;
};

function isCheckPermissionFinished(rightShorts: string[], rightsFullString: string[]) {
    let result = false;

    if (Array.isArray(rightShorts) && Array.isArray(rightsFullString)) {
        rightShorts.forEach((rs) => {
            if (!result && rightsFullString.includes(rs)) {
                result = true;
            }
        });
    }
    return result;
}

function composeRights(tenantId: string, subscriptionId: string, applicationId: string, projectId: string, entity: string, rights: string[]) {
    if (!rights?.length || !entity || !applicationId) return [];

    const specificProjects: string[] = [];
    const allProjects: string[] = [];
    const allApplications: string[] = [];

    rights.forEach((right) => {
        if (projectId) {
            const projectRight = `t/${tenantId}/s/${subscriptionId}/a/${applicationId}/p/${projectId}/e/${entity}/o/*/p/${right}`;
            specificProjects.push(projectRight);
        }

        const allProjectRight = `t/${tenantId}/s/${subscriptionId}/a/${applicationId}/p/*/e/${entity}/o/*/p/${right}`;
        allProjects.push(allProjectRight);

        const allApplicationRight = `t/${tenantId}/s/${subscriptionId}/a/*/p/*/e/${entity}/o/*/p/${right}`;
        allApplications.push(allApplicationRight);
    });
    return [...specificProjects, ...allProjects, ...allApplications];
}

function composeRightNones(tenantId: string, subscriptionId: string, applicationId: string, projectId: string, entity: string, rights: string[]) {
    if (!rights?.length || !entity || !applicationId) return [];

    const specificProjects: string[] = [];
    const allProjects: string[] = [];
    const allApplications: string[] = [];

    rights.forEach((right) => {
        if (projectId) {
            const projectRight = `t/${tenantId}/s/${subscriptionId}/a/${applicationId}/p/${projectId}/e/${entity}/o/*/p/${right}/none`;
            specificProjects.push(projectRight);
        }

        const allProjectRight = `t/${tenantId}/s/${subscriptionId}/a/${applicationId}/p/*/e/${entity}/o/*/p/${right}/none`;
        allProjects.push(allProjectRight);

        const allApplicationRight = `t/${tenantId}/s/${subscriptionId}/a/*/p/*/e/${entity}/o/*/p/${right}/none`;
        allApplications.push(allApplicationRight);
    });
    return [...specificProjects, ...allProjects, ...allApplications];
}

export function getPermissionList(rights: string[], entity: string, project: IProject) {
    if (!project) return [];

    const { tenantId, subscriptionId, applicationId, id: projectId } = project;
    return composeRights(tenantId, subscriptionId, applicationId, projectId, entity, rights);
}

export function getPermissionListNone(rights: string[], entity: string, project: IProject) {
    if (!project) return [];

    const { tenantId, subscriptionId, applicationId, id: projectId } = project;
    return composeRightNones(tenantId, subscriptionId, applicationId, projectId, entity, rights);
}

export function isHasPermissionByTenant(rights: string[], entity: string, tenantInfo: any, isCheckNoPermission?: boolean) {
    if (!tenantInfo || !entity || !rights || !rights.length) {
        return false;
    }

    const { tenantId, subscriptionId, applicationId, projectId } = tenantInfo;
    if (!tenantId || !subscriptionId || !applicationId) return false;

    const rightShorts = getRightsFromStorage();
    if (!rightShorts.length) return false;

    const rightsFullString = !isCheckNoPermission
        ? composeRights(tenantId, subscriptionId, applicationId, projectId, entity, rights)
        : composeRightNones(tenantId, subscriptionId, applicationId, projectId, entity, rights);
    return isCheckPermissionFinished(rightShorts, rightsFullString);
}

export function isNoPermissionByTenant(rights: string[], entity: string, tenantInfo: any) {
    return isHasPermissionByTenant(rights, entity, tenantInfo, true);
}

export function hasPermission(rights: string[]) {
    const rightShorts = getRightsFromStorage();
    return isCheckPermissionFinished(rightShorts, rights);
}

export function isNoPermissionByEntity(rights: string[], entity: string, project: IProject) {
    const rightFullString = getPermissionListNone(rights, entity, project);
    const rightShorts = getRightsFromStorage();
    return isCheckPermissionFinished(rightShorts, rightFullString);
}

export function isHasPermissionByEntity(rights: string[], entity: string, project: IProject) {
    const rightFullString = getPermissionList(rights, entity, project);
    const rightShorts = getRightsFromStorage();
    return isCheckPermissionFinished(rightShorts, rightFullString);
}
