import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import AuthorizedPage from '../AuthorizedPage';

const PARENT_ROUTE = 'data-preprocessing';
const CHILD_ROUTES = {
  DATA_FLOW_TEMPLATE: {
    INDEX: 'dataflow-template',
    CREATE: 'create',
    EDIT_VERSION: ':dataflowTemplateId/version/:versionId',
    EDIT: ':dataflowTemplateId',
    TESTING: ':dataflowTemplateId/testing/:versionId',
    SELECT_DATASET: ':dataflowTemplateId/testing/:versionId/select-dataset',
    PREVIEW_DATA: ':dataflowTemplateId/:versionId/:executedId/preview/:training',
  },
}

const DataflowTemplate = lazy(() => import('pages/DataPreProcessing/DataFlowTemplate/DataflowTemplate'));
const ConfigDataflowTemplate = lazy(() => import('pages/DataPreProcessing/DataFlowTemplate/ConfigDataflowTemplate/ConfigDataflowTemplate'));
const DataFlowTesting = lazy(() => import('pages/DataPreProcessing/DataFlowTemplate/DataFlowTesting/DataFlowTesting'));
const SelectDataset = lazy(() => import('pages/DataPreProcessing/DataFlowTemplate/DataFlowTesting/components/SelectDataset'));
const PreviewData = lazy(() => import('pages/DataPreProcessing/DataFlowTemplate/DataFlowTesting/components/PreviewData'));

const childRouteObjects: RouteObject[] = [
  {
    path: CHILD_ROUTES.DATA_FLOW_TEMPLATE.INDEX,
    element: <Outlet />,
    children: [
      {
        index: true,
        element: (
            <AuthorizedPage
                content={DataflowTemplate}
            />
        )
      },
      {
        path: CHILD_ROUTES.DATA_FLOW_TEMPLATE.CREATE,
        element: (
            <AuthorizedPage
                content={ConfigDataflowTemplate}
            />
        )
      },
      {
        path: CHILD_ROUTES.DATA_FLOW_TEMPLATE.EDIT_VERSION,
        element: (
            <AuthorizedPage
                content={ConfigDataflowTemplate}
            />
        )
      },
      {
        path: CHILD_ROUTES.DATA_FLOW_TEMPLATE.EDIT,
        element: (
            <AuthorizedPage
                content={ConfigDataflowTemplate}
            />
        )
      },
      {
        path: CHILD_ROUTES.DATA_FLOW_TEMPLATE.TESTING,
        element: (
            <AuthorizedPage
                content={DataFlowTesting}
            />
        )
      },
      {
        path: CHILD_ROUTES.DATA_FLOW_TEMPLATE.SELECT_DATASET,
        element: (
            <AuthorizedPage
                content={SelectDataset}
            />
        )
      },
      {
        path: CHILD_ROUTES.DATA_FLOW_TEMPLATE.PREVIEW_DATA,
        element: (
            <AuthorizedPage
                content={PreviewData}
            />
        )
      },
    ]
  }
]


export const DataPreProcessingRoutes: RouteObject = {
  path: PARENT_ROUTE,
  element: <Outlet />,
  children: childRouteObjects
};