import { IOption } from '../models/Layout/IOption';

export const COMMON_CONSTANT = {
    EMPTY_GUID: '00000000-0000-0000-0000-000000000000',
    PROJECT_ID: '7868dcc6-6c80-480f-dd54-08da6e26db8b',
};

export const APP_STORAGE = {
    PREFIX: 'MLS_',
    ACCESS_TOKEN: 'MLS_ACCESS_TOKEN',
    ASSET_PICKER: 'MLS_ASSET_PICKER',
    CURRENT_MENU: 'MLS_CURRENT_MENU',
    RIGHT_HASHES: 'MLS_RIGHT_HASHES',
    RIGHT_SHORT: 'MLS_RIGHT_SHORT',
    OBJECT_RIGHT_HASHES: 'MLS_OBJECT_RIGHT_HASHES',
    OBJECT_RIGHT_SHORT: 'MLS_OBJECT_RIGHT_SHORT',
    INFO_LOCK_ASSET: 'MLS_INFO_LOCK_ASSET',
    INFO_LOCK_ASSET_TEMPLATE: 'MLS_INFO_LOCK_ASSET_TEMPLATE',
    INFO_LOCK_DEVICE_TEMPLATE: 'MLS_INFO_LOCK_DEVICE_TEMPLATE',
    SETTINGS_CONFIG: 'MLS_SETTINGS_CONFIG',
    AHI_SELECTED_TENANT: 'MLS_AHI_SELECTED_TENANT',
    AHI_SELECTED_SUBSCRIPTION: 'MLS_AHI_SELECTED_SUBSCRIPTION',
    CURRENT_LANGUAGE: 'MLS_LANGUAGE',
    ACCESS_TOKEN_OF_PROJECT: 'MLS_ACCESS_TOKEN_OF_PROJECT',
    DSB_ACCESS_TOKEN_OF_ASSET_PROJECT: 'DSB_ACCESS_TOKEN_OF_ASSET_PROJECT',
    DSB_ALL_TOKEN_OF_ASSET_PROJECTS: 'DSB_ALL_TOKEN_OF_ASSET_PROJECTS',
    RIGHT_SHORTS: 'MLS_RIGHT_SHORTS',
    IN_PROGRESS_LOGOUT: 'MLS_IN_PROGRESS_LOGOUT',
    OBJECT_RIGHT_SHORTS: 'MLS_OBJECT_RIGHT_SHORTS',
    DSB_ASSET_PICKER: 'DSB_ASSET_PICKER',
    CURRENT_USER: 'CURRENT_USER',
    DSB_MAP_PROJECT_ID_TO_TOKEN: 'DSB_MAP_PROJECT_ID_TO_TOKEN',
    CURRENT_TENANT_ID: 'TM_CURRENT_TENANT_ID',
    CURRENT_SUBSCRIPTION_ID: 'TM_CURRENT_SUBSCRIPTION_ID',

    ALL_TOKEN_OF_ASSET_PROJECTS: 'ALL_TOKEN_OF_ASSET_PROJECTS',
    ACCESS_TOKEN_OF_ASSET_PROJECT: 'ACCESS_TOKEN_OF_ASSET_PROJECT',
    SELECTED_TENANT: 'MLS_SELECTED_TENANT',
    SELECTED_SUBSCRIPTION: 'MLS_SELECTED_SUBSCRIPTION',
    AUTH_STATE_SKIP_REDIRECT: 'MLS_AUTH_STATE_SKIP_REDIRECT'
};

export const NOTIFICATION_TYPE = {
    IMPORT: 'import',
    EXPORT: 'export',
};

export const NOTIFICATION_STATUS = {
    START: 'start',
    PARTIAL: 'partial',
    FAIL: 'fail',
    SUCCESS: 'success',
};

export const PROJECT_STATUS = {
    ACTIVE: 'AC',
    PENDING: 'PD',
    INACTIVE: 'IN',
    RESTORE: 'RES',
};

export const PROJECT_TYPE = {
    ASSET_ANALYTIC: 'asset_analytic',
    ASSET_MANAGEMENT: 'asset',
};

export const NOTIFY_TYPE = {
    SUCCESS: 'success',
    LOADING: 'loading',
    ERROR: 'error',
    WARNING: 'warning',
    PARTIAL: 'partial',
};

export const WINDOW_EVENT_LISTENER = {
    BEFORE_UNLOAD: 'beforeunload',
};

export const JS_DATA_TYPE = {
    OBJECT: 'object',
    STRING: 'string',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
    UNDEFINED: 'undefined',
    FUNCTION: 'function',
};

export const QUERY_OPERATOR = {
    EQUALS: 'eq',
    NOT_EQUALS: 'neq',
    CONTAINS: 'contains',
    NOT_CONTAINS: 'ncontains',
};

export const QUERY_TYPE = {
    TEXT: 'text',
    NUMBER: 'number',
    LIST: 'list',
    DATETIME: 'datetime',
    BOOLEAN: 'boolean',
    GUID: 'guid',
};

export const HTTP_HEADER = {
    FIELDS: {
        ACCEPT_FIELD: 'Accept',
        CONTENT_TYPE_FIELD: 'Content-Type',
        AUTHORIZATION: 'Authorization',
        X_TENANT_ID: 'x-tenant-id',
        X_SUBSCRIPTION_ID: 'x-subscription-id',
        X_PROJECT_ID: 'x-project-id',
    },
    CONTENT_TYPE_VALUES: {
        APPLICATION_JSON: 'application/json',
    },
};

export const TOKEN_TYPE = {
    BEARER: 'Bearer',
    JWT: 'jwt',
};

export const BINDING_VALUE_TYPE_DATA = {
    SERIES_DATA: 'series_data',
    STRING_DATA: 'string_data',
};

export const YEAR_FORMAT = 'YYYY';

export const APP_ROUTES = {
    DEFAULT: '/',
    INDEX: '/',
    PROJECT_DEFAULT: '/projects',
    PROJECT_LIST: '/:tenantId/:subscriptionId/projects',
    PROJECT_DETAIL: '/:tenantId/:subscriptionId/:projectId',
    ERROR_403_URL: '/error/403',
    ERROR_404_URL: '/error/404',
    ERROR_500_URL: '/error/500',
    CALLBACK: '/callback',
    HOMEPAGE: '/homepage/:id/:tenantID',
    DATA_MODEL: {
        INDEX: '/data-model',
        RAW_DATA: {
            INDEX: '/data-model/:dataModelId/raw_data',
            INFO: '/data-model/:dataModelId/raw_data/:rawDataId',
            CREATE: {
                OPTION: '/data-model/raw_data/create/select-option',
                UPLOAD: '/data-model/raw_data/create/upload',
                QUERY_DB: '/data-model/raw_data/create/query-db',
                ASSET_HEALTH: '/data-model/raw_data/create/asset-health',
                CONFIRM: '/data-model/raw_data/confirm',
            },
            VISUALIZE: {
                INDEX: '/data-model/:dataModelId/raw_data/:rawDataId/visualize',
                OUTLIER: '/data-model/:dataModelId/raw_data/:rawDataId/visualize/outlier',
                HISTOGRAM: '/data-model/:dataModelId/raw_data/:rawDataId/visualize/histogram',
                CORRELATION: '/data-model/:dataModelId/raw_data/:rawDataId/visualize/correlation',
            },
            CLONE: {
                ASSET_HEALTH: '/data-model/raw_data/clone/asset-health',
                QUERY_DB: '/data-model/raw_data/clone/query-db',
            },
            DATA_SET: {
                INDEX: '/data-model/:dataModelId/raw_data/:rawDataId/dataSet',
                INFO: '/data-model/:dataModelId/raw_data/:rawDataId/dataSet/:dataSetId/info',
                CREATE: '/data-model/raw_data/dataset/create',
                TRAINING: {
                    INDEX: '/data-model/:dataModelId/raw_data/:rawDataId/dataSet/:dataSetId/training',
                    INFO: '/data-model/:dataModelId/raw_data/:rawDataId/dataSet/:dataSetId/training/:trainingId/info',
                    VIEW_DATAFLOW:
                        '/data-model/:dataModelId/raw_data/:rawDataId/dataSet/:dataSetId/training/:trainingId/info/view-dataflow/:dataflowTemplateId/:versionId',
                    CREATE: '/data-model/raw_data/dataSet/training/create',
                    EDIT: '/data-model/raw_data/dataSet/training/:trainingId/edit',
                    EDIT_FEATURES_MAPPING: '/data-model/raw_data/dataSet/training/edit-mapping',
                    STATUS_TRAINING: '/data-model/raw_data/dataSet/training/:trainingId/status',
                },
            },
        },
    },
    ONLINE_ANALYTIC: {
        INDEX: '/online-analytic',
        RELEARNING: '/online-analytic/:id/relearning',
        FORWARDING: '/online-analytic/:id/forwarding',
        CREATE: '/online-analytic/create',
        EDIT: '/online-analytic/edit/:onlineAnalyticId',
        DETAIL: '/online-analytic/detail/:onlineAnalyticId/relearning/jobs/:relearningConfig',
    },
    MODEL_MAMAGEMENT: {
        INDEX: '/model-management',
        DETAIL: '/model-management/:id',
        VIEW_DATAFLOW: '/model-management/:id/view-dataflow/:dataflowTemplateId/:versionId',
        UPLOAD_MODEL: '/model-management/upload-model',
        DATA_SET_TRAINING_DETAIL: '/model-management/dataset/:datasetId/training-detail/:trainingJobId/:page',
    },
    DATA_FORWARDING: {
        INDEX: '/data-forwarding',
        LIST: '/data-forwarding/list',
        CREATE: '/data-forwarding/create',
        EDIT: '/data-forwarding/:id/edit',
    },
    DATA_PREPROCESSING: {
        INDEX: '',
        DATA_FLOW_TEMPLATE: {
            INDEX: '/data-preprocessing/dataflow-template',
            CREATE: '/data-preprocessing/dataflow-template/create',
            EDIT_VERSION: '/data-preprocessing/dataflow-template/:dataflowTemplateId/version/:versionId',
            EDIT: '/data-preprocessing/dataflow-template/:dataflowTemplateId',
            TESTING: '/data-preprocessing/dataflow-template/:dataflowTemplateId/testing/:versionId',
            SELECT_DATASET: '/data-preprocessing/dataflow-template/:dataflowTemplateId/testing/:versionId/select-dataset',
            PREVIEW_DATA: '/data-preprocessing/dataflow-template/:dataflowTemplateId/:versionId/:executedId/preview/:training',
        },
    },
    SECURITY: {
        INDEX: '',
        USER: {
            INDEX: '/security/users',
            DETAIL: '/security/users/access-control/:id',
        },
        ROLE: {
            INDEX: '/security/roles',
            DETAIL: '/security/roles/access-control/:id',
        },
    },
    ERROR: '/error',
};

export const BREADCRUMBS = {
    DATA_MODEL: {
        name: 'MLS.DATA_MODEL.DATA_MODEL',
        path: APP_ROUTES.DATA_MODEL.INDEX,
    },
    DATA_FLOW_TEMPLATE: {
        name: 'MLS.DATA_PREPROCESSING.DATA_FLOW_TEMPLATE',
        path: APP_ROUTES.DATA_PREPROCESSING.DATA_FLOW_TEMPLATE,
    },
};

export const DATA_TYPES_ID = {
    TEXT: 'text',
    BOOL: 'bool',
    TIMESTAMP: 'timestamp',
    INT: 'int',
    DECIMAL: 'decimal',
};

export const BOOLEAN_TYPE = 'boolean';

export const FIELDS_NAME = {
    DATA_FORWARDING: {
        NAME: 'name',
        TYPE: 'type',
        IS_ACTIVE: 'isActive',
        HEALTH_CHECK: 'healthCheck',
        INTERVAL: 'interval',
        TIME_UNIT: 'timeUnit',
        HOST: 'host',
        PARTITION: 'partition',
        AUTHENTICATION: 'authentication',
        TOPIC_NAME: 'topicName',
        USERNAME: 'username',
        PASSWORD: 'password',
    },
};

export const SCHEMA_TYPE = {
    TEXT: 'text',
    NUMBER: 'number',
    BOOL: 'bool',
    TEXTAREA: 'textarea',
    API_SELECT: 'api-select',
    SELECT: 'select',
};

export const BOOLEAN_TEXT = {
    TRUE: 'true',
    FALSE: 'false',
};

export enum DATA_FORWARDING_SCHEMA {
    TENANT_ID = '26611BC0-4B82-4FB8-8AAA-618D2ADFDC13',
    SUBSCRIPTION_ID = '99D13C78-649B-426B-908F-A618918D5FD1',
    PROJECT_ID = '1EF18551-A695-419D-9369-D0463406A052',
    BROKER_ID = '2ABF08B3-5D92-4724-A123-41C3D4DE7776',
    HEALTH_CHECK_ID = '3E63309A-B0C8-4018-B5C0-D2E3B34079F9',
    INTERVAL_ID = '8FA60619-5E9E-41F6-ADF2-15B93DF2C3A4',
    TIME_UNIT_ID = '219C1C1B-8523-4A34-BD7E-4E089B9D21B5',
    HEALTH_CHECK_KAFKA_ID = '667D8B8A-BE66-4BBE-AD51-DF44E88A54AB',
    INTERVAL_KAFKA_ID = 'BF71D3DB-13F7-4A12-9023-4E9B7FF85FB6',
    TIME_UNIT_KAFKA_ID = '352B63A0-28BD-4EB9-88AE-DC0432388873',
    HOST_ID = 'F4AA32F0-8F00-4B30-9B66-1A96FFA0BACA',
    TOPIC_NAME_ID = '579EFB72-BDBE-4521-B2A6-014BB49D48D6',
    PARTITION_ID = '3C02A094-85E3-49E8-B406-A40692F48A06',
    AUTHENTICATION_ID = '7AFB763B-5F2C-47A3-8B88-5045964171F5',
    USERNAME_ID = 'C74F6612-FC47-4D2E-ABD0-9274CEC530B2',
    PASSWORD_ID = 'A90238F7-86A3-4A1B-A7E3-824F79E1AEE3',
}

export enum ACTION_BUTTON_FOOTER {
    BACK = 'back',
    NEXT = 'next',
    FINISH = 'finish',
    CANCEL = 'cancel',
    SAVE = 'save',
    SAVEANDTRAIN = 'saveAndTrain',
}

export enum DATA_FORWARDING_TYPE_SCHEMA {
    ASSET_MANAGEMENT_BROKER_ID = 'c0497e92-7a34-42e6-8c15-c5e0f0182ec1',
    KAFKA_BROKER = '29ab0c62-6fbc-4207-b322-a802fe851927',
}

export enum ALGORITHM_TYPE_NAME {
    ANOMALY_DETECTION = 'anomaly detection',
    REGRESSION = 'regression',
}

export enum RAW_DATA_MORE {
    VISUALIZE = 'visualize',
    NOT_VISUALIZE = 'notVisualize',
}

export enum RAW_DATA_SOURCE_TYPE_DES {
    ASSET_HEATH = 'Asset Management',
    QUERY_DB = 'Database',
    UPLOAD = 'Upload',
    SPLIT_DATA = 'Split raw data',
}

export enum RAW_DATA_SOURCE_TYPE_NAME {
    ASSET_HEATH = 'Asset Health',
    QUERY_DB = 'Query DB',
    UPLOAD = 'Upload',
    SPLIT_DATA = 'Split',
}

export const OPTION_FILTER_SOURCE_TYPE: IOption[] = [
    { label: 'COMMON.APPLICATION.ASSET_MANAGEMENT', value: RAW_DATA_SOURCE_TYPE_DES.ASSET_HEATH },
    { label: 'MLS.COMMON.DATABASE', value: RAW_DATA_SOURCE_TYPE_DES.QUERY_DB },
    { label: 'MLS.DATA_MODEL.UPLOAD', value: RAW_DATA_SOURCE_TYPE_DES.UPLOAD },
    // { label: RAW_DATA_SOURCE_TYPE_DES.SPLIT_DATA, value: RAW_DATA_SOURCE_TYPE_DES.SPLIT_DATA },
];

export enum CREATE_ANALYTIC_BY_LABEL {
    ASSET_MANAGEMENT = 'Asset Management',
    EXTERNAL_SYSTEM = 'External System',
}

export enum CREATE_ANALYTIC_BY {
    ASSET_MANAGEMENT = 'Am',
    EXTERNAL_SYSTEM = 'External System',
}

export const OPTION_FILTER_CREATED_BY: IOption[] = [
    { label: 'COMMON.APPLICATION.ASSET_MANAGEMENT', value: CREATE_ANALYTIC_BY_LABEL.ASSET_MANAGEMENT },
    { label: 'MLS.COMMON.FILTER.EXTERNAL_SYSTEM', value: CREATE_ANALYTIC_BY_LABEL.EXTERNAL_SYSTEM },
];

export enum SOURCE_TYPE_MODEL {
    mls = 'MLS System',
    upload = 'Upload',
    relearning = 'MLS Relearning',
}

export const MAPPING_SOURCE_TYPE_NAME = {
    [SOURCE_TYPE_MODEL.mls]: 'Internal',
    [SOURCE_TYPE_MODEL.upload]: 'User Uploaded',
    [SOURCE_TYPE_MODEL.relearning]: 'Relearning',
};

export const OPTION_FILTER_SRC_BY: IOption[] = [
    { label: 'MLS.MLMODEL_MANAGEMENT.USER_UPLOADED', value: MAPPING_SOURCE_TYPE_NAME[SOURCE_TYPE_MODEL.upload] },
    { label: 'MLS.MLMODEL_MANAGEMENT.INTERNAL', value: MAPPING_SOURCE_TYPE_NAME[SOURCE_TYPE_MODEL.mls] },
    { label: 'MLS.MLMODEL_MANAGEMENT.RELEARNING', value: MAPPING_SOURCE_TYPE_NAME[SOURCE_TYPE_MODEL.relearning] },
];

export enum RELEARNING_MODEL_FILTER {
    INTERNAL_AND_UPLOAD = 'MLS.ML_MODEL.INTERNAL_UPLOAD',
    RELEARNING = 'MLS.ONLINE_ANALYTIC.RELEARNING_STATUS',
    VIEW_ALL = 'MLS.ML_MODEL.VIEW_ALL',
}

export const OPTION_RELEARNING_FILTER = [
    { label: RELEARNING_MODEL_FILTER.INTERNAL_AND_UPLOAD, value: RELEARNING_MODEL_FILTER.INTERNAL_AND_UPLOAD },
    { label: RELEARNING_MODEL_FILTER.RELEARNING, value: RELEARNING_MODEL_FILTER.RELEARNING },
    { label: RELEARNING_MODEL_FILTER.VIEW_ALL, value: RELEARNING_MODEL_FILTER.VIEW_ALL },
];

export const API_ERROR_CODE = {
    ENTITY_VALIDATION: 'ERROR.ENTITY.VALIDATION',
    ENTITY_DUPLICATION: 'ERROR.ENTITY.DUPLICATION',
    ENTITY_INVALID: 'ERROR.ENTITY.INVALID',
    ENTITY_LOCK_ALREADY_LOCKED: 'ERROR.ENTITY.LOCK.ALREADY_LOCKED',
    ENTITY_LOCK_NOT_LOCKED: 'ERROR.ENTITY.LOCK.NOT_LOCKED',
    ENTITY_LOCK_REQUESTED_TAKEN_LOCK_BY_OTHER: 'ERROR.ENTITY.LOCK.REQUESTED_TAKEN_LOCK_BY_OTHER',
    ENTITY_LOCK_REQUESTED_IN_PROCESS: 'ERROR.ENTITY.LOCK.REQUESTED_IN_PROCESS',
    ENTITY_LOCK_LOCKED_BY_OTHER: 'ERROR.ENTITY.LOCK.LOCKED_BY_OTHER',
    ENTITY_NOT_FOUND: 'ERROR.ENTITY.NOT_FOUND',
    GENERIC_COMMON_EXCEPTION: 'ERROR.GENERIC.COMMON_EXCEPTION',
    GENERIC_PROCESS_FAILED: 'ERROR.GENERIC.PROCESS_FAILED',
    SYSTEM_CALL_SERVICE: 'ERROR.SYSTEM.CALL_SERVICE',
    SYSTEM_NOT_SUPPORTED: 'ERROR.SYSTEM.NOT_SUPPORTED',
    SYSTEM_SECURITY_EXCEPTION: 'ERROR.SYSTEM.SECURITY_EXCEPTION',
    RELATED_ENTITY_NOT_FOUND: 'ERROR.ENTITY.RELATED_NOT_FOUND',
    PAYLOAD_USING: 'ERROR.ENTITY.INVALID.DEVICE_TEMPLATE.PAYLOAD_USING',
    METRIC_USING: 'ERROR.ENTITY.INVALID.DEVICE_TEMPLATE.METRIC_USING ',
    ENTITY_INVALID_ASSET_TEMPLATE_USING: 'ERROR.ENTITY.INVALID.ASSET_TEMPLATE.USING',
    ENTITY_INVALID_UOM_USING: 'ERROR.ENTITY.INVALID.UOM.USING',
    INVALID_GRANT: 'invalid_grant',
};

export const VALIDATION_ERROR_CODE = {
    INVALID: 'ERROR.ENTITY.VALIDATION.FIELD_INVALID',
    REQUIRED: 'ERROR.ENTITY.VALIDATION.FIELD_REQUIRED',
    NOT_FOUND: 'ERROR.ENTITY.VALIDATION.FIELD_NOT_FOUND',
    DUPLICATED: 'ERROR.ENTITY.VALIDATION.FIELD_DUPLICATED',
    SOME_ITEMS_DELETED: 'ERROR.ENTITY.VALIDATION.SOME_ITEMS_DELETED',
    GT: 'ERROR.ENTITY.VALIDATION.GT',
    LT: 'ERROR.ENTITY.VALIDATION.LT',
    GTE: 'ERROR.ENTITY.VALIDATION.GTE',
    LTE: 'ERROR.ENTITY.VALIDATION.LTE',
    MAX_LENGTH: 'ERROR.ENTITY.VALIDATION.MAX_LENGTH',
    OUT_OF_RANGE: 'ERROR.ENTITY.VALIDATION.FIELD_OUT_OF_RANGE',
    OUT_OF_RANGE_OF_TYPE: 'ERROR.ENTITY.VALIDATION.OUT_OF_RANGE',
};

export const BUTTON_FOOTER: IButton[] = [
    {
        label: 'MLS_COMMON.BUTTON.CANCEL',
        disabled: false,
        className: 'base-button button-new-flow cancel-type',
        action: ACTION_BUTTON_FOOTER.CANCEL,
    },
    {
        label: 'MLS_COMMON.BUTTON.PREVIOUS',
        className: 'base-button button-new-flow cancel-type',
        action: ACTION_BUTTON_FOOTER.BACK,
    },
    {
        label: 'MLS_COMMON.BUTTON.NEXT',
        disabled: true,
        className: 'base-button button-new-flow save-type',
        action: ACTION_BUTTON_FOOTER.NEXT,
    },
    {
        label: 'MLS_COMMON.BUTTON.FINISH',
        disabled: true,
        className: 'base-button button-new-flow save-type',
        action: ACTION_BUTTON_FOOTER.FINISH,
    },
];
export const BUTTON_FOOTER_TRAINING: IButton[] = [
    {
        label: 'MLS_COMMON.BUTTON.CANCEL',
        disabled: false,
        className: 'base-button button-new-flow cancel-type',
        action: ACTION_BUTTON_FOOTER.CANCEL,
    },
    {
        label: 'COMMON.BUTTON.SAVE',
        disabled: true,
        className: 'base-button button-new-flow save-type',
        action: ACTION_BUTTON_FOOTER.SAVE,
    },
    {
        label: 'MLS_COMMON.BUTTON.SAVEANDTRAIN',
        disabled: true,
        className: 'base-button button-new-flow save-type',
        action: ACTION_BUTTON_FOOTER.SAVEANDTRAIN,
    },
];
export const DATA_TYPE = {
    DATA_MODEL: {
        id: 'ce24ac7a-1b6d-41d7-b507-56cabbc2ded7',
        label: 'MLS.MLMODEL_MANAGEMENT.DATA_MODEL',
    },
    CUSTOM_JSON: {
        id: '5f43050e-5f4d-4d71-b8c5-b6cf281ce00a',
        label: 'MLS.MLMODEL_MANAGEMENT.CUSTOM_JSON',
    },
    IMAGE: {
        id: 'b39de2f0-c854-42fe-92ac-907441a346ed',
        label: 'MLS.MLMODEL_MANAGEMENT.IMAGE',
    },
};
