import styled from 'styled-components';

export const ErrorPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .error-code {
    font-size: 80px;
    font-weight: 600;
    margin-bottom: 0;
    color: #2d4650;
  }
  h1 {
    font-size: 26px;
    font-weight: 500;
    color: #2d4650;
  }
  h4 {
    font-size: 13px;
    font-weight: 400;
    color: #2d4650;
  }
  button {
    height: 36px;
    width: 87px;
    border-radius: 4px;
    margin-top: 28px;
    padding: 9px, 16px;
    background : #3399FF;
    border-color : #3399FF;
  }
`;
