declare var window: any;

export const APP_API_URL: string = window._env.REACT_APP_API_URL;
export const APPLICATION_ID: string = window._env.PROJECT_APPLICATION_ID;
export const REACT_APP_SA_CLIENT_ID: string = window._env.REACT_APP_SA_CLIENT_ID;
export const IDP_URL: string = window._env.REACT_APP_IDP_URL;
export const ASSET_MANAGEMENT_APPLICATION_ID = 'a0f1c338-1eff-40ff-997e-64f08e141b06';
export const PROJECT = {
    NUMBER_TO_DISPLAY_FOR_HORIZONTAL: 4,
};

export const ENV_CONFIG = {
    REACT_APPLICATION_ID: window._env.PROJECT_APPLICATION_ID,
    REACT_APP_IDP_URL: window._env.REACT_APP_IDP_URL,
    REACT_APP_API_URL: window._env.REACT_APP_API_URL,
    REACT_APP_URL_NOTIFICATIONS: window._env.REACT_APP_URL_NOTIFICATIONS,
    REACT_APP_SA_CLIENT_ID: window._env.REACT_APP_SA_CLIENT_ID,
    REACT_APP_SA_SCOPE: window._env.REACT_APP_SA_SCOPE,
    DOMAIN_URL: window._env.DOMAIN_URL,
    REDIRECT_URL: window._env.REDIRECT_URL,
    SILENT_REDIRECT_URL: window._env.SILENT_REDIRECT_URL,
    LOGOUT_REDIRECT_URL: window._env.LOGOUT_REDIRECT_URL,
    LANGUAGE_URL: window._env.LANGUAGE_URL,
    DOCUMENT_URL: window._env.DOCUMENT_URL,
    DISPLAY_ICP: window._env.DISPLAY_ICP,
    CDN_URL: window._env.CDN_URL,
    REACT_APP_API_AHI_URL: window._env.REACT_APP_API_AHI_URL,
    RELEASE_VERSION: window._env.RELEASE_VERSION,
    RELEASE_CHANGE_LOG_URL: window._env.RELEASE_CHANGE_LOG_URL,
    ENABLE_CONSENT: window._env.ENABLE_CONSENT
};

export const APP_CONFIG = {
    DEFAULT_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    PAGE_INDEX: 1,
    PAGE_SIZE: 20,
    DOMAIN_URL: APP_API_URL,
    MAX_INT: 2147483647,
    MIN_INT: -2147483648,
    MAX_SEVERITY: 1000,
    ERROR_404_URL: '/error/404',
    ERROR_500_URL: '/error/500',
    ERROR_401_URL: '/error/401',
    ERROR_403_URL: '/error/403',
    FORMAT_DATE_WITH_SLASH: 'YYYY/MM/DD HH:mm:ss',
};

export const I18NEXT_CONFIG = {
    DEFAULT_LANGUAGE: 'en',
    DEFAULT_NAMESPACE: 'translation',
    FALL_BACK_LANGUAGE: 'en',
    FALL_BACK_NAMESPACE: 'local',

    AVAILABLE_LANGUAGES: {
        ENGLISH: 'en',
        JAPANESE: 'jp',
    },
    TRANSLATION: {
        COMMON: 'common',
        ACTIVITY: 'activity',
    },
};

export const API = {
    DEVICE_ASSET_SERIES: `${APP_API_URL}/dev/assets/series`,
};

export const BREAKPOINT_SCREEN = {
    MOBILE: 375,
    TABLET_MEDIUM: 768,
    TABLET_LARGE: 992,
    DESKTOP_SMALL: 1368,
    DESKTOP_MEDIUM: 1368,
};

export const REGEX_CONFIG = {
    NAME_INPUT: /^[^\\/\\\\:*?"<>|]{0,255}$/,
    NAME_ATTRIBUTE: /^[a-z\d\_\s]+$/i,
    CODE_ATTRIBUTE: /^[a-zA-Z]+$/,
    CODE_REGEX: /^[a-zA-Z0-9]*$/,
    FLOAT: /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/,
    FLOATING_POINT: /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
    INTEGER: /^-?[0-9]+$/,
    NAME_TABLE_TAB_START: /^[a-zA-Z_]+$/,
    NAME_TABLE_TAB_SPECIAL: /^[a-zA-Z0-9_]*$/,
    REGEX_INTERGER: /[^0-9.-]+/,
    REGEX_TRAFFIC: /^[0-9][0-9]?$|^100$/,
    TIMESTAMP: /[0-9]{4}(-|\/)(0[1-9]|1[0-2])(-|\/)(0[1-9]|[1-2][0-9]|3[0-1]) (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]/,
    EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    REGEX_VERSION: /^[1-9][0-9]{0,8}\.(?!0[0-9])[0-9]{1,9}\.(?!0[0-9])[0-9]{1,9}$/,
    SECURITY_UPN: new RegExp("^(?=.{1,250}@)[a-zA-Z0-9](?:(?:\\.|_|-)?[a-zA-Z0-9])*@(?=.{1,250}$)[a-zA-Z0-9](?:(?:\\.|-)?[a-zA-Z0-9])*\\.[a-zA-Z]{2,}$"),
    VECTOR: /^[1-9][\d]*x[1-9][\d]*(x[1-9][\d]*)*$/,
    UUID: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/
};

export const NOTIFY_TYPE = {
    SUCCESS: 'success',
    LOADING: 'loading',
    ERROR: 'error',
    WARNING: 'warning',
};

export const SERVICE_CONNECTION_TYPE = {
    ASSSET: 'asset',
    EXTERNAL_API: 'externalAPI',
};

export const MOD_DIALOG = {
    ADD: 'ADD_NEW',
    EDIT: 'EDIT_ITEM',
};

export const CONDITIONS_SEARCH_MASTER_DATA = {
    pageSize: APP_CONFIG.MAX_INT,
    pageIndex: 0,
    clientOverride: true,
};

export const EXCEL_CSV_FILES_EXTENSION = ['.xls', '.xlsx', '.csv'];

export const HTTP_STATUS = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
    LOOKUP_TYPE_CONCURRENCY_FAIL: 301001,
};

export const ERROR_CODE = {
    ENTITY_NOT_FOUND: '101001',
    USED_OTHE_SESSION: '901001',
    DUPLICATE: '201001',
    ITEM_NOT_LOCK: '15101',
    ITEM_ALREADY_LOCK: '15102',
    ITEM_IS_LOCK_BY_OTHER: '15103',
    ITEM_IS_REQUESTED_TAKEN_BY_OTHER: '15104',
    CANT_DELETE_CAUSE_HAS_CHILD: '901002',
    ASSET_LOOP_CREATE: '11122',
    ATTRIBUTE_NOT_FOUND: '501001',
    TEMPLATE_NOT_FOUND: '10204',
    TABLE_NOT_FOUND: '301001',
    DEVICE_NOT_FOUND: '10203',
};

export const APPLY_REGEXT_INPUT = {
    GENERAL_RULE: 'generalRule',
    ATTRIBUTE_NAME_RULE: 'attributeNameRule',
    DEVICE_ID_RULE: 'deviceIdRule',
    METRIC_KEY_RULE: 'metricKeyRule',
    TABLE_NAME_RULE: 'tableNameRule',
    COLUMN_NAME_RULE: 'columnNameRule',

    NUMBER_ONLY: 'numberOnly',
    NUMBER_INTEGER: 'numberInteger',
    NUMBER_DECIMALS: 'numberDecimals',
    EMAIL: 'emailRole',
    PHONE_NUMBER: 'phoneNumber',
    GENERAL_CODE: 'generalCode',
    GENERAL_NAME: 'generalName',
    NAME_RULE: 'nameRule',
    ABBREVIATION_RULE: 'abbreviationRule',
};

export const DATE_TIME_FORMAT = {
    DISPLAY_TYPE: 'momentjs',
};

export const SIGNALR_TYPES = {
    MSG_ENTITY_USING: 'MSG_ENTITY_USING',
    DEVICE_METRICS: 'device_metrics',
    DEVICE_TEMPLATE: 'device_template',
    UOM: 'uom',
    ACTIONS: 'action',
    ASSET_TEMPLATE: 'asset_template',
    ASSET_MEDIA: 'asset_media',
    MEDIA_LIST: 'media',
    DEVICE: 'device',
    LOCK_ELEMENT: 'lock_element',
    FORCE_LOCK_ELEMENT: 'force_lock_element',
    TAKE_LOCK_ELEMENT: 'take_lock_element',
    CONFIRM_TAKEN_LOCK_ELEMENT: 'confirm_taken_lock_element',
    REJECT_TAKEN_LOCK_ELEMENT: 'reject_taken_lock_element',
    UNLOCK_ELEMENT: 'unlock_element',
    DELETE_ELEMENT: 'delete_element',
    BROKER: 'broker',
    BROKER_CHANGE: 'broker_change',
    BROKER_TEMPLATE: 'broker_template',
    ALARM: 'alarm',
    ALARM_ACTION: 'alarm_action',
    ALARM_RULE: 'alarm_rule',
    ALARM_HISTORY: 'alarm_history',
    LOCK_TEMPLATE: 'lock_template',
    UNLOCK_TEMPLATE: 'unlock_template',
    FORCE_LOCK_TEMPLATE: 'force_lock_template',
    CONFIRM_TAKEN_LOCK_TEMPLATE: 'confirm_taken_lock_template',
    REJECT_TAKEN_LOCK_TEMPLATE: 'reject_taken_lock_template',
    LOOKUP_TYPE: 'lookup_type',
    LOOKUP: 'lookup',
    TAKE_LOCK_TEMPLATE: 'take_lock_template',

    ACTIVITIES_TEMPLATE: 'activities_template',

    REPORT_TEMPLATE: 'report_template',
    REPORT: 'report',

    // message type for Table Tab
    LOCK_TABLE_TAB: 'lock_table',
    UNLOCK_TABLE_TAB: 'unlock_table',
    TAKE_LOCK_TABLE_TAB: 'take_lock_table',
    REJECT_UNLOCK_TABLE_TAB: 'reject_taken_lock_table',
    CONFIRM_UNLOCK_TABLE_TAB: 'confirm_taken_lock_table',

    // message type for asset tree
    ENTITY_CHANGE: 'entity_changed',

    USER_ACCESS_CONTROL: 'user/access_control',

    PROJECT_LIST_NOTIFY: 'project_list',
} as const;

export type SignalRKey = keyof typeof SIGNALR_TYPES;
export type SignalRType = typeof SIGNALR_TYPES[SignalRKey];

export const SIGNALR_METHODS = {
    UNREGISTER_USER_UPN: 'UnregisterUserUpn',
    REGISTER_USER_UPN: 'RegisterUserUpn',

    REGISTER_DEVICE: 'RegisterDevice',
    UNREGISTER_DEVICE: 'UnregisterDevice',

    REGISTER_BROKER: 'RegisterBroker',
    UNREGISTER_BROKER: 'UnregisterBroker',

    REGISTER_ALARM: 'RegisterAlarm',
    UNREGISTER_ALARM: 'UnregisterAlarm',

    REGISTER_PROJECT_LIST: 'RegisterProject',
    UNREGISTER_PROJECT_LIST: 'UnregisterProject',

    RECEIVE_MESSAGE: 'ReceiveMessage',
    RECEIVE_IMPORT_MESSAGE: 'ReceiveImportMessage',
    RECEIVE_EXPORT_MESSAGE: 'ReceiveExportMessage',
    RECEIVE_DEVICE_MESSAGE: 'ReceiveDeviceMessage',
    RECEIVE_USER_MESSAGE: 'ReceiveUserMessage',
    RECEIVE_BROKER_MESSAGE: 'ReceiveBrokerMessage',
    RECEIVE_ALARM_MESSAGE: 'ReceiveAlarmMessage',
    RECEIVE_PROJECT_MESSAGE: 'ReceiveProjectMessage',
} as const;

export type SignalRMethodKey = keyof typeof SIGNALR_METHODS;
export type SignalRMethodType = typeof SIGNALR_METHODS[SignalRMethodKey];

export const DEFAULT_NUMBER_OF_PROJECT_FOR_HORIZONTAL = 4;

export const RANDOM_COLOR_FOR_PROJECT_AVATAR = ['#3399FF', '#990000', '#77D78B', '#FF9900', '#990099', '#006666', '#AAD419'];

export const STATUS_OF_PROJECT_PENDING = 'PD';

export const STATUS_OF_PROJECT_ACTIVE = 'AC';

type HttpMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTION' | 'HEAD' | 'CONNECT' | 'TRACE';
export const HTTP_METHODS: Record<HttpMethodType, string> = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
    PATCH: 'PATCH',
    OPTION: 'OPTION',
    CONNECT: 'CONNECT',
    HEAD: 'HEAD',
    TRACE: 'TRACE',
};

export enum KUBEFLOW_STATUS {
    STOPPING = 'stopping',
    STOPPED = 'stopped',
    SUCCESSFUL = 'successful',
    IN_PROGRESS = 'in progress',
    ERROR = 'error',
    INITIALIZING = 'initializing',
}

export enum CONNECTION_STATUS {
    NA = 'na',
    CONNECTED = 'connected',
    DISCONNECTED = 'disconnected',
    CONNECTING = 'connecting',
}

export enum ANALYTIC_STATUS {
    NOT_PREDICTED = 'not predicted',
    SUCCESSFUL = 'successful',
    ERROR = 'error',
}

export enum OA_RELEARNING_STATUS {
    ACTIVE = 'Active',
    DE_ACTIVE = 'Deactive',
}

export enum SORT_STATUS_KEY {
    RAW_DATA = 'KubeFlowJobStatus.SortOrder',
    DATASET = 'KubeFlowJobStatus.SortOrder',
    TRAINING = 'trainingJob.kubeFlowJobStatus.id',
    MODEL_DEPLOYMENT = 'ModelDeployment.KubeFlowJobStatus.SortOrder',
}

export const URL_DOCS_CRON = 'https://www.quartz-scheduler.net/documentation/quartz-3.x/tutorial/crontriggers.html#cron-expressions';

export const MATH_EXPRESSIONS = [
    'E',
    'PI',
    'Tau',
    'Abs(',
    'Acos(',
    'Acosh(',
    'Asin(',
    'Asinh(',
    'Atan(',
    'Atan2(',
    'Atanh(',
    'BigMul(',
    'BitDecrement(',
    'BitIncrement(',
    'Cbrt(',
    'Ceiling(',
    'Clamp(',
    'CopySign(',
    'Cos(',
    'Cosh(',
    'DivRem(',
    'Exp(',
    'Floor(',
    'FusedMultiplyAdd(',
    'IEEERemainder(',
    'ILogB(',
    'Log(',
    'Log10(',
    'Log2(',
    'Max(',
    'MaxMagnitude(',
    'Min(',
    'MinMagnitude(',
    'Pow(',
    'ReciprocalEstimate(',
    'ReciprocalSqrtEstimate(',
    'Round(',
    'ScaleB(',
    'Sign(',
    'Sin(',
    'SinCos(',
    'Sinh(',
    'Sqrt(',
    'Tan(',
    'Tanh(',
    'Truncate(',
];

export const FILE_PREVIEW_NEED_CONVERT = [
    'bib',
    'doc',
    'xml',
    'docx',
    'fodt',
    'html',
    'ltx',
    'txt',
    'odt',
    'ott',
    'pdb',
    'psw',
    'rtf',
    'sdw',
    'stw',
    'sxw',
    'uot',
    'vor',
    'wps',
    'epub',
    'emf',
    'eps',
    'fodg',
    'met',
    'odd',
    'otg',
    'pbm',
    'pct',
    'pgm',
    'ppm',
    'ras',
    'std',
    'svm',
    'swf',
    'sxd',
    'sxw',
    'tif',
    'tiff',
    'xhtml',
    'xpm',
    'odp',
    'fodp',
    'potm',
    'pot',
    'pptx',
    'pps',
    'ppt',
    'pwp',
    'sda',
    'sdd',
    'sti',
    'sxi',
    'uop',
    'wmf',
    'csv',
    'dbf',
    'dif',
    'fods',
    'ods',
    'ots',
    'pxl',
    'sdc',
    'slk',
    'stc',
    'sxc',
    'uos',
    'xls',
    'xlt',
    'xlsx',
];

export const COLUMN_ACTION_WIDTH = 73;
