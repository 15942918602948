import { Checkbox } from '_core/components/Base/checkbox/Checkbox';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConsentDetail from '../ConsentDetail';
import { ConfirmConsentRequest, ConsentDialog, ConsentDialogResponse, TYPE_CODE } from './props';
import { ReactComponent as Expand } from '_core/icons/Expand.svg';
import { ReactComponent as Collapse } from '_core/icons/Collapse.svg';
import './styles.scss';
import { handleError } from '_core/helpers/HandleError';

interface Props {
    visibleConsent: boolean;
    setVisibleConsent: (visibleConsent: boolean) => void;
    getListConsentsRequest: () => Promise<ConsentDialog[]>;
    getConsentDetail: (termAndConditionId: string) => Promise<ConsentDialogResponse>;
    confirmConsent: (data: ConfirmConsentRequest) => void;
}

const ConsentDialogComponent: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation();
    const { visibleConsent, setVisibleConsent, getListConsentsRequest, getConsentDetail, confirmConsent } = props;
    const [selectedConsent, setSelectedConsent] = useState<ConsentDialog | null>(null);
    const [consents, setConsents] = useState<ConsentDialog[]>([]);
    const [isFullView, setIsFullView] = useState<boolean>(false);

    const getListConsents = async () => {
        try {
            const response: ConsentDialog[] = await getListConsentsRequest();
            if (!response.length) {
                setVisibleConsent(false);
                return;
            }
            setConsents(() => response.map((item) => ({ ...item, isChecked: true })));
        } catch (error) {
            handleError(error)
        }
    };

    useEffect(() => {
        getListConsents();
    }, []);

    const handleChangeCheckbox = (consentData: ConsentDialog) => {
        const newConsents = [...consents];
        const index: number = newConsents.findIndex((item) => item.termAndConditionId === consentData.termAndConditionId);
        if (index < 0) return;
        newConsents[index].isChecked = !consentData.isChecked;
        setConsents(newConsents);
    };

    const handleSubmitConsent = async (isAgree: boolean) => {
        try {
            confirmConsent({
                isAgree,
                termAndConditions: consents.map((item) => {
                    return {
                        termAndConditionId: item.termAndConditionId,
                        state: Boolean(item.isChecked)
                    };
                })
            });
            setConsents([]);
        } catch (error) {
            handleError(error)
        }
    };

    const renderListConsent = () => {
        return consents.map((item: ConsentDialog) => {
            return (
                <div className='item-consent' key={item.name + item.termAndConditionId}>
                    <div className="d-flex">
                        <Checkbox checked={Boolean(item.isChecked)} disabled={item.required && item.typeCode === TYPE_CODE.ADTOS} onChange={() => handleChangeCheckbox(item)}/>
                        <div
                            className="checkbox-label"
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedConsent(item);
                            }}
                        >
                            {item.name}
                        </div>
                    </div>
                </div>
            );
        });
    };

    const renderConsent = () => {
        return (
            <>
                <label>{t('PAGE.CONSENT.REQUEST_CONSENT_RULE')}:</label>
                <div className='list-consent'>{renderListConsent()}</div>
                <div className='footer-consent button-actions'>
                    <Button  onClick={() => handleSubmitConsent(false)} className='base-button cancel-type'>
                        {t('PAGE.CONSENT.NO_DISAGREE')}
                    </Button>
                    <Button onClick={() => handleSubmitConsent(true)}>
                        {t('PAGE.CONSENT.YES_AGREE')}
                    </Button>
                </div>
            </>
        );
    };

    const renderHeader = () => {
        return (
            <div className='d-flex justify-content-between align-items-center pr-2'>
                <div>{selectedConsent?.name || t('PAGE.CONSENT.CONSENT')}</div>
                {selectedConsent?.name &&
                    <div onClick={() => setIsFullView(!isFullView)} className='button-expand d-flex'>
                        {isFullView ? <Collapse/> : <Expand />}
                    </div>
                }
            </div>
        );
    };

    return (
        <>
            {consents.length ? (
                <Dialog
                    draggable={false}
                    className={`wrap-consent ${selectedConsent && 'wrap-consent-detail'} ${isFullView && 'wrap-consent-fullscreen'}`}
                    header={renderHeader}
                    visible={visibleConsent}
                    onHide={() => {
                        if (selectedConsent) {
                            setIsFullView(false);
                            setSelectedConsent(null);
                            return;
                        }
                        handleSubmitConsent(false);
                    }}
                >
                    {!selectedConsent ? renderConsent() : <ConsentDetail selectedConsent={selectedConsent} getConsentDetail={getConsentDetail} />}
                </Dialog>
            ) : null}
        </>
    );
};

export default ConsentDialogComponent;
