export interface TermItemModel {
    name: string,
    code: string
}

export const TERM_CODE = {
    CLOUD_SERVICE: 'CLOUD_SERVICE',
    SPECIFIC_LICENSE: 'SPECIFIC_LICENSE',
    DATA_PROCESSING: 'DATA_PROCESSING',
    SERVICE_LEVEL: 'SERVICE_LEVEL',
    GENERAL: 'GENERAL',
}
