export interface IPromiseCallback<T> {
    resolve: (value: T) => void;
    reject: (error: any) => void;
}

export const DefaultPromiseCallback: IPromiseCallback<any> = {
    resolve() {
    },
    reject() {
    }
};
