import { useEffect, useState } from 'react';
import { ConsentDialog, ConsentDialogResponse } from '../ConsentDialog/props';
import PreviewFileContent from './PreviewFileContent';
import { handleError } from '_core/helpers/HandleError';

interface Props {
    selectedConsent: ConsentDialog;
    getConsentDetail: (termAndConditionId: string) => Promise<ConsentDialogResponse>;
}

const ConsentDialogDetail: React.FC<Props> = (props: Props) => {
    const { selectedConsent, getConsentDetail } = props;
    const [termsAndConditions, setTermsAndConditions] = useState<ConsentDialogResponse | null>(null);

    const getDefaultConsentDetail = async () => {
        try {
            const response = await getConsentDetail(selectedConsent.termAndConditionId);
            setTermsAndConditions(response);
        } catch (error) {
            handleError(error)
        }
    };

    useEffect(() => {
        getDefaultConsentDetail();
    }, []);

    return (
        <>
            {termsAndConditions?.contentPath ? (
                <PreviewFileContent filePath={termsAndConditions.contentPath} />
            ) : (
                <div className='timeline-content' dangerouslySetInnerHTML={{ __html: termsAndConditions?.content || '' }} />
            )}
        </>
    );
};

export default ConsentDialogDetail;
