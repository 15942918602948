import { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import AppConfirmDialog from './AppConfirmDialog';
import { useAppServiceStore } from '../../../../store/AppService/AppServiceStore';
import { IAppServiceComponentRef } from '../../../../models/AppService/AppServiceModel';

const AppService = observer(() => {
    const AppConfirmDialogRef = useRef<IAppServiceComponentRef>();
    const appServiceStore = useAppServiceStore();

    useEffect(() => {
        let confirmControl = appServiceStore.confirmControl;
        if (confirmControl.active && AppConfirmDialogRef.current) {
            AppConfirmDialogRef.current.open(confirmControl.config).then(
                (response: any) => confirmControl.callback.resolve(response),
                (error: any) => confirmControl.callback.reject(error)
            );
        }
    }, [appServiceStore.confirmControl]);

    return (
        <div className={'app-service'}>
            <AppConfirmDialog ref={AppConfirmDialogRef} />
        </div>
    );
});

export default AppService;
