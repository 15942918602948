import axios from 'axios';
import { t } from 'i18next';
import { get } from 'lodash';
import { API_ERROR_CODE, VALIDATION_ERROR_CODE } from '../../constants';
import { ApiResponseError, IApiResponseError, IFieldValidationError } from '../../models/IApiResponse';
import { HistoryRef } from '../../pages/ErrorsPage/History';
import { APP_CONFIG, HTTP_STATUS, NETWORK_ERROR } from '../constants/config';
import { BE_ERROR_MESSAGES } from '../constants/message';
import notify from './notify';

export const handleError = (error: any, customErrorHandler?: (error: IApiResponseError) => void) => {
    if (error.message === NETWORK_ERROR) {
        notify.error(t('MESSAGE.COMMON.ERROR'));
        return;
    }

    if (!axios.isAxiosError(error)) return;

    const {
        status = 0,
        data: { errorCode = '', fields = [] },
    } = error.response || {};

    const { message, traceId, detailCode, errorCode: beErrorCode, error: beError } = error?.response?.data
    if (message && !traceId) {
        const text = get(BE_ERROR_MESSAGES, message) || message;
        notify.error(t(text));
    } else if (beError === API_ERROR_CODE.INVALID_GRANT) {
        window.location.reload();
    } else if (beErrorCode && detailCode) {
        const message = detailCode;
        const text = get(BE_ERROR_MESSAGES, message) || message;
        notify.error(t(text));
    } else if (status === HTTP_STATUS.UNAUTHORIZED) {
        (HistoryRef.current as any)?.replace(APP_CONFIG.ERROR_401_URL);
    } else if (status === HTTP_STATUS.FORBIDDEN) {
        (HistoryRef.current as any)?.replace(APP_CONFIG.ERROR_403_URL);
    } else if (status === HTTP_STATUS.NOT_FOUND) {
        notify.error(t(API_ERROR_CODE.ENTITY_NOT_FOUND));
        customErrorHandler?.(new ApiResponseError(status, API_ERROR_CODE.ENTITY_NOT_FOUND, fields));
    } else if (errorCode) {
        let key = errorCode;
        if (fields.some((f: IFieldValidationError) => f.errorCode === VALIDATION_ERROR_CODE.NOT_FOUND)) {
            key = API_ERROR_CODE.RELATED_ENTITY_NOT_FOUND; // Some selected item(s) have been deleted.
        }
        notify.error(t(key));
        customErrorHandler?.(new ApiResponseError(status, errorCode, fields));
    } else {
        notify.error(t('MESSAGE.COMMON.ERROR'));
    }
};