import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import AuthorizedPage from '../AuthorizedPage';

const PARENT_ROUTE = 'data-model';
const CHILD_ROUTES = {
  INDEX: '',
  RAW_DATA: {
    INDEX: ':dataModelId/raw_data',
    INFO: ':dataModelId/raw_data/:rawDataId',
    CREATE: {
        OPTION: 'raw_data/create/select-option',
        UPLOAD: 'raw_data/create/upload',
        QUERY_DB: 'raw_data/create/query-db',
        ASSET_HEALTH: 'raw_data/create/asset-health',
        CONFIRM: 'raw_data/confirm',
      },
      VISUALIZE: {
        INDEX: 'visualize',
        OUTLIER: 'visualize/outlier',
        HISTOGRAM: 'visualize/histogram',
        CORRELATION: 'visualize/correlation',
    },
    CLONE: {
      ASSET_HEALTH: 'raw_data/clone/asset-health',
      QUERY_DB: 'raw_data/clone/query-db',
    },
    DATA_SET: {
      INDEX: ':dataModelId/raw_data/:rawDataId/dataSet',
      INFO: ':dataModelId/raw_data/:rawDataId/dataSet/:dataSetId/info',
      CREATE: 'raw_data/dataset/create',
      TRAINING_LIST: ':dataModelId/raw_data/:rawDataId/dataSet/:dataSetId/training',
      TRAINING: {
        INFO: ':trainingId/info',
        VIEW_DATAFLOW: ':trainingId/info/view-dataflow/:dataflowTemplateId/:versionId',
      },
      CREATE_TRAINING: 'raw_data/dataSet/training/create',
      EDIT_TRAINING: 'raw_data/dataSet/training/:trainingId/edit',
      EDIT_FEATURES_MAPPING: 'raw_data/dataSet/training/edit-mapping',
      STATUS_TRAINING: 'raw_data/dataSet/training/:trainingId/status',
    },
  },
};

const DataModel = lazy(() => import('pages/DataModel/DataModel'));
const RawData = lazy(() => import('pages/RawData/RawData'));
const RawDataInformation = lazy(() => import('pages/RawData/RawDataInformation'));
const OptionAddRawData = lazy(() => import('pages/RawData/OptionRawData'));
const UploadRawData = lazy(() => import('pages/RawData/upload/UploadRawData'));
const QueryBD = lazy(() => import('pages/RawData/setup/QueryBD'));
const RawDataFromAM = lazy(() => import('pages/RawData/createFromAM/CreateFromAM'));
const ConfirmData = lazy(() => import('pages/ConfirmData/ConfirmData'));

// Visualize
const Visualize = lazy(() => import('pages/Visualize/Visualize'));
const VisualizeOutlier = lazy(() => import('pages/Visualize/Outlier/Outlier'));
const VisualizeHistogram = lazy(() => import('pages/Visualize/Histogram/Histogram'));
const VisualizeCorrelation = lazy(() => import('pages/Visualize/Correlation/Correlation'));
const Dataset = lazy(() => import('pages/Dataset/Dataset'));

const DatasetInformation = lazy(() => import('pages/Dataset/DatasetInformation'));
const CreateDataSet = lazy(() => import('pages/Dataset/Create'));
const Training = lazy(() => import('pages/Training/Training'));
const TrainingJobDetail = lazy(() => import('pages/Dataset/TrainingJobDetail'));
const ViewDataflow = lazy(() => import('pages/DataPreProcessing/DataFlowTemplate/PreviewDataflowTemplate/index'))
const SetupForTraining = lazy(() => import('pages/RawData/setup/SetupForTraining'));
const GraphStatusTraining = lazy(() => import('pages/RawData/setup/component/GraphStatusTraining'));

const childRouteObjects: RouteObject[] = [
  {
    path: CHILD_ROUTES.INDEX,
    element: <Outlet />,
        children: [
            {
                index: true,
                element: (
                    <AuthorizedPage
                        content={DataModel}
                    />
                )
            },
            {
                path: CHILD_ROUTES.RAW_DATA.INDEX,
                element: (
                    <AuthorizedPage
                        content={RawData}
                    />
                )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.INFO,
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element:  (
                    <AuthorizedPage
                        content={RawDataInformation}
                    />
                  )
                },
                {
                  path: CHILD_ROUTES.RAW_DATA.VISUALIZE.INDEX,
                  element: (
                    <AuthorizedPage
                        content={Visualize}
                    />
                  )
                },
                {
                  path: CHILD_ROUTES.RAW_DATA.VISUALIZE.OUTLIER,
                  element: (
                    <AuthorizedPage
                        content={VisualizeOutlier}
                    />
                  )
                },
                {
                  path: CHILD_ROUTES.RAW_DATA.VISUALIZE.HISTOGRAM,
                  element: (
                    <AuthorizedPage
                        content={VisualizeHistogram}
                    />
                  )
                },
                {
                  path: CHILD_ROUTES.RAW_DATA.VISUALIZE.CORRELATION,
                  element: (
                    <AuthorizedPage
                        content={VisualizeCorrelation}
                    />
                  )
                }
              ]
            },
            {
              path: CHILD_ROUTES.RAW_DATA.CREATE.OPTION,
              element: (
                  <AuthorizedPage
                      content={OptionAddRawData}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.CREATE.UPLOAD,
              element: (
                  <AuthorizedPage
                      content={UploadRawData}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.CREATE.QUERY_DB,
              element: (
                  <AuthorizedPage
                      content={QueryBD}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.CREATE.ASSET_HEALTH,
              element: (
                  <AuthorizedPage
                      content={RawDataFromAM}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.CREATE.CONFIRM,
              element: (
                  <AuthorizedPage
                      content={ConfirmData}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.CLONE.ASSET_HEALTH,
              element: (
                  <AuthorizedPage
                      content={RawDataFromAM}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.CLONE.QUERY_DB,
              element: (
                  <AuthorizedPage
                      content={QueryBD}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.DATA_SET.INDEX,
              element: (
                  <AuthorizedPage
                      content={Dataset}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.DATA_SET.INFO,
              element: (
                  <AuthorizedPage
                      content={DatasetInformation}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.DATA_SET.CREATE,
              element: (
                  <AuthorizedPage
                      content={CreateDataSet}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.DATA_SET.TRAINING_LIST,
              element: <Outlet />,
              children: [
                {
                  index: true,
                  element:  (
                    <AuthorizedPage
                        content={Training}
                    />
                  )
                },
                {
                  path: CHILD_ROUTES.RAW_DATA.DATA_SET.TRAINING.INFO,
                  element: (
                    <AuthorizedPage
                        content={TrainingJobDetail}
                    />
                  )
                },
                {
                  path: CHILD_ROUTES.RAW_DATA.DATA_SET.TRAINING.VIEW_DATAFLOW,
                  element: (
                    <AuthorizedPage
                        content={ViewDataflow}
                    />
                  )
                },
              ]
            },
            {
              path: CHILD_ROUTES.RAW_DATA.DATA_SET.CREATE_TRAINING,
              element: (
                  <AuthorizedPage
                      content={SetupForTraining}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.DATA_SET.EDIT_TRAINING,
              element: (
                  <AuthorizedPage
                      content={SetupForTraining}
                  />
              )
            },
            {
              path: CHILD_ROUTES.RAW_DATA.DATA_SET.STATUS_TRAINING,
              element: (
                  <AuthorizedPage
                      content={GraphStatusTraining}
                  />
              )
            },
        ]
    }
];

export const DataModelRoutes: RouteObject = {
    path: PARENT_ROUTE,
    element: <Outlet />,
    children: childRouteObjects
};
