import styled from 'styled-components';
import { BASE_COLOR } from './styleConfig';

export const BaseSpanText = styled.span`
  color: ${BASE_COLOR.white}
`
export const BaseBlackText = styled.span`
  color: ${BASE_COLOR.black}
`

export const BaseColorSeprateHeader = styled.div`
  background: ${BASE_COLOR.white};
  height: 40px;
  width: 1px;
  margin: 11px 20px 20px 20px;
`

export const BaseFormContent = styled.div`
  background: ${BASE_COLOR.white};
`

export const BaseFormContentSmall = styled.div`
  background: ${BASE_COLOR.white};
`