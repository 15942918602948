// import { IUserInfoResponse } from 'src/models';
import httpClient from '../httpClient';
import { IUserInfoResponse } from '../../models';

const userInfoService = {
    getUserInfo: async (forceUpdate = false, config: any = {}) => {
        let path = '/usr/users/info';
        if (forceUpdate) {
            path += '?forceUpdate=true';
        }
        return httpClient.get<IUserInfoResponse>(path, config);
    },

    updateUserLanguage: (languageCode: string) => {
        if (!languageCode) return;

        return httpClient.patch(`/usr/users/info`, [
            {
                op: 'update/language',
                path: '/',
                value: {
                    languageCode,
                },
            },
        ]);
    },
};

export default userInfoService;