import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import ConsentDialog from 'components/ConsentDialog';
import { ConfirmConsentRequest } from 'components/ConsentDialog/props';
import { consentService } from 'services/master';
import { cleanLocalCache } from 'utils/utils';
import { useAuthenStore } from 'store/Auth/AuthStore';
import { APP_STORAGE } from '../../constants';

const InitConsentDialog = () => {
    const authStore = useAuthenStore();
    const auth = useAuth();
    const cacheConsentRef = useRef<any>([]);

    useEffect(() => {
        if (
            !authStore.user.requiredConsent ||
            !authStore.user.currentSubscriptionId ||
            cacheConsentRef.current.includes(authStore.user.currentSubscriptionId)
        )
            return;
        cacheConsentRef.current.push(authStore.user.currentSubscriptionId);
        authStore.visibleConsent = true;
    }, [authStore.user.requiredConsent, authStore.user.currentSubscriptionId]);

    const handleConfirmConsent = async (data: ConfirmConsentRequest) => {
        const isConfirmNo = !data.isAgree || data.termAndConditions.some((item) => !item.state);
        if (isConfirmNo) {
            await consentService.confirmConsent(data);
            handleLogout();
        } else {
            authStore.visibleConsent = false;
            await consentService.confirmConsent(data);
        }
    };

    const handleLogout = () => {
        cleanLocalCache(APP_STORAGE.PREFIX);
        auth.signoutRedirect();
    };

    return (
        <>
            {authStore.visibleConsent && (
                <ConsentDialog
                    visibleConsent={authStore.visibleConsent}
                    setVisibleConsent={(value: boolean) => (authStore.visibleConsent = value)}
                    getListConsentsRequest={() => consentService.get()}
                    getConsentDetail={(termAndConditionId: string) => consentService.getConsentByIdIncludingDeleted(termAndConditionId)}
                    confirmConsent={handleConfirmConsent}
                />
            )}
        </>
    );
};

export default InitConsentDialog;
