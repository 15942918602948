import { Navigate, Outlet, RouteObject } from 'react-router-dom';
// import { APP_ROUTES } from 'constants';
import { AdminLayout } from 'layouts/PrivateLayout/AdminLayout';
import Error403 from 'pages/ErrorsPage/components/Error403';
import Error404 from 'pages/ErrorsPage/components/Error404';
import Error500 from 'pages/ErrorsPage/components/Error500';
import { ErrorBoundaryRouter } from 'pages/ErrorsPage/components/ErrorBoundaryRouter';
import { SigninSilentCallback } from 'pages/Authen/SigninSilentCallback';
import ProjectDetail from 'pages/ProjectDetail';
import { DataModelRoutes } from './RouteObject/DataModelRoutes';
import { ModelManagementRoutes } from './RouteObject/ModelManagementRoutes';
import { OnlineAnalyticRoutes } from './RouteObject/OnlineAnalyticRoutes';
import { DataForwardingRoutes } from './RouteObject/DataForwardingRoutes';
import { DataPreProcessingRoutes } from './RouteObject/DataPreProcessingRoutes';
import { APP_ROUTES } from '../constants';
import { ENV_CONFIG } from '../config';
import { SigninCallback } from 'pages/Authen/SigninCallback';
import { SignoutCallback } from 'pages/Authen/SignoutCallback';
//todo: remove
const ErrorRoutes: RouteObject = {
    path: '/error',
    element: <Outlet />,
    children: [
        {
            path: APP_ROUTES.ERROR_403_URL,
            element: <Error403 />
        },
        {
            path: APP_ROUTES.ERROR_404_URL,
            element: <Error404 />
        },
        {
            path: APP_ROUTES.ERROR_500_URL,
            element: <Error500 />
        }
    ]
};

export const AppRoutes: RouteObject[] = [
    {
        element: <AdminLayout />,
        children: [
            {
                path: ENV_CONFIG.SILENT_REDIRECT_URL,
                element: <SigninSilentCallback />
            },
            {
                path: ENV_CONFIG.REDIRECT_URL,
                element: <SigninCallback />
            },
            {
                path: ENV_CONFIG.LOGOUT_REDIRECT_URL,
                element: <SignoutCallback />
            },
            {
                path: APP_ROUTES.CALLBACK,
                lazy: async () => {
                    const page = await import('pages/Default');
                    return { Component: page.default };
                }
            },
            {
                path: APP_ROUTES.INDEX,
                lazy: async () => {
                    const page = await import('pages/Default');
                    return { Component: page.default };
                }
            },
            {
                path: APP_ROUTES.PROJECT_LIST,
                lazy: async () => {
                    const page = await import('pages/Home/ProjectManagement/ProjectManager');
                    return { Component: page.default };
                }
            },
            {
                path: APP_ROUTES.PROJECT_DETAIL,
                element: <ProjectDetail />,
                children: [
                    DataModelRoutes,
                    ModelManagementRoutes,
                    OnlineAnalyticRoutes,
                    DataForwardingRoutes,
                    DataPreProcessingRoutes
                ]
            },
            ErrorRoutes
        ],
        errorElement: <ErrorBoundaryRouter />
    }
];
