export const MESSAGES = {
    LOGIN_SUCCESS: "MLS_LOGIN_SUCCESS",
    LOGIN_FAIL: "MLS_LOGIN_FAIL",
    CREATE_SUCCESS: "MLS_CREATE_SUCCESS",
    UPDATED_SUCCESS: "MLS_UPDATED_SUCCESS",
    UPDATED_FAIL: "MLS_UPDATED_FAIL",
    DONT_PERMISSION: "MLS_DONT_PERMISSION",
    ERROR_COMMON: "MLS_ERROR_COMMON",
    ASSET_PATH_NOT_EXIST: "MLS_ASSET_PATH_NOT_EXIST",
    CREATING_PROJECT: "MLS_CREATING_PROJECT",
    CREATED_PROJECT: "MLS_CREATED_PROJECT",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    RENAME_SUCCESS: "MLS_RENAME_SUCCESS",
    DOWNLOADING: "MLS_DOWNLOADING",
    DOWNLOAD_SUCCESS: "MLS_DOWNLOAD_SUCCESS",
    CONFIRMATION_RELOAD: "MLS_CONFIRMATION_RELOAD",
    SAVE_SUCCESS: "MLS_SAVE_SUCCESS",
    DELETED_SUCCESS: "MLS_DELETED_SUCCESS"
};

export const ERR_MESSAGE = {
    MSG002: "MLS_MSG002",
    MSG_011: "MLS_MSG_011",
    MSG_012: "MLS_MSG_012"
}

export const ATTRIBUTE_MESSAGE = {
    CAN_NOT_DELETE: "MLS_CAN_NOT_DELETE",
    LOCKED_NOTIFICATION: "MLS_LOCKED_NOTIFICATION",
    REQUEST_UNLOCK: "MLS_REQUEST_UNLOCK",
    UNLOCK_DENIED: "MLS_UNLOCK_DENIED",
    DELETE_ATTRIBUTE_USED: "MLS_DELETE_ATTRIBUTE_USED"
}

export const ASSETS_MESSAGE = {
    LEAVE_WARNING: "MLS_LEAVE_WARNING",
    LOCKED_RELOAD: "MLS_LOCKED_RELOAD",
    ASSET_ENTITY_NOT_FOUND: "MLS_ASSET_ENTITY_NOT_FOUND",
    ASSET_DELETED: "MLS_ASSET_DELETED",
    ASSET_PARENT_DELETED: "MLS_ASSET_PARENT_DELETED",
    ASSET_ALIAS_PARENT_DELETED: "MLS_ASSET_ALIAS_PARENT_DELETED",
    ASSET_NOT_LOCK: "MLS_ASSET_NOT_LOCK",
    ASSET_ALREADY_LOCK: "MLS_ASSET_ALREADY_LOCK",
    ASSET_IS_LOCKED_BY_OTHER: "MLS_ASSET_IS_LOCKED_BY_OTHER",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    ASSET_IS_REQUESTED_TAKEN_BY_OTHER: "MLS_ASSET_IS_REQUESTED_TAKEN_BY_OTHER",
    ASSET_LOOP_CREATE_ATRIBUTE_WITH_TYPE_ALIAS: "MLS_ASSET_LOOP_CREATE_ATRIBUTE_WITH_TYPE_ALIAS",
    REQUIRED_DELETE_CHILD: "MLS_REQUIRED_DELETE_CHILD",
    ASSET_USE_OTHER_SESSIONS: "MLS_ASSET_USE_OTHER_SESSIONS",
    ENSURE_DELETE: "MLS_ENSURE_DELETE",
    DELETED_OR_NO_PERMISSION: "MLS_DELETED_OR_NO_PERMISSION"
}

export const DEVICES_MANAGEMENTS = {
    USED_OTHE_SESSIONS: "MLS_USED_OTHE_SESSIONS",
    ADD_SUCCESS: "MLS_ADD_SUCCESS",
    EDIT_SUCCESS: "MLS_EDIT_SUCCESS",
    DELETED_ALREADY_FOR_UPDATE: "MLS_DELETED_ALREADY_FOR_UPDATE",
    DELETED_ALREADY: "MLS_DELETED_ALREADY",
    EDIT_CONCURRENCY_FAIL: "MLS_EDIT_CONCURRENCY_FAIL"
}

export const DEVICE_TEMPLATE = {
    USED_OTHE_SESSIONS: "MLS_USED_OTHE_SESSIONS",
    TEMPLATE_NAME_DUPLICATE: "MLS_TEMPLATE_NAME_DUPLICATE",
    SAVE_SUCCESS: "MLS_SAVE_SUCCESS",
    UPDATED_SUCCESS: "MLS_UPDATED_SUCCESS",
    USED_OTHER_METRIC: "MLS_USED_OTHER_METRIC",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    LOCKED_TEMPLATES: "MLS_LOCKED_TEMPLATES",
    TEMPLATE_DELETED: "MLS_TEMPLATE_DELETED",
    TEMPLATE_NOT_FOUND: "MLS_TEMPLATE_NOT_FOUND",
    NOT_LOCK_TEMPLATE: "MLS_NOT_LOCK_TEMPLATE"
}

export const ASSET_TEMPLATE = {
    USED_OTHE_SESSIONS: "MLS_USED_OTHE_SESSIONS",
    TEMPLATE_NAME_DUPLICATE: "MLS_TEMPLATE_NAME_DUPLICATE",
    SAVE_SUCCESS: "MLS_SAVE_SUCCESS",
    SAVE_FAIL: "MLS_SAVE_FAIL",
    UPDATED_SUCCESS: "MLS_UPDATED_SUCCESS",
    UPDATED_FAIL: "MLS_UPDATED_FAIL",
    USED_OTHER_METRIC: "MLS_USED_OTHER_METRIC",
    LOCKED_TEMPLATES: "MLS_LOCKED_TEMPLATES",
    ASSET_TEMPLATE_NOT_FOUND: "MLS_ASSET_TEMPLATE_NOT_FOUND",
    ASSET_TEMPLATE_DELETED: "MLS_ASSET_TEMPLATE_DELETED",
    NOT_LOCK_TEMPLATE: "MLS_NOT_LOCK_TEMPLATE"
}

export const FILE_COMMON_MESSAGES = {
    OBJECTTYPE_REQUIRED: "MLS_OBJECTTYPE_REQUIRED",
    PROCESSING_EXPORT_FILES: "MLS_PROCESSING_EXPORT_FILES",
    FILES_TYPE_INVALID: "MLS_FILES_TYPE_INVALID",
    USED_OTHER_METRIC: "MLS_USED_OTHER_METRIC",
    FILE_NAME_TOO_LONG: "MLS_FILE_NAME_TOO_LONG",
    FILE_SIZE_TOO_LARGE: "MLS_FILE_SIZE_TOO_LARGE"
}

export const LOOKUPTYPE = {
    MUST_LETTER: "MLS_MUST_LETTER",
    LOOKUP_TYPE_REQUIRED: "MLS_LOOKUP_TYPE_REQUIRED",
    CODE_IS_REQUIRED: "MLS_CODE_IS_REQUIRED",
    CODE_NOT_SPECIAL_CHARACTER: "MLS_CODE_NOT_SPECIAL_CHARACTER",
    NAME_IS_REQUIRED: "MLS_NAME_IS_REQUIRED",
    ADD_SUCCESS: "MLS_ADD_SUCCESS",
    ADD_FAIL: "MLS_ADD_FAIL",
    EDIT_SUCCESS: "MLS_EDIT_SUCCESS",
    EDIT_FAIL: "MLS_EDIT_FAIL",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_FAIL: "MLS_DELETE_FAIL",
    EDIT_LOOKUP_TYPE_CONCURRENCY_FAIL: "MLS_EDIT_LOOKUP_TYPE_CONCURRENCY_FAIL",
    EDIT_LOOKUP_TYPE_CONCURRENCY_FAIL_RELOAD: "MLS_EDIT_LOOKUP_TYPE_CONCURRENCY_FAIL_RELOAD",
    EDIT_LOOKUP_TYPE_CONCURRENCY_FAIL_S_RELOAD: "MLS_EDIT_LOOKUP_TYPE_CONCURRENCY_FAIL_S_RELOAD",
    MSG_DUPLICATE_CODE: "MLS_MSG_DUPLICATE_CODE",
    MSG_DUPLICATE_NAME: "MLS_MSG_DUPLICATE_NAME",
    MSG_CODE_AND_NAME_DUPLICATE: "MLS_MSG_CODE_AND_NAME_DUPLICATE",
    MSG_ENTITY_USING: "MLS_MSG_ENTITY_USING"
}

export const LOOKUP = {
    ADD_SUCCESS: "MLS_ADD_SUCCESS",
    ADD_FAIL: "MLS_ADD_FAIL",
    LOOKUP_TYPE_REQUIRED: "MLS_LOOKUP_TYPE_REQUIRED",
    MUST_LETTER: "MLS_MUST_LETTER",
    CODE_IS_REQUIRED: "MLS_CODE_IS_REQUIRED",
    NAME_IS_REQUIRED: "MLS_NAME_IS_REQUIRED",
    COLOR_INVALID: "MLS_COLOR_INVALID",
    CODE_NOT_SPECIAL_CHARACTER: "MLS_CODE_NOT_SPECIAL_CHARACTER",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_FAIL: "MLS_DELETE_FAIL",
    DELETE_LOOKUP_NON_EXIST: "MLS_DELETE_LOOKUP_NON_EXIST",
    EDIT_LOOKUP_DELETED: "MLS_EDIT_LOOKUP_DELETED",
    EDIT_LOOKUP_CONCURRENCY_FAIL: "MLS_EDIT_LOOKUP_CONCURRENCY_FAIL",
    EDIT_SUCCESS: "MLS_EDIT_SUCCESS",
    EDIT_FAIL: "MLS_EDIT_FAIL",
    MSG_DUPLICATE_CODE: "MLS_MSG_DUPLICATE_CODE",
    MSG_DUPLICATE_NAME: "MLS_MSG_DUPLICATE_NAME",
    MSG_CODE_AND_NAME_DUPLICATE: "MLS_MSG_CODE_AND_NAME_DUPLICATE",
    MSG_LOOKUP_USING: "MLS_MSG_LOOKUP_USING",
    MSG_CODE_DUPLICATE_BACKEND: "MLS_MSG_CODE_DUPLICATE_BACKEND",
    MSG_NAME_DUPLICATE_BACKEND: "MLS_MSG_NAME_DUPLICATE_BACKEND",
    MSG_CODE_AND_NAME_DUPLICATE_BACKEND: "MLS_MSG_CODE_AND_NAME_DUPLICATE_BACKEND",
    MSG_ENTITY_NOT_FOUND: "MLS_MSG_ENTITY_NOT_FOUND"
}

export const INTEGRATION = {
    ADD_SUCCESS: "MLS_ADD_SUCCESS",
    ADD_FAIL: "MLS_ADD_FAIL",
    EDIT_SUCCESS: "MLS_EDIT_SUCCESS",
    EDIT_FAIL: "MLS_EDIT_FAIL",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_FAIL: "MLS_DELETE_FAIL",
    EDIT_CONCURRENCY_FAIL: "MLS_EDIT_CONCURRENCY_FAIL",
    EDIT_CONCURRENCY_FAIL_RELOAD: "MLS_EDIT_CONCURRENCY_FAIL_RELOAD",
    EDIT_CONCURRENCY_FAIL_S_RELOAD: "MLS_EDIT_CONCURRENCY_FAIL_S_RELOAD"
}
export const MEDIA_TAP_MESSAGE = {
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_FAIL: "MLS_DELETE_FAIL",
    DELETE_ATTRIBUTE_FAIL: "MLS_DELETE_ATTRIBUTE_FAIL",
    MSG_ENTITY_NOT_FOUND: "MLS_MSG_ENTITY_NOT_FOUND",
    DELETE_MEDIA_NON_EXIST: "MLS_DELETE_MEDIA_NON_EXIST"
}

export const TABLE_LIST_MANAGEMENTS = {
    USED_OTHE_SESSIONS: "MLS_USED_OTHE_SESSIONS"
}

export const MEDIA_LIST_MANAGEMENTS = {
    USED_OTHE_SESSIONS: 'MLS_USED_OTHE_SESSIONS_MEDIA',
    DELETE_MEDIA_NON_EXIST: "MLS_DELETE_MEDIA_NON_EXIST"
}

export const TABLE_TAB = {
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_FAIL: "MLS_DELETE_FAIL",
    REQUIRED_INPUT: "MLS_REQUIRED_INPUT",
    DUPLICATE_COLUMN_NAME: "MLS_DUPLICATE_COLUMN_NAME",
    NOT_LOCK_TEMPLATE: "MLS_NOT_LOCK_TABLE_TEMPLATE",
    NAME_NOT_SPECIAL_CHARACTER: "MLS_NAME_NOT_SPECIAL_CHARACTER",
    NAME_TABLE_REQUIRED: "MLS_NAME_TABLE_REQUIRED",
    NAME_TABLE_EXIST: "MLS_NAME_TABLE_EXIST",
    NAME_COLUMN_REQUIRED: "MLS_NAME_COLUMN_REQUIRED",
    MUST_LETTER: "MLS_MUST_LETTER_UNDERSCORE",
    DEFAULT_VALUE_INVALID: "MLS_DEFAULT_VALUE_INVALID",
    DATA_TYPE_REQUIRED: "MLS_DATA_TYPE_REQUIRED",
    INVALID_DEFAULT_VALUE_COlUMN: "MLS_INVALID_DEFAULT_VALUE_COlUMN",
    INVALID_DEFAULT_VALUE_COlUMNS: "MLS_INVALID_DEFAULT_VALUE_COlUMNS",
    MESSAGE_ERROR_SERVER: "MLS_MESSAGE_ERROR_SERVER",
    DELETE_TABLE_LOCK: "MLS_DELETE_TABLE_LOCK"
}

export const UOM = {
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_UOM_NONEXIST: "MLS_DELETE_UOM_NONEXIST",
    EDIT_UOM_NONEXIST: "MLS_EDIT_UOM_NONEXIST",
    ADD_SUCCESSFUL: "MLS_ADD_SUCCESSFUL",
    ADD_NAME_EXIST: "MLS_ADD_NAME_EXIST",
    UPDATE_SUCCESSFUL: "MLS_UPDATE_SUCCESSFUL",
    UPDATE_NONEXIST: "MLS_UPDATE_NONEXIST",
    UOM_LOOKUP_TYPE: "MLS_UOM_LOOKUP_TYPE",
    UOM_USED_AS_REF_UOM: "MLS_UOM_USED_AS_REF_UOM"
}

export const PERSONAL_INFORMATION = {
    EDIT_SUCCESS: "MLS_EDIT_SUCCESS",
    EDIT_FAIL: "MLS_SERVER_EDIT_FAIL"
}

export const ACTION = {
    ADD_SUCCESS: "MLS_ADD_SUCCESS",
    ADD_FAIL: "MLS_ADD_FAIL",
    EDIT_SUCCESS: "MLS_EDIT_SUCCESS",
    EDIT_FAIL: "MLS_EDIT_FAIL",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_FAIL: "MLS_DELETE_FAIL",
    EDIT_CONCURRENCY_FAIL: "MLS_EDIT_CONCURRENCY_ACTIONS_FAIL",
    EDIT_DETAIL_CONCURRENCY_FAIL: "MLS_EDIT_DETAIL_CONCURRENCY_FAIL",
    REQUIRED_CONTENT: "MLS_REQUIRED_CONTENT",
    LABEL_CONTENT: "MLS_LABEL_CONTENT"
}

export const BROKER = {
    ADD_SUCCESS: "MLS_ADD_SUCCESS",
    ADD_FAIL: "MLS_ADD_FAIL",
    EDIT_SUCCESS: "MLS_EDIT_SUCCESS",
    EDIT_FAIL: "MLS_EDIT_FAIL",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_FAIL: "MLS_DELETE_FAIL",
    EDIT_CONCURRENCY_FAIL: "MLS_EDIT_CONCURRENCY_FAIL_BROKER",
    EDIT_CONCURRENCY_FAIL_RELOAD: "MLS_EDIT_CONCURRENCY_FAIL_RELOAD_BROKER",
    EDIT_CONCURRENCY_FAIL_S_RELOAD: "MLS_EDIT_CONCURRENCY_FAIL_S_RELOAD_BROKER",
    DELETED_ALREADY: "MLS_DELETED_ALREADY_BROKER"
}
export const RULE = {
    ADD_SUCCESS: "MLS_ADD_SUCCESS",
    ADD_FAIL: "MLS_ADD_FAIL",
    EDIT_SUCCESS: "MLS_EDIT_SUCCESS",
    EDIT_FAIL: "MLS_EDIT_FAIL",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_FAIL: "MLS_DELETE_FAIL",
    EDIT_CONCURRENCY_FAIL: "MLS_EDIT_CONCURRENCY_FAIL_RULE",
    EDIT_CONCURRENCY_FAIL_RELOAD: "MLS_EDIT_CONCURRENCY_FAIL_RELOAD_RULE",
    EDIT_CONCURRENCY_FAIL_S_RELOAD: "MLS_EDIT_CONCURRENCY_FAIL_S_RELOAD_RULE",
    DELETED_ALREADY: "MLS_DELETED_ALREADY_RULE"
}
export const ACTIVITY_LOG = {
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_NONEXIST: "MLS_DELETE_NONEXIST",
    EDIT_NONEXIST: "MLS_EDIT_NONEXIST",
    ADD_SUCCESSFUL: "MLS_ADD_SUCCESSFUL",
    ADD_NAME_EXIST: "MLS_ADD_NAME_EXIST",
    UPDATE_SUCCESSFUL: "MLS_UPDATE_SUCCESSFUL",
    UPDATE_NONEXIST: "MLS_UPDATE_NONEXIST_ACTIVITY",
    LOOKUP_TYPE: "MLS_LOOKUP_TYPE"
}

export const DATA_MODEL = {
    ADD_SUCCESS: "MLS_ADD_SUCCESS",
    ADD_FAIL: "MLS_ADD_FAIL",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_FAIL: "MLS_DELETE_FAIL",
    DELETE_DATA_MODEL_NONEEXIST: "MLS_DELETE_DATA_MODEL_NONEEXIST"
};

export const PROJECT_LIST_MANAGEMENT = {
    MSG_CODE_DUPLICATE: "MLS_MSG_CODE_DUPLICATE",
    DUPLICATE_ERROR: "MLS_DUPLICATE_ERROR",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS",
    DELETE_FAILED: "MLS_DELETE_FAILED",
    DONT_HAVE_PERMISSION: "MLS_DONT_HAVE_PERMISSION"
}

export const RAW_DATA = {
    SPLIT_SUCCESS: "MLS_SPLIT_SUCCESS",
    SPLIT_ERROR: "MLS_SPLIT_ERROR",
    DELETE_ERROR: "MLS_DELETE_ERROR",
    FILTER_PLACEHOLDER: "MLS_FILTER_PLACEHOLDER",
    FILTER_MODEL_PLACEHOLDER: "MLS_FILTER_MODEL_PLACEHOLDER",
    CLONE_SUCCESS: "MLS_CLONE_SUCCESS",
    CLONE_UNSUCCESS: "MLS_CLONE_UNSUCCESS",
    INVALID_CONNECTION_STRING_QUERY_RAWDATA: "MLS_INVALID_CONNECTION_STRING_QUERY_RAWDATA",
    INVALID_QUERY_STRING_QUERY_RAWDATA: "MLS_INVALID_QUERY_STRING_QUERY_RAWDATA",
    DATASET_CONNECTION_GET_FAILED: "MLS_DATASET_CONNECTION_GET_FAILED",
    DATASET_ASSET_GET_FAILED: "MLS_DATASET_ASSET_GET_FAILED",
    DATASET_ASSET_ATTRIBUTE_GET_FAILED: "MLS_DATASET_ASSET_ATTRIBUTE_GET_FAILED",
    DATASET_ASSET_TIMESERIES_GET_FAILED: "MLS_DATASET_ASSET_TIMESERIES_GET_FAILED",
    INVALID_AM_CONNECTION: "MLS_INVALID_AM_CONNECTION",
    INVALID_ASSET: "MLS_INVALID_ASSET",
    INVALID_ATTRIBUTE: "MLS_INVALID_ATTRIBUTE",
    CONNECTION_DELETED: "MLS_CONNECTION_DELETED",
    ASSET_NOT_FOUND: "MLS_ASSET_NOT_FOUND",
    PROJECT_NOT_FOUND: "MLS_PROJECT_NOT_FOUND",
    RENAME_SUCCESS: "MLS_RENAME_SUCCESS",
    RAW_DATA_NOT_FOUND: "MLS_RAW_DATA_NOT_FOUND"
}
export const DATASET = {
    DELETE_ERROR: "MLS_DELETE_ERROR_DATASET",
    DELETE_TRAINING_ERROR: "MLS_DELETE_TRAINING_ERROR",
    STOPPING_TRAINING: "MLS_STOPPING_TRAINING",
    STOPPED_TRAINING: "MLS_STOPPED_TRAINING",
    DELETE_ERROR_STATUS_IN_PROGRESS: "MLS_DELETE_ERROR_STATUS_IN_PROGRESS",
    DELETE_ERROR_STATUS_STOPPING: "MLS_DELETE_ERROR_STATUS_STOPPING",
    DELETE_ERROR_STATUS_DEPLOYED: "MLS_DELETE_ERROR_STATUS_DEPLOYED",
    DELETE_ERROR_STATUS_API_IN_PROGRESS: "MLS_DELETE_ERROR_STATUS_API_IN_PROGRESS",
    DELETE_ERROR_STATUS_API_STOPPING: "MLS_DELETE_ERROR_STATUS_API_STOPPING",
    DELETE_ERROR_STATUS_API_DEPLOYED: "MLS_DELETE_ERROR_STATUS_API_DEPLOYED",
    DELETE_ERROR_STATUS_API_INITIALIZING: "MLS_DELETE_ERROR_STATUS_API_INITIALIZING",
    NUMBER_ROW_BIGGER_0: "MLS_NUMBER_ROW_BIGGER_0",
    DATASET_NOT_FOUND: "MLS_DATASET_NOT_FOUND"
}
export const TRAINING_JOB_DETAIL = {
    DEPLOYING_API: "MLS_DEPLOYING_API",
    STOP_DEPLOY_API: "MLS_STOP_DEPLOY_API",
    INVALID_STOP_DEPLOY: "MLS_INVALID_STOP_DEPLOY",
    CANNOT_GET_EXAMPLE_DATA: "MLS_CANNOT_GET_EXAMPLE_DATA",
    CANNOT_SHOW_CHART: "MLS_CANNOT_SHOW_CHART"
}

export const TRAINING_SET_UP = {
    TITLE: "MLS_TITLE",
    TRAINING_NAME: "MLS_TRAINING_NAME",
    ALGORITHM_TYPE: "MLS_ALGORITHM_TYPE",
    ALGORITHM_TYPE_REQUIRED: "MLS_ALGORITHM_TYPE_REQUIRED",
    ALGORITHM_NAME: "MLS_ALGORITHM_NAME",
    ALGORITHM_NAME_REQUIRED: "MLS_ALGORITHM_NAME_REQUIRED",
    START_TRAINING: "MLS_START_TRAINING",
    CANCEL: "MLS_CANCEL",
    MODEL_NAME: "MLS_MODEL_NAME",
    DATA_FLOW: "MLS_DATA_FLOW",
    DEPLOY_AFTER_TRAINING: "MLS_DEPLOY_AFTER_TRAINING"
}
export const TRAINING_STOP = {
    "TRAINING.TRAINING.STOP.STOPPING_FAILED": "TRAINING.TRAINING.STOP.STOPPING_FAILED",
}

export const SERVICE_CONNECTION = {
    DELETED_ALREADY: 'Service Connection (s) are deleted. Please reload this page to get the latest data.',
    DUPLICATE_CONNECTION: 'This Service Connection has already existed. Please create another one.',
}
export const MODEL_MANAGEMENT = {
    CONFIRM_STOP: 'MLS_MODEL_MANAGEMENT_CONFIRM_STOP',
    MESSAGE_BE: {
        ONLINE_ANALYTIC_STARTED: 'MLS_ONLINE_ANALYTIC_STARTED',
    },
    MESSAGE_DISPLAY: {
        ONLINE_ANALYTIC_STARTED: 'MLS_ONLINE_ANALYTIC_STARTED',
    },
}
export const DATAFLOW_TESTING = {
    STOP_TESTING: "DATAFLOW_TESTING_STOP_TESTING",
}
export const HISTOGRAM = {
    MESSAGE_BE: {
        DATASET_RAW_DATA_GET_NOT_FOUND: 'DATASET.RAW_DATA.GET.NOT_FOUND',
        DATASET_RAW_DATA_CHECK_STATUS_NOT_SUCCESSFUL: 'DATASET.RAW_DATA.CHECK_STATUS.NOT_SUCCESSFUL',
        DATASET_DATA_MODEL_FEATURE_GET_NOT_FOUND: 'DATASET.DATA_MODEL_FEATURE.GET.NOT_FOUND',
        DATASET_RAW_DATA_FEATURE_HISTOGRAM_GET_NOT_FOUND: 'DATASET.RAW_DATA_FEATURE_HISTOGRAM.GET.NOT_FOUND',
        DATASET_RAW_DATA_FEATURE_STATISTIC_VALUE_GET_NOT_FOUND: 'DATASET.RAW_DATA_FEATURE_STATISTIC_VALUE.GET.NOT_FOUND',
        DATASET_RAW_DATA_FEATURE_HISTOGRAM_CHECK_BIN_TOTAL_MUST_GREATER_THAN_ZERO: 'DATASET.RAW_DATA_FEATURE_HISTOGRAM.CHECK_BIN_TOTAL.MUST_GREATER_THAN_ZERO',
        DATASET_RAW_DATA_FEATURE_HISTOGRAM_COMPUTE_ERROR: 'DATASET.RAW_DATA_FEATURE_HISTOGRAM.COMPUTE.ERROR',
        DATASET_RAW_DATA_FEATURE_HISTOGRAM_CHECK_BIN_TOTAL_EXISTED: 'DATASET.RAW_DATA_FEATURE_HISTOGRAM.CHECK_BIN_TOTAL.EXISTED',
    },
    MESSAGE_DISPLAY: {
        DATASET_RAW_DATA_GET_NOT_FOUND: "MLS_DATASET_RAW_DATA_GET_NOT_FOUND",
        DATASET_RAW_DATA_CHECK_STATUS_NOT_SUCCESSFUL: "MLS_DATASET_RAW_DATA_CHECK_STATUS_NOT_SUCCESSFUL",
        DATASET_DATA_MODEL_FEATURE_GET_NOT_FOUND: "MLS_DATASET_DATA_MODEL_FEATURE_GET_NOT_FOUND",
        DATASET_RAW_DATA_FEATURE_HISTOGRAM_GET_NOT_FOUND: "MLS_DATASET_RAW_DATA_FEATURE_HISTOGRAM_GET_NOT_FOUND",
        DATASET_RAW_DATA_FEATURE_STATISTIC_VALUE_GET_NOT_FOUND: "MLS_DATASET_RAW_DATA_FEATURE_STATISTIC_VALUE_GET_NOT_FOUND",
        DATASET_RAW_DATA_FEATURE_HISTOGRAM_CHECK_BIN_TOTAL_MUST_GREATER_THAN_ZERO: "MLS_DATASET_RAW_DATA_FEATURE_HISTOGRAM_CHECK_BIN_TOTAL_MUST_GREATER_THAN_ZERO",
        DATASET_RAW_DATA_FEATURE_HISTOGRAM_COMPUTE_ERROR: "MLS_DATASET_RAW_DATA_FEATURE_HISTOGRAM_COMPUTE_ERROR",
        DATASET_RAW_DATA_FEATURE_HISTOGRAM_CHECK_BIN_TOTAL_EXISTED: "MLS_DATASET_RAW_DATA_FEATURE_HISTOGRAM_CHECK_BIN_TOTAL_EXISTED"
    }
}

export const ONLINE_ANALYTIC_MESSAGE = {
    MESSAGE_BE: {
        RENAME_DUPLICATE: 'ONLINE_ANALYTIC.DUPLICATE_NAME',
        MODEL_NOT_FOUND: 'MODEL.MODEL.GET.NOT_FOUND',
        MODEL_NOT_DEPLOY: 'MODEL_DEPLOYMENT.NOT_FOUND',
        MODEL_STOPPED: 'MODEL_DEPLOYMENT.STATUS_IS_NOT_SUCCESSFUL',
        ONLINE_ANALYTIC_NOT_FOUND: 'ONLINE_ANALYTIC.NOT_FOUND',
        RELEARNING_MODEL_DEPLOYMENT_NOT_FOUND: 'RELEARNING_MODEL_DEPLOYMENT.NOT_FOUND',
        ALREADY_STARTED: 'ONLINE_ANALYTIC.ALREADY_STARTED'
    },
    MESSAGE_DISPLAY: {
        RENAME_DUPLICATE: "MLS_RENAME_DUPLICATE",
        ADD_SUCCESS: "MLS_ADD_SUCCESS",
        UPDATE_SUCCESS: "MLS_UPDATE_SUCCESS",
        MODEL_NOT_FOUND: "MLS_MODEL_NOT_FOUND",
        MODEL_NOT_DEPLOY: "MLS_MODEL_NOT_DEPLOY",
        MODEL_STOPPED: "MLS_MODEL_STOPPED",
        ONLINE_ANALYTIC_NOT_FOUND: "MLS_ONLINE_ANALYTIC_NOT_FOUND",
        RELEARNING_MODEL_DEPLOYMENT_NOT_FOUND: "MLS_RELEARNING_MODEL_DEPLOYMENT_NOT_FOUND"
    },
    STARTING: "MLS_STARTING",
    STOPPING: "MLS_STOPPING",
    COPIED: "MLS_COPIED",
    CONFIRM_STOP: "MLS_CONFIRM_STOP",
    START_SUCCESS: "MLS_START_SUCCESS",
    STOP_SUCCESS: "MLS_STOP_SUCCESS",
    TESTING: "MLS_TESTING",
    TEST_OVERTIME: "MLS_TEST_OVERTIME",
    INPUT_REQUIRED: "MLS_INPUT_REQUIRED",
    INPUT_INVALID: "MLS_INPUT_INVALID",
    INPUT_INVALID_FEATURES_KEY: "MLS_INPUT_INVALID_FEATURES_KEY",
    INPUT_INVALID_FEATURES_VALUE: "MLS_INPUT_INVALID_FEATURES_VALUE",
    INPUT_MISSING_FEATURES_KEY: "MLS_INPUT_MISSING_FEATURES_KEY",
    INPUT_DUPLICATED_FEATURES_KEY: "MLS_INPUT_DUPLICATED_FEATURES_KEY",
    INPUT_MISSING_KEY: "MLS_INPUT_MISSING_KEY",
    INPUT_INVALID_ID: "MLS_INPUT_INVALID_ID",
    INPUT_INVALID_MLSTENANTID: "MLS_INPUT_INVALID_MLSTENANTID",
    INPUT_INVALID_MLSSUBSCRIPTIONID: "MLS_INPUT_INVALID_MLSSUBSCRIPTIONID",
    INPUT_INVALID_MLSPROJECTID: "MLS_INPUT_INVALID_MLSPROJECTID",
    CANNOT_RELEARNING: "MLS_CANNOT_RELEARNING",
    ATTRIBUTE_NOT_FOUND: "MLS_ATTRIBUTE_NOT_FOUND",
    ASSET_NOT_FOUND: "MLS_ASSET_NOT_FOUND_OA",
    RELEARNING_CONFIG_NOT_ACTIVE: "MLS_RELEARNING_CONFIG_NOT_ACTIVE",
    ONLINE_ANALYTIC_NOT_FOUND: "MLS_ONLINE_ANALYTIC_NOT_FOUND_OA",
    UPDATE_MODEL_COMPARISON_SUCCESS: "MLS_UPDATE_MODEL_COMPARISON_SUCCESS",
    EDIT_MODEL_SUCCESS: "MLS_EDIT_MODEL_SUCCESS",
    CONFIRM_DIALOG_EDIT_MODEL_SAVE: "MLS_CONFIRM_DIALOG_EDIT_MODEL_SAVE",
    CONFIRM_DIALOG_EDIT_MODEL_CANCEL: "MLS_CONFIRM_DIALOG_EDIT_MODEL_CANCEL",
    DATASET_RAW_NOT_FOUND: "MLS_DATASET_RAW_NOT_FOUND",
    TRAINING_NOT_FOUND: "MLS_TRAINING_NOT_FOUND",
    DELETE_SUCCESS: "MLS_DELETE_SUCCESS_OA",
    CAN_NOT_DELETE: "MLS_CAN_NOT_DELETE_OA"
}

export const BE_ERROR_MESSAGES = {
    "STORAGE.FILE.UPLOAD.FILE_NAME_TOO_LONG": "STORAGE.FILE.UPLOAD.FILE_NAME_TOO_LONG",
    "MODEL.MODEL_DEPLOYMENT.GET.STATUS_IS_NOT_SUCCESSFUL": "MODEL.MODEL_DEPLOYMENT.GET.STATUS_IS_NOT_SUCCESSFUL",
    "MODEL.ONLINE_ANALYTIC.GET.ONLINE_ANALYTIC_NOT_STARTED": "MODEL.ONLINE_ANALYTIC.GET.ONLINE_ANALYTIC_NOT_STARTED",
    "DATASET.RAW_DATA_FEATURE_HISTOGRAM.COMPUTE.ERROR": "DATASET.RAW_DATA_FEATURE_HISTOGRAM.COMPUTE.ERROR",
    "DATASET.PREVIEW_SAMPLE_PREDICT_FAILED": "DATASET.PREVIEW_SAMPLE_PREDICT_FAILED",
    "DATASET.PREVIEW_FAILED": "DATASET.PREVIEW_FAILED",
    "RAW_DATA.PREVIEW_FAILED": "RAW_DATA.PREVIEW_FAILED",
    "RAW_DATA.FEATURE_HISTOGRAM_COMPUTE_ERROR": "RAW_DATA.FEATURE_HISTOGRAM_COMPUTE_ERROR",
    "TRAINING.TRAINING.STOP.STOPPING_FAILED": "MLS.TRAINING.TRAINING.STOP.STOPPING_FAILED",
    "MODEL.MODEL.PREDICT.PREDICT_FAILED": "MODEL.MODEL.PREDICT.PREDICT_FAILED",
    "MLS_SERVING_SERVICE": "MLS_SERVING_SERVICE",
    "ASSET.GET_ERROR": "ASSET.GET_ERROR",
    "ASSET.NOT_FOUND": "ASSET.NOT_FOUND",
    "ASSET.ID_INVALID": "ASSET.ID_INVALID",
    "ASSET_ATTRIBUTE.NOT_FOUND": "ASSET_ATTRIBUTE.NOT_FOUND",
    "ASSET_ATTRIBUTE.ID_IS_SELECTED": "ASSET_ATTRIBUTE.ID_IS_SELECTED",
    "PROJECT.NOT_FOUND": "PROJECT.NOT_FOUND",
    "SUBSCRIPTION.NOT_FOUND": "SUBSCRIPTION.NOT_FOUND",
    "TENANT.ID_INVALID": "TENANT.ID_INVALID",
    "TENANT.NOT_FOUND": "TENANT.NOT_FOUND",
    "PROJECT.ID_INVALID": "PROJECT.ID_INVALID",
    "SUBSCRIPTION.ID_INVALID": "SUBSCRIPTION.ID_INVALID",
    "MODEL_FEATURE.NOT_FOUND": "MODEL_FEATURE.NOT_FOUND",
    "MLS_SERVING_SERVICE400": "MLS_SERVING_SERVICE400",
    "EVENT_FORWARDING.CREATE_ERROR": "EVENT_FORWARDING.CREATE_ERROR",
    "EVENT_FORWARDING.DELETE_ERROR": "EVENT_FORWARDING.DELETE_ERROR",
    "RELEARNING_CONFIG.DUPLICATE_NAME": "RELEARNING_CONFIG.DUPLICATE_NAME",
    "RELEARNING_CONFIG.NOT_ACTIVE": "RELEARNING_CONFIG.NOT_ACTIVE",
    "MODEL.ONLINE_ANALYTIC.GET.NOT_FOUND": "MODEL.ONLINE_ANALYTIC.GET.NOT_FOUND",
    "RELEARNING_SCHEDULER.IS_STARTED": "RELEARNING_SCHEDULER.IS_STARTED",
    "RELEARNING_CONFIG.NOT_SAME": "RELEARNING_CONFIG.NOT_SAME",
    "MODEL.MODEL.GET.NOT_FOUND": "MODEL.MODEL.GET.NOT_FOUND",
    "DATASET.ASSET_TIMESERIES.GET.FAILED": "DATASET.ASSET_TIMESERIES.GET.FAILED",
    "ONLINE_ANALYTIC.ALREADY_STARTED": "ONLINE_ANALYTIC.ALREADY_STARTED",
    "ONLINE_ANALYTIC.ONLINE_ANALYTIC_NOT_STARTED": "ONLINE_ANALYTIC.ONLINE_ANALYTIC_NOT_STARTED",
    "MODEL_FEATURE.MISSING": "MODEL_FEATURE.MISSING",
    "MODEL_TARGET_COLUMN.MISSING": "MODEL_TARGET_COLUMN.MISSING",
    "MODEL_DEPLOYMENT.STATUS_IS_NOT_SUCCESSFUL": "MODEL_DEPLOYMENT.STATUS_IS_NOT_SUCCESSFUL",
    "ONLINE_ANALYTIC.NOT_FOUND": "ONLINE_ANALYTIC.NOT_FOUND",
    "MODEL.ONLINE_ANALYTIC.TEST.TENANT_ID_": "MODEL.ONLINE_ANALYTIC.TEST.TENANT_ID_",
    "MODEL.ONLINE_ANALYTIC.TEST.SUBSCRIPTION_ID_": "MODEL.ONLINE_ANALYTIC.TEST.SUBSCRIPTION_ID_",
    "MODEL.ONLINE_ANALYTIC.TEST.PROJECT_ID_INVALID": "MODEL.ONLINE_ANALYTIC.TEST.PROJECT_ID_INVALID",
    "RECURRING_JOB.UPDATE_ERROR": "RECURRING_JOB.UPDATE_ERROR",
    "RECURRING_JOB.DELETE_ERROR": "RECURRING_JOB.DELETE_ERROR",
    "RECURRING_JOB.CREATE_ERROR": "RECURRING_JOB.CREATE_ERROR",
    "RELEARNING_SCHEDULER.CRON_REQUIRED": "RELEARNING_SCHEDULER.CRON_REQUIRED",
    "RELEARNING_SCHEDULER.DATA_PERIOD_REQUIRED": "RELEARNING_SCHEDULER.DATA_PERIOD_REQUIRED",
    "RELEARNING_SCHEDULER.TIME_UNIT_REQUIRED": "RELEARNING_SCHEDULER.TIME_UNIT_REQUIRED",
    "RELEARNING_SCHEDULER.NUMBER_RETAINED_MODEL_REQUIRED": "RELEARNING_SCHEDULER.NUMBER_RETAINED_MODEL_REQUIRED",
    "RELEARNING_SCHEDULER.CRON_INVALID": "RELEARNING_SCHEDULER.CRON_INVALID",
    "RELEARNING_SCHEDULER.TIME_RANGE_INVALID": "RELEARNING_SCHEDULER.TIME_RANGE_INVALID",
    "ONLINE_ANALYTIC.FROM_EXTERNAL_SYSTEM": "ONLINE_ANALYTIC.FROM_EXTERNAL_SYSTEM",
    "RELEARNING_DEPLOY_STRATEGY.NOT_FOUND": "RELEARNING_DEPLOY_STRATEGY.NOT_FOUND",
    "RELEARNING_DEPLOY_STRATEGY_SCHEMA.NOT_FOUND": "RELEARNING_DEPLOY_STRATEGY_SCHEMA.NOT_FOUND",
    "RELEARNING_JOB.ALREADY_DEACTIVATE": "RELEARNING_JOB.ALREADY_DEACTIVATE",
    "RELEARNING_DEPLOY_MODEL.CANARY_TRAFFIC_INVALID": "RELEARNING_DEPLOY_MODEL.CANARY_TRAFFIC_INVALID",
    "RELEARNING_DEPLOY_MODEL.CANARY_TOTAL_TRAFFIC_INVALID": "RELEARNING_DEPLOY_MODEL.CANARY_TOTAL_TRAFFIC_INVALID",
    "RELEARNING_DEPLOY_STRATEGY.NOT_SUPPORT": "RELEARNING_DEPLOY_STRATEGY.NOT_SUPPORT",
    "RELEARNING.COMPUTE_COMPARISION_AT_LEAST_TWO_MODELS": "RELEARNING.COMPUTE_COMPARISION_AT_LEAST_TWO_MODELS",
    "RELEARNING.COMPUTE_COMPARISION_ERROR": "RELEARNING.COMPUTE_COMPARISION_ERROR",
    "BROKER.ID_INVALID": "BROKER.ID_INVALID",
    "BROKER.NOT_FOUND": "BROKER.NOT_FOUND",
    "DATA_FORWARDING.NOT_FOUND": "DATA_FORWARDING.NOT_FOUND",
    "DATA_FORWARDING.DUPLICATE_NAME": "DATA_FORWARDING.DUPLICATE_NAME",
    "DATA_FORWARDING.NAME_REQUIRED": "DATA_FORWARDING.NAME_REQUIRED",
    "DATA_FORWARDING.TYPE_REQUIRED": "DATA_FORWARDING.TYPE_REQUIRED",
    "DATA_FORWARDING.DETAILS_REQUIRED": "DATA_FORWARDING.DETAILS_REQUIRED",
    "ONLINE_ANALYTIC.DATA_FORWARDING_IS_EXIST": "ONLINE_ANALYTIC.DATA_FORWARDING_IS_EXIST",
    "DATA_FORWARDING.ONLINE_ANALYTIC_IS_EXIST": "DATA_FORWARDING.ONLINE_ANALYTIC_IS_EXIST",
    "BROKER.TYPE_REST_API": "BROKER.TYPE_REST_API",
    "RELEARNING_MODEL_DEPLOYMENT.ROLLBACK_ACTIVE": "RELEARNING_MODEL_DEPLOYMENT.ROLLBACK_ACTIVE",
    "DATA_FORWARDING.GET_SAMPLE_PAYLOAD_ERROR": "DATA_FORWARDING.GET_SAMPLE_PAYLOAD_ERROR",
    "MODEL.MODEL_ID_INVALID": "MODEL.MODEL_ID_INVALID",
    "MODEL_DEPLOYMENT.STATUS_ID_INVALID": "MODEL_DEPLOYMENT.STATUS_ID_INVALID",
    "MODEL.MODEL_PARTICIPATE_IN_ACTIVE_DEPLOYMENT_STRATEGY": "MODEL.MODEL_PARTICIPATE_IN_ACTIVE_DEPLOYMENT_STRATEGY",
    "MODEL.STOP_MULTIPLE_MODELS_API_ERROR": "MODEL.STOP_MULTIPLE_MODELS_API_ERROR",
    "MODEL.MODEL_IS_ORIGINAL_MODEL": "MODEL.MODEL_IS_ORIGINAL_MODEL",
    "MODEL.NOT_FOUND": "MODEL.NOT_FOUND",
    "MSG_GETTING_MODEL_ERROR": "MSG_GETTING_MODEL_ERROR",
    "TRAINING.DELETE_TRAINING_ERROR": "TRAINING.DELETE_TRAINING_ERROR",
    "ENTITY.ENTITY_HAS_ORIGINAL_MODEL": "ENTITY.ENTITY_HAS_ORIGINAL_MODEL",
    "ENTITY.ENTITY_HAS_MODEL_PARTICIPATE_IN_ACTIVE_DEPLOYMENT_STRATEGY": "ENTITY.ENTITY_HAS_MODEL_PARTICIPATE_IN_ACTIVE_DEPLOYMENT_STRATEGY",
    "TRAINING.DELETE_MODEL_ERROR": "TRAINING.DELETE_MODEL_ERROR",
    "RELEARNING_DEPLOY_MODEL.REPLACEMENT_TRAFFIC_INVALID": "RELEARNING_DEPLOY_MODEL.REPLACEMENT_TRAFFIC_INVALID",
    "RELEARNING_MODEL.NOT_FOUND": "RELEARNING_MODEL.NOT_FOUND",
    "RELEARNING_MODEL_CONFIG.NOT_FOUND": "RELEARNING_MODEL_CONFIG.NOT_FOUND",
    "RELEARNING_MODEL_CONFIG.SELECTED_AUTO_DEPLOY": "RELEARNING_MODEL_CONFIG.SELECTED_AUTO_DEPLOY",
    "RELEARNING_MODEL_CONFIG.SELECTED_NO_AUTO_DEPLOY": "RELEARNING_MODEL_CONFIG.SELECTED_NO_AUTO_DEPLOY",
    "MODEL.CAN_NOT_STOP_MODEL_PARTICIPATE_IN_ACTIVE_DEPLOYMENT_STRATEGY": "MODEL.CAN_NOT_STOP_MODEL_PARTICIPATE_IN_ACTIVE_DEPLOYMENT_STRATEGY",
    "TRAINING_JOB.KUBE_FLOW_STATUS_INVALID": "TRAINING_JOB.KUBE_FLOW_STATUS_INVALID",
    "DATASET.STATUS_NOT_SUCCESSFUL": "DATASET.STATUS_NOT_SUCCESSFUL",
    "TRAINING.DUPLICATE_NAME": "TRAINING.DUPLICATE_NAME",
    "DATASET.NOT_FOUND": "DATASET.NOT_FOUND",
    "TRAINING.CREATE_ERROR": "TRAINING.CREATE_ERROR",
    "FEATURE_KEY.NOT_FOUND": "FEATURE_KEY.NOT_FOUND",
    "FLOW_SETTING.STARED_STAGE_ID_REQUIRED": "FLOW_SETTING.STARED_STAGE_ID_REQUIRED",
    "FLOW_SETTING.FINISHED_STAGE_ID_REQUIRED": "FLOW_SETTING.FINISHED_STAGE_ID_REQUIRED",
    "RAW_DATA.NOT_FOUND": "RAW_DATA.NOT_FOUND",
    "RAW_DATA.STATUS_NOT_SUCCESSFUL": "RAW_DATA.STATUS_NOT_SUCCESSFUL",
    "DATA_MODEL.NOT_FOUND": "DATA_MODEL.NOT_FOUND",
    "DATA_MODEL.DUPLICATE_NAME": "DATA_MODEL.DUPLICATE_NAME",
    "MODEL.DUPLICATE_NAME": "MLS.MODEL.DUPLICATE_NAME",
    "TRAINING.TRAINING.GET.DUPLICATE_NAME": "MLS.TRAINING.TRAINING.GET.DUPLICATE_NAME",
    "MSG_ENTITY_NOT_FOUND": "MSG_ENTITY_NOT_FOUND",
    "TRAINING.NOT_FOUND": "TRAINING.NOT_FOUND",
    "ONLINE_ANALYTIC.DELETE.STARTED_ITEMS": "ONLINE_ANALYTIC.DELETE.STARTED_ITEMS",
    "FUNCTION_CATEGORY.DUPLICATE_NAME": "FUNCTION_CATEGORY.DUPLICATE_NAME",
    "FUNCTION_CATEGORY.PARENT_ID_INVALID": "FUNCTION_CATEGORY.PARENT_ID_INVALID",
    "FUNCTION.DUPLICATE_NAME": "FUNCTION.DUPLICATE_NAME",
    "FUNCTION_CATEGORY.NOT_FOUND": "FUNCTION_CATEGORY.NOT_FOUND",
    "FUNCTION.NOT_FOUND": "FUNCTION.NOT_FOUND",
    "DATA_FLOW_TEMPLATE.DUPLICATE_NAME": "DATA_FLOW_TEMPLATE.DUPLICATE_NAME",
    "DATA_FLOW_TEMPLATE.NOT_FOUND": "DATA_FLOW_TEMPLATE.NOT_FOUND",
    "FUNCTION.ALREADY_USED": "FUNCTION.ALREADY_USED",
    "FUNCTION_CONFIG.ENVIRONMENT_DUPLICATE_NAME": "FUNCTION_CONFIG.ENVIRONMENT_DUPLICATE_NAME",
    "FUNCTION_CATEGORY.HAS_FUNCTION_ALREADY_USED": "FUNCTION_CATEGORY.HAS_FUNCTION_ALREADY_USED",
    "DATA_FLOW_EXECUTION.NOT_FOUND": "DATA_FLOW_EXECUTION.NOT_FOUND",
    "DATA_FLOW_EXECUTION.DATASET_ID_REQUIRED": "DATA_FLOW_EXECUTION.DATASET_ID_REQUIRED",
    "DATA_FLOW_EXECUTION.DATASET_MAPPING_ID_REQUIRED": "DATA_FLOW_EXECUTION.DATASET_MAPPING_ID_REQUIRED",
    "DATA_FLOW_EXECUTION.TEMPLATE_ID_REQUIRED": "DATA_FLOW_EXECUTION.TEMPLATE_ID_REQUIRED",
    "DATA_FLOW_EXECUTION.TEMPLATE_VERSION_ID_REQUIRED": "DATA_FLOW_EXECUTION.TEMPLATE_VERSION_ID_REQUIRED",
    "DATA_FLOW_EXECUTION.START_ROW_INDEX_GREATER_THAN_OR_EQUAL_TO_0": "DATA_FLOW_EXECUTION.START_ROW_INDEX_GREATER_THAN_OR_EQUAL_TO_0",
    "DATA_FLOW_EXECUTION.NUMBER_OF_RECORDS_GREATER_THAN_0": "DATA_FLOW_EXECUTION.NUMBER_OF_RECORDS_GREATER_THAN_0",
    "DATA_FLOW_EXECUTION.INPUT_COLUMN_MAPPINGS_REQUIRED": "DATA_FLOW_EXECUTION.INPUT_COLUMN_MAPPINGS_REQUIRED",
    "DATA_FLOW_EXECUTION.KUBE_FLOW_STATUS_INVALID": "DATA_FLOW_EXECUTION.KUBE_FLOW_STATUS_INVALID",
    "DATA_FLOW_EXECUTION.BLOCKS_REQUIRED": "DATA_FLOW_EXECUTION.BLOCKS_REQUIRED",
    "DATA_FLOW_EXECUTION.BLOCK_NOT_FOUND": "DATA_FLOW_EXECUTION.BLOCK_NOT_FOUND",
    "KUBE_FLOW_JOB.STOP_DATA_FLOW_EXECUTION_ERROR": "KUBE_FLOW_JOB.STOP_DATA_FLOW_EXECUTION_ERROR",
    "DATASET.FEATURE_NOT_FOUND": "DATASET.FEATURE_NOT_FOUND",
    "DATASET.TARGET_COLUMN_NOT_FOUND": "DATASET.TARGET_COLUMN_NOT_FOUND",
    "DATA_FLOW_EXECUTION.BLOCK_LOGS_OBJECT_NOT_FOUND": "DATA_FLOW_EXECUTION.BLOCK_LOGS_OBJECT_NOT_FOUND",
    "DATA_FLOW_EXECUTION.BLOCK_LOGS_BUCKET_NOT_EXIST": "DATA_FLOW_EXECUTION.BLOCK_LOGS_BUCKET_NOT_EXIST",
    "MLS_DATA_PROCESSING.EXECUTION_ERROR": "MLS_DATA_PROCESSING.EXECUTION_ERROR",
    "KUBE_FLOW_PIPELINE.EXECUTION_ERROR": "KUBE_FLOW_PIPELINE.EXECUTION_ERROR",

    "DATA_FLOW_TEMPLATE_VERSION.ORIGINAL_VERSION_INVALID": "DATA_FLOW_TEMPLATE_VERSION.ORIGINAL_VERSION_INVALID",
    "DATA_FLOW_TEMPLATE_VERSION.NAME_INVALID": "DATA_FLOW_TEMPLATE_VERSION.NAME_INVALID",
    "DATA_FLOW_TEMPLATE_VERSION.IS_USED_TRAINING": "DATA_FLOW_TEMPLATE_VERSION.IS_USED_TRAINING",
    "DATA_FLOW_TEMPLATE_VERSION.IS_USED_TESTING": "DATA_FLOW_TEMPLATE_VERSION.IS_USED_TESTING",
    "FUNCTION_CONFIG.NOT_FOUND": "FUNCTION_CONFIG.NOT_FOUND",
    "DATA_FLOW_TEMPLATE_VERSION.MISSING_FUNCTION_CONFIG": "DATA_FLOW_TEMPLATE_VERSION.MISSING_FUNCTION_CONFIG",
    "DATA_FLOW_TEMPLATE_VERSION.GRAPH_VECTORS_INVALID": "DATA_FLOW_TEMPLATE_VERSION.GRAPH_VECTORS_INVALID",
    "DATA_FLOW_TEMPLATE.GRAPH_VECTORS_HAVE_CYCLE": "DATA_FLOW_TEMPLATE.GRAPH_VECTORS_HAVE_CYCLE",
    "DATA_FLOW_TEMPLATE_VERSION.REQUIRED_FUNCTION_CONFIG_NEED_VALUE": "DATA_FLOW_TEMPLATE_VERSION.REQUIRED_FUNCTION_CONFIG_NEED_VALUE",
    "DATA_FLOW_TEMPLATE.GRAPH_NODES_NOT_IN_VECTORS": "DATA_FLOW_TEMPLATE.GRAPH_NODES_NOT_IN_VECTORS",
    "DATA_FLOW_TEMPLATE_VERSION.NOT_FOUND": "DATA_FLOW_TEMPLATE_VERSION.NOT_FOUND",
    "DATASET_KUBE_FLOW_STATUS_NOT_SUCCESSFUL": "DATASET_KUBE_FLOW_STATUS_NOT_SUCCESSFUL",
    "RAW_DATA.DUPLICATE_NAME": "RAW_DATA.DUPLICATE_NAME",
    "TRAINING.DELETE_BULK_DATA_MODELS_ERROR": "TRAINING.DELETE_BULK_DATA_MODELS_ERROR",
    "TRAINING.DELETE_BULK_RAWDATA_ERROR": "TRAINING.DELETE_BULK_RAWDATA_ERROR",
    "RAW_DATA.QUERY_PREVIEW_CONNECTION_STRING_INVALID": "RAW_DATA.QUERY_PREVIEW_CONNECTION_STRING_INVALID",
    "RAW_DATA.QUERY_PREVIEW_NO_COLUMN": "RAW_DATA.QUERY_PREVIEW_NO_COLUMN",
    "RAW_DATA.QUERY_PREVIEW_SQL_EXCEPTION": "RAW_DATA.QUERY_PREVIEW_SQL_EXCEPTION",
    "RAW_DATA.QUERY_PREVIEW_NO_DATA": "RAW_DATA.QUERY_PREVIEW_NO_DATA",
    "RAW_DATA_SOURCE.NOT_FOUND": "RAW_DATA_SOURCE.NOT_FOUND",
    "DATASET.DUPLICATE_NAME": "DATASET.DUPLICATE_NAME",
    "TRAINING.DELETE_BULK_DATASETS_ERROR": "TRAINING.DELETE_BULK_DATASETS_ERROR",
    "TRAINING_DUPLICATE_NAME": "TRAINING_DUPLICATE_NAME",
    "TRAINING_NAME_REQUIRED": "TRAINING_NAME_REQUIRED",
    "MODEL_NAME_REQUIRED": "MODEL_NAME_REQUIRED",
    "MODEL_DEPLOYMENT_STATUS_SUCCSESFUL": "MODEL_DEPLOYMENT_STATUS_SUCCSESFUL",
    "ALGORITHM_NOT_FOUND": "ALGORITHM_NOT_FOUND",
    "ALGORITHM_ID_REQUIRED": "ALGORITHM_ID_REQUIRED",
    "ALGORITHM_PARAM_SCHEMA_NOT_FOUND": "ALGORITHM_PARAM_SCHEMA_NOT_FOUND",
    "ALGORITHM_PARAM_SCHEMA_VALUE_REQUIRED": "ALGORITHM_PARAM_SCHEMA_VALUE_REQUIRED",
    "TRAINING_JOB_KUBE_FLOW_STATUS_INVALID": "TRAINING_JOB_KUBE_FLOW_STATUS_INVALID",
    "DATASET_JOB_KUBE_FLOW_STATUS_NOT_SUCCESSFUL": "DATASET_JOB_KUBE_FLOW_STATUS_NOT_SUCCESSFUL",
    "DATASET.ID_REQUIRED": "DATASET.ID_REQUIRED",
    "MODEL.IS_DEPLOYED": "MODEL.IS_DEPLOYED",
    "MODEL_DEPLOYMENT.NOT_FOUND": "MODEL_DEPLOYMENT.NOT_FOUND",
    "MODEL.UNSTOPABLE_STATUS": "MODEL.UNSTOPABLE_STATUS",
    "MODEL.UNDEPLOYABLE_STATUS": "MODEL.UNDEPLOYABLE_STATUS",
    "MODEL.MODEL.STOP.JOB_IS_STARTED": "MODEL.MODEL.STOP.JOB_IS_STARTED",
    "MODEL.STOP_DEPLOY_ERROR": "MODEL.STOP_DEPLOY_ERROR",
    "MODEL_FRAMEWORK.NOT_FOUND": "MODEL_FRAMEWORK.NOT_FOUND",
    "MODEL.STORAGE.UPLOAD.FAILED": "MODEL.STORAGE.UPLOAD.FAILED",
    "MODEL.MODEL_UPLOAD.URL.INVALID": "MODEL.MODEL_UPLOAD.URL.INVALID",
    "RELEARNING_MODEL_DEPLOYMENT.NOT_FOUND": "RELEARNING_MODEL_DEPLOYMENT.NOT_FOUND",
    "TRAINING.UNCLONEABLE_STATUS": "TRAINING.UNCLONEABLE_STATUS",
    "DATA_FLOW_EXECUTION.PREVIEW_ERROR": "DATA_FLOW_EXECUTION.PREVIEW_ERROR",
    "DATA_FLOW_EXECUTION_NOT_READY_TO_PREVIEW": "DATA_FLOW_EXECUTION_NOT_READY_TO_PREVIEW",
    "ONLINE_ANALYTIC.DUPLICATE_NAME": "ONLINE_ANALYTIC.DUPLICATE_NAME",
    "TRAINING.IMPORTANCE_SCORE_OUTPUT_RESULT_EMPTY": "TRAINING.IMPORTANCE_SCORE_OUTPUT_RESULT_EMPTY",
    "TRAINING.ACTUAL_PREDICT_OUTPUT_RESULT_EMPTY": "TRAINING.ACTUAL_PREDICT_OUTPUT_RESULT_EMPTY",
    "MODEL_TARGET_COLUMN.DUPLICATE": "MODEL_TARGET_COLUMN.DUPLICATE",
    "MODEL_FEATURE.DUPLICATE": "MODEL_FEATURE.DUPLICATE"
}

export const PREDICT = {
    MESSAGE_BE: {
        PREDICT_FAILED: 'MODEL.MODEL.PREDICT.PREDICT_FAILED',
    },
    MESSAGE_DISPLAY: {
        PREDICT_FAILED: 'PREDICT.PREDICT_FAILED',
    },
}
