import React from 'react';
import { makeAutoObservable } from 'mobx';
import { clearPersistedStore } from 'mobx-persist-store';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
declare var window: any;

export class SignalRStore {
    private URL_NOTIFICATIONS: string = window._env.REACT_APP_URL_NOTIFICATIONS;
    private hub: HubConnection;
    isConnected: boolean;

    constructor() {
        makeAutoObservable(this);
        this.isConnected = false;
        this.hub = this.createHub();
    }

    async clearPersistedData(): Promise<void> {
        await clearPersistedStore(this);
    }

    private createHub = () => {
        const newHub = new HubConnectionBuilder()
            .withUrl(this.URL_NOTIFICATIONS)
            .configureLogging(LogLevel.Information)
            .withAutomaticReconnect([0, 2000, 4000])
            .build();

        newHub.onclose(async () => {
            this.setIsConnected(false);
        });

        newHub.onreconnecting = ((error: any) => {
            this.setIsConnected(false);

        });

        newHub.onreconnected(() => {
            // temporary solution to trigger all useEffect when connection is disconected then reconnect
            this.setIsConnected(false);
            setTimeout(() => {
                this.setIsConnected(true);
            }, 1000);
        });

        return newHub;
    }

    private setIsConnected = (value: boolean) => this.isConnected = value;

    connect = async () => {
        if (this.hub.state !== HubConnectionState.Disconnected) return;
        await this.hub.start()
            .then(() => {
                this.setIsConnected(true);
            })
            .catch((_error: any) => {
                this.setIsConnected(false);
                // withAutomaticReconnect won't configure the HubConnection to retry initial start failures,
                // so start failures need to be handled manually
                setTimeout(this.connect, 2000);
            });
    }

    disconnect = async () => {
        if (this.hub.state === HubConnectionState.Disconnected) return;
        await this.hub.stop()
            .catch((error: any) => {
            });
        this.setIsConnected(false);
    }

    registerMessage(registerMethod: string, data: string) {
        if (this.hub.state !== HubConnectionState.Connected) return;
        this.hub.send(registerMethod, data);
    }

    unRegisterMessage(unRegisterMethod: string, data: string) {
        if (this.hub.state !== HubConnectionState.Connected) return;
        this.hub.send(unRegisterMethod, data);
    }

    onReceiveMessage = (receiveMethod: string, callback: (response: any) => any) => {
        if (this.hub.state !== HubConnectionState.Connected) return;
        this.hub.on(receiveMethod, (message: any, _body: any) => {
            callback(message);
        });
    }

    offReceiveMessage = (receiveMethod: string) => {
        if (this.hub.state !== HubConnectionState.Connected) return;
        this.hub.off(receiveMethod);
    }

    registerAndReceiveMessage = (registerMethod: string, registerData: string, receiveMethod: string, callback: (response: any) => any) => {
        if (this.hub.state !== HubConnectionState.Connected) return;
        this.hub.send(registerMethod, registerData);

        this.hub.on(receiveMethod, (message: any, _body: any) => {
            callback(message);
        });
    }

    unRegisterAndReceiveMessage = (unRegisterMethod: string, unRegisterData: string, receiveMethod: string) => {
        if (this.hub.state !== HubConnectionState.Connected) return;
        this.hub.send(unRegisterMethod, unRegisterData);

        this.hub.off(receiveMethod);
    }

    onReceiveMessageMutipleActions = (registerMethod: string, registerData: string[], receiveMethod: string, callback: (response: any) => any) => {
        if (registerMethod && registerData.length) {
            registerData.forEach((value: any) => {
                setTimeout(() => {
                    this.hub.send(registerMethod, value);
                }, 100);
            });
        }

        if (receiveMethod) {
            this.hub.on(receiveMethod, (message: any, body: any) => {
                callback(message);
            });
        }
    }
}

export const signalRStore = new SignalRStore();
export const storeContext = React.createContext(signalRStore);
export const useSignalRStore = (): SignalRStore => React.useContext(storeContext);
