import { User } from 'oidc-client';
import axios from 'axios';
import { COMMON_CONSTANT } from '../../constants';
import { ENV_CONFIG } from '../../config';
// import { COMMON_CONSTANT } from 'src/constants';
// import { ENV_CONFIG } from 'src/config';

const tokenAxios = axios.create({
    baseURL: ENV_CONFIG.REACT_APP_IDP_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});

const getUser = () => {
    const oidcStorage = localStorage.getItem(
        `oidc.user:${ENV_CONFIG.REACT_APP_IDP_URL}:${ENV_CONFIG.REACT_APP_SA_CLIENT_ID}`
    );
    if (!oidcStorage) {
        return null;
    }
    return User.fromStorageString(oidcStorage);
};

const authService = {
    getAccessToken: async (tenantId: string, subscriptionId: string, projectId = '') => {
        try {
            const user = getUser();
            const token = user?.access_token || '';
            let params = new URLSearchParams();
            params.append('grant_type', 'user_switch_tenant');
            params.append('client_id', ENV_CONFIG.REACT_APP_SA_CLIENT_ID);
            params.append('tenantId', tenantId);
            params.append('subscriptionId', subscriptionId);
            params.append('applicationId', ENV_CONFIG.REACT_APPLICATION_ID);
            if (projectId && projectId !== COMMON_CONSTANT.EMPTY_GUID) {
                params.append('projectId', projectId);
            }
            params.append('token', token);
            const response = await tokenAxios.post('/connect/token', params);
            return response.data?.access_token as string;
        } catch (error) {
            throw error;
        }
    },
};

export default authService;
