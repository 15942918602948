import { useEffect, useState } from 'react';
import { fileService } from 'services/storage';
import { getFileNameFromUrl } from '_core/helpers/Utils';
import { FILE_PREVIEW_NEED_CONVERT, HTTP_STATUS } from 'config';
import { ProgressSpinner } from 'primereact/progressspinner';

interface Props {
    filePath: string;
}

const PreviewFileContent = (props: Props) => {
    const { filePath = '' } = props;
    const [filePreview, setFilePreview] = useState<File | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!filePath) return;
        handlePreviewFile();
    }, [filePath]);

    const handlePreviewFile = async (): Promise<void> => {
        setLoading(true);
        try {
            const urlFile: string = await fileService.getDownloadLink(filePath);
            const file = await getFileFromUrl(urlFile, getFileNameFromUrl(filePath));
            const fileExtension = getFileExtension(getFileNameFromUrl(filePath)).toLowerCase();
            if (!fileExtension) {
                setLoading(false);
                return;
            }

            if (FILE_PREVIEW_NEED_CONVERT.includes(fileExtension)) {
                const formData = new FormData();
                formData.append('file', file);
                const filePdf = await fileService.convertToPdf(formData);
                setFilePreview(filePdf);
            } else {
                setFilePreview(file);
            }
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const getFileExtension = (name: string = ''): string => {
        const arr = name.split('.');
        if (!arr.length) return '';
        return arr[arr.length - 1];
    };

    const getFileFromUrl = async (url: string, name: string): Promise<File> => {
        const response = await fetch(url);
        if (response?.status === HTTP_STATUS.NOT_FOUND) throw new Error();

        const data = await response.blob();
        return new File([data], name, {
            type: data.type
        });
    };

    const renderContent = (): JSX.Element => {
        if (loading) return <ProgressSpinner />;
        if (filePreview) return <iframe src={URL.createObjectURL(filePreview)} className={'file-pdf w-100 h-100'} />;
        return <></>;
    };
    return <>{renderContent()}</>;
};

export default PreviewFileContent;
