import { BellOutlined, DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { ReactComponent as CubeLoading } from '_core/icons/CubeLoading.svg';
import { ReactComponent as EditVector } from '_core/icons/EditVector.svg';
import { ReactComponent as GoAppPortal } from '_core/icons/GoAppPortal.svg';
import { ReactComponent as Logo } from '_core/icons/LogoApp.svg';
import { ReactComponent as LogoutVector } from '_core/icons/LogoutVector.svg';
import { ReactComponent as MenuBar } from '_core/icons/MenuBar.svg';
import { ReactComponent as SwitchProject } from '_core/icons/SwitchTanents.svg';
import { Badge, Button, Dropdown, Menu, notification } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useNavigate, generatePath, useMatch, useLocation, redirect } from 'react-router-dom';
import { caculateTimeWithNow, cleanLocalCache, downloadFileByURL } from '_core/helpers/Utils';
import notify from '_core/helpers/notify';
import { APP_CONFIG, ENV_CONFIG, SIGNALR_METHODS, SIGNALR_TYPES } from '../../config';
import { APP_ROUTES, NOTIFICATION_STATUS, NOTIFICATION_TYPE } from '../../constants';
import { ILanguage } from '../../models/ILanguage';
import userInfoService from '../../services/Authen/userInfoService';
import { useAuthenStore } from '../../store/Auth/AuthStore';
import { useNotificationStore } from '../../store/Shared/NotificationStore';
import { useSignalRStore } from '../../store/Shared/signalRStore';
import { BaseColorSeprateHeader, BaseSpanText } from '../Themes/baseStyles';
import { StyledHeader } from './AdminLayout.styles';
import './PrivateHeader.scss';
import localizationService from 'services/Localization/localizationService';
import { useRedirect } from 'hooks/useRedirect';

const defaultLanguage = {
    code: 'en-us',
    name: 'English',
    nativeName: 'English',
};

const getFlagUrl = (languageCode: string) => {
    return `${ENV_CONFIG.CDN_URL}/languages/_flags/${languageCode.toLowerCase()}.png`;
};

const PrivateHeader = observer((props: any) => {
    const { t, i18n } = useTranslation();
    const auth = useAuth();
    const navigate = useNavigate();
    const authStore = useAuthenStore();
    const signalRStore = useSignalRStore();
    const notificationStore = useNotificationStore();
    const [languageList, setLanguageList] = useState<ILanguage[]>([defaultLanguage]);
    const [selectedLanguage, setSelectedLanguage] = useState<ILanguage>(defaultLanguage);
    const [showSwitchProjectMenu, setShowSwitchProjectMenu] = useState<boolean>(false);
    const { onVisible, isPc, isTablet, visible, collapse, onCollapse, hideSensitiveInfoWhenNotConfirmConsent } = props;
    const { pathname } = useLocation();
    const isProjectListPage = useMatch(APP_ROUTES.PROJECT_LIST);

    // for export
    useEffect(() => {
        signalRStore.onReceiveMessage(SIGNALR_METHODS.RECEIVE_EXPORT_MESSAGE, (response: any) => {
            const payload = response && response.payload ? JSON.parse(response.payload) : null;
            if (payload && payload.notifyType === NOTIFICATION_TYPE.EXPORT) {
                handleExport(payload);
                handleNotification(payload);
            }
        });
        return () => {
            signalRStore.offReceiveMessage(SIGNALR_METHODS.RECEIVE_EXPORT_MESSAGE);
        };
    }, [signalRStore.isConnected]);

    // for import
    useEffect(() => {
        if (authStore.user && authStore.user.upn) {
            signalRStore.onReceiveMessage(SIGNALR_METHODS.RECEIVE_IMPORT_MESSAGE, (response: any) => {
                const payload = response && response.payload ? JSON.parse(response.payload) : null;
                if (payload.notifyType === NOTIFICATION_TYPE.IMPORT) {
                    handleImport(response.type, payload);
                    handleNotification(payload);
                }
            });
        }
        return () => {
            signalRStore.offReceiveMessage(SIGNALR_METHODS.RECEIVE_IMPORT_MESSAGE);
        };
    }, [signalRStore.isConnected]);

    useEffect(() => {
        // TO DO: analytic does not support multi-language
        if (!authStore.user.upn) return;
        (async () => {
            const response = await localizationService.getLanguages();
            setLanguageList(response.data);
        })();
    }, [authStore.user.upn]);

    useEffect(() => {
        if (!authStore.user.upn) return;

        const { preferredLanguage } = authStore.user;
        const language = languageList.find((lang) => lang?.code === preferredLanguage?.code);
        if (!language) return;

        setSelectedLanguage(language);
        i18n.changeLanguage(language.code);
    }, [authStore.user.preferredLanguage, languageList]);

    useEffect(() => {
        setShowSwitchProjectMenu(!isProjectListPage);
    }, [authStore.selectedProject, pathname]);

    const handleNotification = (payload: any) => {
        if (payload.objectName) {
            let payloadTemp = _.cloneDeep(payload);
            payloadTemp.CurrentTimestamp = payload.createdUtc ? payload.createdUtc : '';
            notificationStore.addNotifications(payloadTemp);
        }
    };

    const handleExport = (payload: any) => {
        if (payload.status === NOTIFICATION_STATUS.START) {
            notify.loading(payload.description);
            notificationStore.setProcessing(true);
        } else {
            notification.close(payload.activityId);
            notificationStore.setProcessing(false);
            if (payload.status === NOTIFICATION_STATUS.SUCCESS && payload.url) {
                downloadFileByURL(`${APP_CONFIG.DOMAIN_URL}${payload.url}`, true);
            } else if (payload.status === NOTIFICATION_STATUS.FAIL) {
                notificationStore.showError(payload.description);
            } else if (payload.status === NOTIFICATION_STATUS.PARTIAL) {
                notificationStore.showWarning(payload.description);
            }
        }
    };

    const handleImport = (type: any, payload: any) => {
        if (payload.status === NOTIFICATION_STATUS.START) {
            // use this to control destroy
            notify.loading(payload.description);
            notificationStore.setProcessing(true);
        } else {
            // hide in-progress by ActivityId
            notification.close(payload.activityId);

            notificationStore.setProcessing(false);

            if (payload.status === NOTIFICATION_STATUS.SUCCESS) {
                switch (type) {
                    case SIGNALR_TYPES.REPORT: {
                        break;
                    }
                    default:
                        break;
                }

                notificationStore.showSuccess(payload.description);
            } else if (payload.status === NOTIFICATION_STATUS.PARTIAL) {
                notificationStore.showWarning(payload.description);
            } else if (payload.status === NOTIFICATION_STATUS.FAIL) {
                notificationStore.showError(payload.description);
            }
        }
    };

    const handleClickNotification = (payload: any) => {
        if (payload && payload.activityId) {
            // TO DO: activity log not done
            // redirect(stringFormat(APP_ROUTES.DETAIL_ACTIVITY_LOG, payload.activityId));
        }
    };

    const handleLogout = () => {
        signalRStore.disconnect();
        (async () => {
            cleanLocalCache();
            await auth.signoutRedirect({
                state: {
                    redirect: window.location.href.replace(window.location.origin, ''),
                },
            });
        })();
    };

    const onClickVisible = () => {
        let isPcTemp = !visible;
        onVisible(isPcTemp);
    };

    const onClickCollapse = () => {
        let isCollapseTemp = !collapse;
        onCollapse(isCollapseTemp);
    };

    const handleViewAllNotify = () => {
        // TO DO: activity log not done
        // redirect(APP_ROUTES.ACTIVITY_LOG);
    };

    const handleViewEditPersonal = () => {
        if (authStore.user.appEndpoint) {
            window.open(
                `${authStore.user.appEndpoint}?subscriptionId=${authStore.user.currentSubscriptionId}&directProfile=true`,
                '_blank'
            );
        }
    };

    const handleSwitchProject = () => {
        const { currentSubscriptionId, subscriptions } = authStore.user;
        const currentSubscription = subscriptions.find((sub) => sub.id === currentSubscriptionId) || subscriptions[0];
        const path = generatePath(APP_ROUTES.PROJECT_LIST, {
            tenantId: authStore.user.currentTenantId,
            subscriptionId: currentSubscription.id,
        });
        navigate(path, { replace: true });
    };

    const handleGoAppPortal = () => {
        signalRStore.disconnect();
        if (authStore.user.appEndpoint) {
            const { appEndpoint, currentTenantId, currentSubscriptionId } = authStore.user;
            window.location.href = `${appEndpoint}/${currentTenantId}/${currentSubscriptionId}`;
        }
    };

    const handleChangeLanguage = (languageCode: string) => {
        if (selectedLanguage.code === languageCode) return;
        const language = languageList.find((lang) => lang.code === languageCode);
        if (!language) return;

        const user = authStore.user;
        user.preferredLanguage = language;
        authStore.setUser(user);

        userInfoService.updateUserLanguage(language.code);
    };

    const goToDocumentPage = () => {
        if (hideSensitiveInfoWhenNotConfirmConsent) return;
        window.open(ENV_CONFIG.DOCUMENT_URL, '_blank');
    };

    const menuProfile = (
        <Menu theme={'light'} className={'profile-menu'}>
            {!hideSensitiveInfoWhenNotConfirmConsent && (
                <>
                    <Menu.Item key="profile-key-0" icon={<GoAppPortal />} onClick={handleGoAppPortal}>
                        {t('COMMON.HEADER.MENU.APP_PORTAL')}
                    </Menu.Item>
                    {showSwitchProjectMenu && (
                        <Menu.Item key="profile-key-1" icon={<SwitchProject />} onClick={handleSwitchProject}>
                            {t('COMMON.HEADER.MENU.SWITCH_PROJECT')}
                        </Menu.Item>
                    )}
                    {authStore.user.isLocalUser && (
                        <Menu.Item key="profile-key-2" icon={<EditVector />} onClick={handleViewEditPersonal}>
                            {t('COMMON.HEADER.MENU.PROFILE')}
                        </Menu.Item>
                    )}
                </>
            )}
            <Menu.Item key="profile-key-3" icon={<LogoutVector />} onClick={handleLogout}>
                {t('COMMON.BUTTON.LOGOUT')}
            </Menu.Item>
        </Menu>
    );

    const menuLanguage = (
        <Menu theme={'light'} className={'profile-menu'}>
            {languageList.map((lang, idx) => {
                return (
                    <Menu.Item key={`language-key-${idx}`} onClick={() => handleChangeLanguage(lang.code)}>
                        <img src={getFlagUrl(lang.code)} className="header-list-lang-icon" alt={''} />
                        {`${lang.nativeName} (${lang.name})`}
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    const menuNotifications = () => {
        return (
            <div className="header-notifications">
                <div
                    className={'header-notification-title'}
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                >
                    {' '}
                    {t('COMMON.HEADER.MENU.NOTIFICATION')}
                </div>
                <div className="header-notification-body">
                    <div style={{ paddingRight: 20 }}>
                        {notificationStore.notifications.map((notiItem: any, i: any) => {
                            return (
                                <a
                                    className="header-notification-link-item"
                                    href=":;javascript"
                                    key={`notification-item-_${i}`}
                                    onClick={() => handleClickNotification(notiItem)}
                                >
                                    <span className={'notification-content-text'}>
                                        <span className="notification-content-object">{notiItem?.objectName}:</span> {notiItem?.description}
                                    </span>
                                    <span className={'notification-content-time'}>{caculateTimeWithNow(notiItem?.CreatedUtc)}</span>
                                </a>
                            );
                        })}
                    </div>
                </div>
                <div className="header-notification-footer">
                    <Button type="link" block onClick={handleViewAllNotify}>
                        {t('COMMON.HEADER.MENU.VIEW_ALL_NOTIFICATION')}
                    </Button>
                </div>
            </div>
        );
    };

    const notificationDropDown = () => {
        const { subscriptionId, tenantId, applicationId } = authStore.selectedProject;
        if (!subscriptionId || !tenantId || !applicationId) return <></>;

        return (
            <Dropdown overlay={menuNotifications}>
                <a className="profile-user-link" onClick={(e) => e.preventDefault()}>
                    <div className={'profile-user-menu'}>
                        <div className={'profile-info-full profile-notification-menu notification-menu'}>
                            <Badge count={notificationStore.notifications.length} className={'notification-ant-badge-count'}>
                                <Button className={'button-bag'} shape="circle" icon={<BellOutlined />} />
                            </Badge>
                            {notificationStore.processing && (
                                <CubeLoading
                                    style={{
                                        display: 'block',
                                        height: 16,
                                        position: 'absolute',
                                        margin: '-11px 0px 0 8px',
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </a>
            </Dropdown>
        );
    };

    return (
        <StyledHeader className="header">
            <div className={'logo-text'}>
                <>
                    {isPc || isTablet ? (
                        <Button
                            shape="circle"
                            icon={<MenuBar />}
                            size="large"
                            style={{
                                background: 'transparent',
                                border: 'none',
                                color: '#FFF',
                                fontSize: '24px',
                                lineHeight: '24px',
                                padding: 0,
                            }}
                            onClick={onClickCollapse}
                        />
                    ) : (
                        <Button
                            shape="circle"
                            icon={<MenuBar />}
                            size="large"
                            style={{
                                background: 'transparent',
                                border: 'none',
                                color: '#FFF',
                            }}
                            onClick={onClickVisible}
                        />
                    )}
                    <div className="header-logo">
                        <Logo className="header-logo-icon" />
                        <BaseSpanText className="header-logo-title">
                            {authStore.applicationInfo.name || 'Data Analytic'}
                        </BaseSpanText>
                    </div>
                </>
            </div>
            <div style={{ flex: 'auto' }} />

            <div className={'header-menu-right'}>
                <div className="help-icon">
                    <QuestionCircleOutlined onClick={goToDocumentPage} />
                </div>

                {notificationDropDown()}

                <Dropdown overlay={menuLanguage}>
                    <a className="profile-user-link" onClick={(e) => e.preventDefault()}>
                        <div className={'profile-user-menu'}>
                            <div className={'profile-info-full language-menu'}>
                                <BaseSpanText className={'profile-username'}>
                                    <img src={getFlagUrl(selectedLanguage.code)} className="header-lang-icon" /> <DownOutlined />
                                </BaseSpanText>
                            </div>
                        </div>
                    </a>
                </Dropdown>

                <BaseColorSeprateHeader className={'devider-mobile'} />

                <Dropdown overlay={menuProfile}>
                    <a className="profile-user-link" onClick={(e) => e.preventDefault()}>
                        <div className={'profile-user-menu'}>
                            <img src={authStore.user.avatar || '/img/avatars/avatar.png'} className={'profile-avatar'} />
                            {isPc ? (
                                <div className={'profile-info-full profile-details-menu'}>
                                    <BaseSpanText className={'profile-username'}>
                                        {authStore?.user?.firstName === 'External User'
                                            ? t('COMMON.HEADER.EXTERNAL_USER')
                                            : `${authStore.user.firstName || ''} ${authStore.user.lastName || ''}`}
                                        <br />
                                        <span>{authStore.user.upn}</span>
                                    </BaseSpanText>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </a>
                </Dropdown>
            </div>
        </StyledHeader>
    );
});

export default PrivateHeader;
