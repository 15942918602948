import authoriedConsentRequest from 'services/authoriedConsentRequest';

const endpoint = '/sta/files';

export class FileService {
    getDownloadLink = async (filePath: string, durationInMinute: number = 5) => {
        const apiResponse = await authoriedConsentRequest.post(`${endpoint}/link`, {
            filePath,
            durationInMinute,
        });
        return apiResponse.data;
    };

    convertToPdf = async (payload: FormData) => {
        const token = await this.generateToken();
        if (!token) return null;

        const response: any = await authoriedConsentRequest.post(`${endpoint}/convert?token=${token}`, payload, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        if (!response.data) return null;

        return new File([response.data], 'filePdf.pdf', {
            type: response.data.type,
        });
    };

    generateToken = async () => {
        const apiResponse = await authoriedConsentRequest.post(`${endpoint}/token`);
        return apiResponse.data.token;
    };
}