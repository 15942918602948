import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend'; // i18next-chained-backend should be placed here
import resourcesToBackend from 'i18next-resources-to-backend';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { ENV_CONFIG } from '../config';
import english from './languages/en-us.json';
import chinese from './languages/zh-cn.json';
import japanese from './languages/ja-jp.json';
import korean from './languages/ko-kr.json';

i18n.use(ChainedBackend)
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        load: 'currentOnly',
        lowerCaseLng: true,
        fallbackLng: ['en-US'],
        backend: {
            backends: [
                HttpBackend, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
                resourcesToBackend({
                    'en-us': {
                        translation: english
                    },
                    'ja-jp': {
                        translation: japanese
                    },
                    'zh-cn': {
                        translation: chinese
                    },
                    'ko-kr': {
                        translation: korean
                    }
                })
            ],
            backendOptions: [
                {
                    loadPath: `${ENV_CONFIG.LANGUAGE_URL}/{{lng}}.json`,
                    crossDomain: true
                }
            ]
        },
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        react: {
            useSuspense: false
        }
    });

export default i18n;
