import { ConfirmConsentRequest } from 'components/ConsentDialog/props';
import authoriedConsentRequest from 'services/authoriedConsentRequest';

const endpoint = '/mst/consents';
class ConsentService  {

    get = async () => {
        const { data } = await authoriedConsentRequest.get(`${endpoint}`);
        return data;
    }

    async getDetailConsentHistory(upn: string) {
        const apiResponse = await authoriedConsentRequest.post(`${endpoint}/search/details`, { upn }) ;
        return apiResponse;
    }

    getConsentByIdIncludingDeleted = async (id: string) => {
        const { data } = await authoriedConsentRequest.get(`${endpoint}/${id}`, {
            params: { isObsoleted: true }
        });
        return data;
    }

    async confirmConsent(data: ConfirmConsentRequest) {
        const apiResponse = await authoriedConsentRequest.post(`${endpoint}/confirm`, data) ;
        return apiResponse;
    }
}

export default ConsentService;
