import { Link, Outlet, useLocation } from 'react-router-dom';
import { useRedirect } from 'hooks/useRedirect';
import { Layout, Menu } from 'antd';
import { ENV_CONFIG } from 'config';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_ROUTES, APP_STORAGE, COMMON_CONSTANT } from '../../constants';
import { IMenuItem } from '../../models';
import AppService from '../../pages/Home/ProjectManagement/AppService/AppService';
import SubscribeProjectListChange from '../../pages/Notification/SubscribeProjectListChange';
import SubscribeUserChange from '../../pages/Notification/SubscribeUserChange';
import { useAuthenStore } from '../../store/Auth/AuthStore';
import { useSignalRStore } from '../../store/Shared/signalRStore';
import { GetWidthWindow } from '_core/components/GetWidthWindow/GetWidthWindow';
import { getFirstChar } from '_core/helpers/Utils';
import { getMenuProjectItems } from '_core/MenuItems';
import { BaseBlackText } from '../Themes/baseStyles';
import { StyledLayout, StyledSider } from './AdminLayout.styles';
import { PrivateFooter } from './PrivateFooter';
import PrivateHeader from './PrivateHeader';
import InitConsentDialog from 'components/InitConsentDialog';

const { SubMenu } = Menu;
const { Content } = Layout;

const AdminLayout: React.FC = observer(() => {
    const signalRStore = useSignalRStore();
    const ref = useRef(null);
    const { pathname } = useLocation();
    const { redirect, generateRouter } = useRedirect();
    const { t } = useTranslation();

    const [menuItems, setMenuItems] = useState<IMenuItem[]>([]);
    const [widthDiv] = GetWidthWindow(ref);
    const [device, setDevice] = useState<any>('is-desktop');
    const [isPC, setIsPC] = useState<any>(true);
    const [isTablet, setIsTablet] = useState<any>(false);
    const [_isMobile, setIsMobile] = useState<any>(false);
    const [collapsed, setCollapsed] = useState<any>(false);
    const [visible, setVisible] = useState<any>(false);
    const [parent, setParent] = useState<any>('');
    const [current, setCurrent] = useState<any>([]);
    const [selectedMenu, setSelectedMenu] = useState<string>('');
    const authStore = useAuthenStore();

    const hideSensitiveInfoWhenNotConfirmConsent: boolean = Boolean(authStore.visibleConsent || !authStore.user.id);

    useEffect(() => {
        if (!authStore.switchSubscription || pathname === APP_ROUTES.DEFAULT) return;
        redirect(APP_ROUTES.DEFAULT);
    }, [authStore.switchSubscription]);

    useEffect(() => {
        if (!authStore.user.upn) return;
        signalRStore.connect();

        return () => {
            signalRStore.disconnect();
        };
    }, [authStore.user.upn]);

    useEffect(() => {
        if (!pathname) return;

        const { upn, currentSubscriptionId, currentTenantId, subscriptions } = authStore.user;
        let nullArray = _.cloneDeep([]);
        setParent([...nullArray]);
        setCurrent([...nullArray]);
        if (!upn || !subscriptions.length) return;

        const { selectedProject } = authStore;
        if (selectedProject.id === COMMON_CONSTANT.EMPTY_GUID || window.location.pathname === APP_ROUTES.DEFAULT) {
            // home page
            const menuItems = subscriptions.map((val) => {
                return {
                    id: val.id,
                    to: '',
                    icon: '',
                    title: val.name,
                    fontIcon: '',
                    showTextThumb: true,
                    thumbColor: val.color,
                    tenantId: val.tenantId,
                    subscriptionId: val.id,
                    isProjectMenu: false,
                };
            });
            setMenuItems(menuItems);
            setCurrentSubscriptionMenu(currentTenantId, currentSubscriptionId, menuItems);
        } else {
            const menuItems = getMenuProjectItems(selectedProject);
            setMenuItems(menuItems);
            handleSetCurrentMenu(pathname, menuItems);
        }
    }, [pathname, authStore.selectedProject]);

    useEffect(() => {
        if (widthDiv && parseInt(widthDiv, 10) > 1024) {
            setIsPC(true);
            setVisible(false);
            setIsMobile(false);
            setDevice('is-desktop');
        } else if (widthDiv && parseInt(widthDiv, 10) >= 600 && parseInt(widthDiv, 10) <= 1024) {
            setIsTablet(true);
            setIsPC(false);
            setVisible(false);
            setIsMobile(false);
            setCollapsed(true);

            if (widthDiv && parseInt(widthDiv, 10) >= 600 && parseInt(widthDiv, 10) <= 834) {
                setDevice('is-tablet');
            }
        } else if (widthDiv && parseInt(widthDiv, 10) < 600 && (isPC || isTablet)) {
            setIsPC(false);
            setVisible(false);
            setIsTablet(false);
            setIsMobile(true);
            setDevice('is-mobile');
        }

        // update visible
        if (widthDiv && parseInt(widthDiv, 10) < 992 && visible) {
            setVisible(false);
        }
    }, [widthDiv]);

    const setCurrentSubscriptionMenu = (tenantId: string, subscriptionId: string, menuItems: IMenuItem[]) => {
        const keys: any[] = [];
        const menuIndex = menuItems.findIndex(
            (menu) => menu.tenantId === tenantId && menu.subscriptionId === subscriptionId
        );
        keys.push([`no_parent_${menuIndex}`]);
        setSelectedMenu(`no_parent_${menuIndex}`);
        setCurrent([...keys]);
    };

    const handleSetCurrentMenu = (currentPath: any, menuItems: IMenuItem[]) => {
        let keys: any[] = [];
        let isSelectedMenu: boolean = false;
        menuItems.map((menu, index: any) => {
            if (menu.to && currentPath.includes(menu.to)) {
                keys.push([`no_parent_${index}`]);
                setSelectedMenu(`no_parent_${index}`);
                localStorage.setItem(APP_STORAGE.CURRENT_MENU, menu.to);
            } else if (menu.children && menu.children.length) {
                let isInChildren: boolean = false;
                menu.children.map((child, indexJ: any) => {
                    if (currentPath.includes(child.to)) {
                        isInChildren = true;
                        setSelectedMenu(`${indexJ}_${index}`);
                        keys.push(`${indexJ}_${index}`);
                        isSelectedMenu = true;
                        localStorage.setItem(APP_STORAGE.CURRENT_MENU, child.to);
                    }
                });

                // set default open sub-menu
                if (isInChildren) {
                    setParent(`has_parent_${index}`);
                }
            }
        });
        setCurrent([...keys]);

        if (!isSelectedMenu) {
            handleSetCurrentMenuFromLocalStorage(menuItems);
        }
    };

    const handleSetCurrentMenuFromLocalStorage = (menuItems: any) => {
        let keys: any[] = [];
        const currentMenu = localStorage.getItem(APP_STORAGE.CURRENT_MENU);
        const currentPath = currentMenu ?? '';
        menuItems.map((menu: any, index: any) => {
            if (menu.to && currentPath.includes(menu.to)) {
                keys.push([`no_parent_${index}`]);
                setSelectedMenu(`no_parent_${index}`);
            } else if (menu.children && menu.children.length) {
                let isInChildren: boolean = false;
                menu.children.map((child: any, indexJ: any) => {
                    if (currentPath === child.to) {
                        isInChildren = true;
                        setSelectedMenu(`${indexJ}_${index}`);
                        keys.push(`${indexJ}_${index}`);
                    }
                });

                // set default open sub-menu
                if (isInChildren) {
                    setParent(`has_parent_${index}`);
                }
            }
        });
        setCurrent([...keys]);
    };

    const handleClick = (e: any) => {
        if (e.keyPath.length >= 2) {
            setParent(e.keyPath[e.keyPath.length - 1]);
        }
        setCurrent(e.keyPath);
    };

    const handleClickSubscription = async (e: any, id: string, tenantId: string) => {
        if (e.keyPath.length >= 2) {
            setParent(e.keyPath[e.keyPath.length - 1]);
        }
        setCurrent(e.keyPath);

        const { currentSubscriptionId, currentTenantId } = authStore.user;
        if (id === currentSubscriptionId && tenantId === currentTenantId) {
            return;
        } else {
            setCurrentSubscriptionMenu(tenantId, id, menuItems);
            await authStore.updateAccessToken(tenantId, id, '', true);
        }
    };

    const onTitleClick = (e: any) => {
        if (parent === e.key) {
            setParent('');
            return;
        }
        setParent(e.key);
    };

    const onVisible = (e: any) => {
        setVisible(e);
    };

    const onCollapsed = (e: any) => {
        setCollapsed(e);
    };

    const handleShowProjectMenu = (menu: IMenuItem, index: number) => {
        if (!menu || !menu.isProjectMenu) return;
        // TO DO: does not support multi language yet.

        // let isCanParent = isHasAnyPermissionInAnyChild(menu.children);
        // let isCanParentNoChild = false;
        // if (menu && menu.rights) {
        //     isCanParentNoChild = hasPermission(menu.rights);
        // }
        //
        // // Check if all the children of menu don't have permission, we will hide this menu
        // let isChildHasPermission = false;
        // menu.children?.map((child) => {
        //     if (child && child.isProjectMenu) {
        //         if (!isChildHasPermission && !hasPermission(child.rightsNone)) {
        //             isChildHasPermission = hasPermission(child.rights);
        //         }
        //     }
        // });

        // TO DO: ignore permission
        let isCanParent = true;
        let isCanParentNoChild = true;
        let isChildHasPermission = true;
        return menu.children && menu.children.length && isCanParent && isChildHasPermission ? (
            <SubMenu
                key={`has_parent_${index}`}
                className={`${parent === `has_parent_${index}` && collapsed ? 'collapsed-active' : ''}`}
                title={t(menu.title)}
                style={{ ...menu?.styles }}
                onTitleClick={onTitleClick}
                icon={<img className="ant-menu-item-icon" src={menu.icon} style={{ ...menu.iconStyles }} alt="" />}
            >
                {menu.children.map((child: any, indexJ: any) => {
                    // TO DO: ignore permission
                    // let isCan = false;
                    // if (child && child.isProjectMenu) {
                    //     if (!hasPermission(child.rightsNone)) {
                    //         isCan = hasPermission(child.rights);
                    //     }
                    // }
                    let isCan = true;

                    if (!isCan) {
                        return null;
                    }

                    return (
                        <Menu.Item
                            key={`${indexJ}_${index}`}
                            // onClick={handleClick}
                            className={`${selectedMenu === `${indexJ}_${index}` ? 'ant-menu-item-selected' : ''} ${collapsed ? 'collapsed-sub-menu' : ''
                                }`}
                        >
                            <Link to={generateRouter(child.to)} rel="noopener noreferrer">
                                {child?.showTextThumb ? (
                                    <span
                                        className="text-menu-icon-thumb ant-menu-item-icon"
                                        style={{ background: child.thumbColor }}
                                    >
                                        {getFirstChar(t(child.title))}
                                    </span>
                                ) : (
                                    <img
                                        className="ant-menu-item-icon"
                                        src={child.icon}
                                        style={{ ...menu?.iconStyles }}
                                        alt=""
                                    />
                                )}
                                <BaseBlackText>{t(child.title)}</BaseBlackText>
                            </Link>
                        </Menu.Item>
                    );
                })}
            </SubMenu>
        ) : (
            isCanParentNoChild && (
                <Menu.Item
                    key={`no_parent_${index}`}
                    style={{ ...menu?.styles }}
                    className={`${selectedMenu === `no_parent_${index}` ? 'ant-menu-item-selected' : ''} ${parent === `has_parent_${index}` && collapsed ? 'collapsed-active' : ''
                        }`}
                    title={null}
                // onClick={handleClick}
                >
                    <Link to={menu.to === '/' ? menu.to : generateRouter(menu.to)} rel="noopener noreferrer">
                        {menu?.showTextThumb ? (
                            <span
                                className="text-menu-icon-thumb ant-menu-item-icon"
                                style={{ background: menu.thumbColor }}
                            >
                                {getFirstChar(t(menu.title))}
                            </span>
                        ) : (
                            <img
                                className="ant-menu-item-icon ant-menu-item-icon"
                                src={menu.icon}
                                style={{ ...menu?.iconStyles }}
                                alt=""
                            />
                        )}
                        <BaseBlackText>{t(menu.title)}</BaseBlackText>
                    </Link>
                </Menu.Item>
            )
        );
    };

    const handleShowSubscriptionMenu = (menu: IMenuItem, index: number) => {
        if (!menu || menu.isProjectMenu) return;
        return (
            <Menu.Item
                key={`no_parent_${index}`}
                style={{ ...menu?.styles }}
                className={`${selectedMenu === `no_parent_${index}` ? 'ant-menu-item-selected' : ''} ${parent === `has_parent_${index}` && collapsed ? 'collapsed-active' : ''
                    }`}
                title={null}
                onClick={(e: any) => handleClickSubscription(e, menu.id ?? '', menu.tenantId ?? '')}
            >
                <Link to={`${menu.tenantId}/${menu.subscriptionId}/projects`} rel="noopener noreferrer">
                    {menu?.showTextThumb ? (
                        <span
                            className="text-menu-icon-thumb ant-menu-item-icon"
                            style={{ background: menu.thumbColor }}
                        >
                            {getFirstChar(menu.title)}
                        </span>
                    ) : (
                        <img
                            className="ant-menu-item-icon ant-menu-item-icon"
                            src={menu.icon}
                            style={{ ...menu?.iconStyles }}
                            alt=""
                        />
                    )}
                    <BaseBlackText>{t(menu.title)}</BaseBlackText>
                </Link>
            </Menu.Item>
        );
    };

    const renderMenuItems = () => {
        return menuItems.map((menu, index: number) =>
            {
                if (menu && menu.isProjectMenu) {
                    return handleShowProjectMenu(menu, index);
                } else {
                    return handleShowSubscriptionMenu(menu, index);
                }
            }
        );
    };

    const StyledSiderTablet = (
        <StyledSider collapsed={collapsed}>
            {!hideSensitiveInfoWhenNotConfirmConsent && <Menu
                mode="inline"
                className={`main-left-menu ${collapsed ? 'menu-is-collapsed' : 'menu-not-collapsed'}`}
                onClick={handleClick}
            >
                {renderMenuItems()}
            </Menu>}
            {/* {
                (authStore.selectedProject.id !== COMMON_CONSTANT.EMPTY_GUID
                && !hasPermission(getPermissionListNone([SETTINGS_APERMISSIONS.SETTINGS.VIEW], APERMISSION_OBJECTS.SETTINGS, authStore.selectedProject))
                    && hasPermission(getPermissionList(SETTINGS_APERMISSIONS.SETTINGS.ALL, APERMISSION_OBJECTS.SETTINGS, authStore.selectedProject))) && (
                    <Menu mode="inline" className={'left-menu-fixed-bottom'}>
                        <Menu.Item key={'setting-menu-key-1'} title={null}>
                            <Link to={'/settings'} rel="noopener noreferrer">
                                <img className="ant-menu-item-icon" src={'/public/img/menuIcons/Settings.svg'} />
                                <BaseBlackText>Setting</BaseBlackText>
                            </Link>
                        </Menu.Item>
                    </Menu>
                )
            } */}
        </StyledSider>
    );

    const StyledSiderPC = (
        <StyledSider collapsed={collapsed}>
            {!hideSensitiveInfoWhenNotConfirmConsent && <Menu
                mode="inline"
                className={`main-left-menu ${collapsed ? 'menu-is-collapsed' : 'menu-not-collapsed'}`}
                onClick={handleClick}
                selectedKeys={[current]}
                openKeys={[parent]}
            >
                {renderMenuItems()}
            </Menu>}
            {/* {
                (authStore.selectedProject.id !== COMMON_CONSTANT.EMPTY_GUID
                    && !hasPermission(getPermissionListNone([SETTINGS_APERMISSIONS.SETTINGS.VIEW], APERMISSION_OBJECTS.SETTINGS, authStore.selectedProject))
                    && hasPermission(getPermissionList(SETTINGS_APERMISSIONS.SETTINGS.ALL, APERMISSION_OBJECTS.SETTINGS, authStore.selectedProject))) && (
                    <Menu mode="inline" className={'left-menu-fixed-bottom'}>
                        <Menu.Item key={'setting-menu-key-1'} title={null}>
                            <Link to={'/settings'} rel="noopener noreferrer">
                                <img className="ant-menu-item-icon" src={'/public/img/menuIcons/Settings.svg'} />
                                <BaseBlackText>Setting</BaseBlackText>
                            </Link>
                        </Menu.Item>
                    </Menu>
                )
            } */}
        </StyledSider>
    );

    const StyledSiderMobile = (visible: any) => {
        if (!visible || hideSensitiveInfoWhenNotConfirmConsent) {
            return null;
        }
        return (
            <div className="menu-bar-overlay">
                <StyledSider collapsed={false} style={{ zIndex: 12 }}>
                    <Menu mode="inline" className={'main-left-menu menu-not-collapsed'}>
                        {renderMenuItems()}
                    </Menu>
                    {/* {
                        (authStore.selectedProject.id !== COMMON_CONSTANT.EMPTY_GUID
                            && !hasPermission(getPermissionListNone([SETTINGS_APERMISSIONS.SETTINGS.VIEW], APERMISSION_OBJECTS.SETTINGS, authStore.selectedProject))
                            && hasPermission(getPermissionList(SETTINGS_APERMISSIONS.SETTINGS.ALL, APERMISSION_OBJECTS.SETTINGS, authStore.selectedProject))) && (
                            <Menu mode="inline" className={'left-menu-fixed-bottom'}>
                                < Menu.Item key={'setting-menu-key-1'} >
                                    <Link to={'/settings'} rel="noopener noreferrer">
                                        <img className="ant-menu-item-icon" src={'/public/img/menuIcons/Settings.svg'} />
                                        <BaseBlackText>Setting</BaseBlackText>
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        )
                    } */}
                </StyledSider>
            </div>
        );
    };

    const renderSideMenu = () => {
        if (isPC || isTablet) {
            return collapsed === true ? StyledSiderTablet : StyledSiderPC;
        } else {
            return StyledSiderMobile(visible);
        }
    };

    const handleRenderChildrenProps = () => {
        // if (hideSensitiveInfoWhenNotConfirmConsent) {
        //     return <></>
        // }
        return <Outlet />;
    };

    const displayICP = ENV_CONFIG.DISPLAY_ICP ? ENV_CONFIG.DISPLAY_ICP.toLowerCase() === 'true' : false;
    return (
        <div ref={ref}>
            <StyledLayout className={`layout-wrapper ${device}`}>
                <PrivateHeader
                    hideSensitiveInfoWhenNotConfirmConsent={hideSensitiveInfoWhenNotConfirmConsent}
                    onVisible={onVisible}
                    isPc={isPC}
                    collapse={collapsed}
                    visible={visible}
                    isTablet={isTablet}
                    onCollapse={onCollapsed}
                />
                <Layout>
                    {renderSideMenu()}
                    <Layout style={{ padding: '0' }}>
                        <Content className={'layout-content'}>{handleRenderChildrenProps()}</Content>
                        <PrivateFooter displayICP={displayICP} cdnEndpoint={ENV_CONFIG.CDN_URL} visibleConsent={hideSensitiveInfoWhenNotConfirmConsent}/>
                    </Layout>
                </Layout>
                {ENV_CONFIG.ENABLE_CONSENT === 'true' && <InitConsentDialog />}
            </StyledLayout>

            <AppService />
            <SubscribeUserChange />
            <SubscribeProjectListChange />
        </div>
    );
});

export { AdminLayout };

