import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { TermItemModel, TERM_CODE } from "./TermsAndCondionsModels";
import { useTranslation } from "react-i18next";

const MapTermCodeToSrc = {
    [TERM_CODE.CLOUD_SERVICE]: 'term-conditions/cloud-service-agreement.html',
    [TERM_CODE.SERVICE_LEVEL]: 'term-conditions/service-level-objective.html',
    [TERM_CODE.DATA_PROCESSING]: 'term-conditions/data-processing-addendum.html',
    [TERM_CODE.SPECIFIC_LICENSE]: 'term-conditions/specific-license-term.html',
    [TERM_CODE.GENERAL]: 'term-conditions/general-specification.html',
};

interface TermsAndConditionsDialogProps {
    visible: boolean;
    cdnEndpoint: string;
    item: TermItemModel;
    onHide: () => void;
}

function TermsAndConditionsDialog(props: TermsAndConditionsDialogProps) {
    const { t } = useTranslation();
    const { item, visible, cdnEndpoint, onHide } = props;
    const [loading, setLoading] = useState<boolean>(true)

    // handle events
    const onLoadIframe = () => setLoading(false)

    // render
    let termSrc = MapTermCodeToSrc[item.code] || ''
    if (termSrc) {
        let endPoint = cdnEndpoint;
        if (endPoint.endsWith('/')) {
            endPoint = endPoint.substring(0, endPoint.length - 1);
        }
        termSrc = `${endPoint}/${termSrc}`;
    }

    return (
        <Dialog
            header={t(item?.name)}
            className='custom-dialog custom-asset-dialog-wrap terms-and-conditions-dialog'
            visible={visible}
            maximizable
            dismissableMask
            onHide={() => onHide()}
        >
            <iframe
                title={item.name}
                src={termSrc}
                onLoad={onLoadIframe}
                style={{ visibility: loading ? 'hidden' : 'visible' }}
            />
        </Dialog>
    )
}

export default TermsAndConditionsDialog
