import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import AuthorizedPage from '../AuthorizedPage';

const PARENT_ROUTE = 'model-management';
const CHILD_ROUTES = {
    INDEX: '',
    DETAIL: ':id',
    VIEW_DATAFLOW: ':id/view-dataflow/:dataflowTemplateId/:versionId',
    UPLOAD_MODEL: 'upload-model',
    DATA_SET_TRAINING_DETAIL: 'dataset/:datasetId/training-detail/:trainingJobId/:page',
}

const ModelManagement = lazy(() => import('pages/ModelManagement/ModelManagement'));
const ModelManagementDetail = lazy(() => import('pages/ModelManagement/ModelDetail'));
const ViewDataflow = lazy(() => import('pages/DataPreProcessing/DataFlowTemplate/PreviewDataflowTemplate/index'))
const MlsUploadModel = lazy(() => import('pages/ModelManagement/MlsUploadModel'));
const TrainingJobDetail = lazy(() => import('pages/Dataset/TrainingJobDetail'));

const childRouteObjects: RouteObject[] = [
    {
        path: CHILD_ROUTES.INDEX,
        element: <Outlet />,
        children: [
        {
            index: true,
            element: (
                <AuthorizedPage
                    content={ModelManagement}
                />
            )
        },
        {
            path: CHILD_ROUTES.DETAIL,
            element: (
                <AuthorizedPage
                    content={ModelManagementDetail}
                />
            )
        },
        {
            path: CHILD_ROUTES.VIEW_DATAFLOW,
            element: (
                <AuthorizedPage
                    content={ViewDataflow}
                />
            )
        },
        {
            path: CHILD_ROUTES.UPLOAD_MODEL,
            element: (
                <AuthorizedPage
                    content={MlsUploadModel}
                />
            )
        },
        {
            path: CHILD_ROUTES.DATA_SET_TRAINING_DETAIL,
            element: (
                <AuthorizedPage
                    content={TrainingJobDetail}
                />
            )
        },
        ]
    }
]


export const ModelManagementRoutes: RouteObject = {
    path: PARENT_ROUTE,
    element: <Outlet />,
    children: childRouteObjects
};