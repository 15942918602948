export const APERMISSION_OBJECTS = {
    // TODO: fake, update later
    ASSET_ANALYTIC: 'device',

    ACTIVITY_LOG: 'audit_log',
    SETTINGS: 'setting',
    PROJECT_LIST: 'project',

    USER: 'project',
    ROLE_LIST: 'project',
    USER_GROUP: 'project',

    CONFIGURATION: 'configuration',

    SERVICE_CONNECTION: 'service_connection',
}

// for export button
export const CONFIGURATION_APERMISSIONS = {
    ALL: [],
    SHARE_CONFIGURATION: 'share_configuration'
}

export const ASSET_ANALYTIC_APERMISSIONS = {
    DATA_MODEL: {
        ALL: [
            'read_asset_template',
            'write_asset_template',
            'delete_asset_template'
        ],
        VIEW: 'read_device',
        ADD_UPDATE: 'write_asset_template',
        DELETE: 'delete_asset_template'
    },
    MEDIA_LIST: {
        ALL: [
            'delete_media',
            'read_media'
        ],
        VIEW: 'read_media',
        DELETE: 'delete_media'
    },
    TABLE_LIST: {
        ALL: [
            'read_table',
            'delete_table'
        ],
        VIEW: 'read_table',
        DELETE: 'delete_table'
    }
};

export const DEVICE_MANAGEMENT_APERMISSIONS = {
    DEVICE: {
        ALL: [
            'assign_device',
            'read_device',
            'write_device',
            'delete_device'
        ],
        ASSIGN_DEVICE: 'assign_device',
        VIEW: 'read_device',
        ADD_UPDATE: 'write_device',
        DELETE: 'delete_device'
    },
    DEVICE_TEMPLATE: {
        ALL: [
            'read_device_template',
            'write_device_template',
            'delete_device_template'
        ],
        VIEW: 'read_device_template',
        ADD_UPDATE: 'write_device_template',
        DELETE: 'delete_device_template'
    }
};


export const ALARM_MANAGEMENT_APERMISSIONS = {
    ALARM_LIST: {
        ALL: [
            'read_alarm',
            'annotate_alarm'
        ],
        VIEW: 'read_alarm',
        ANNOTATE_ALARM: 'annotate_alarm'
    },
    ALARM_RULE: {
        ALL: [
            'read_alarm_rule',
            'write_alarm_rule',
            'delete_alarm_rule'
        ],
        VIEW: 'read_alarm_rule',
        ADD_UPDATE: 'write_alarm_rule',
        DELETE: 'delete_alarm_rule'
    },
    ACTION: {
        ALL: [
            'read_action',
            'write_action',
            'delete_action'
        ],
        VIEW: 'read_action',
        ADD_UPDATE: 'write_action',
        DELETE: 'delete_action'
    }
};

export const BROKER_MANAGEMENT_APERMISSIONS = {
    BROKER: {
        ALL: [
            'read_broker',
            'write_broker',
            'delete_broker'
        ],
        VIEW: 'read_broker',
        ADD_UPDATE: 'write_broker',
        DELETE: 'delete_broker'
    },
    INTERGRATION: {
        ALL: [
            'read_integration',
            'write_integration',
            'delete_integration'
        ],
        VIEW: 'read_integration',
        ADD_UPDATE: 'write_integration',
        DELETE: 'delete_integration'
    }
};

export const CONFIGURATION_MANAGEMENT_APERMISSIONS = {
    LOOKUP: {
        ALL: [
            'read_lookup',
            'write_lookup',
            'delete_lookup'
        ],
        VIEW: 'read_lookup',
        ADD_UPDATE: 'write_lookup',
        DELETE: 'delete_lookup'
    },
    LOOKUP_TYPE: {
        ALL: [
            'read_lookup_type',
            'write_lookup_type',
            'delete_lookup_type'
        ],
        VIEW: 'read_lookup_type',
        ADD_UPDATE: 'write_lookup_type',
        DELETE: 'delete_lookup_type'
    },
    UOM: {
        ALL: [
            'read_uom',
            'write_uom',
            'delete_uom'
        ],
        VIEW: 'read_uom',
        ADD_UPDATE: 'write_uom',
        DELETE: 'delete_uom'
    }
};

export const SECURITY_APERMISSIONS = {
    USER: {
        ALL: [
            'assign_project',
            'write_project'
        ],
        VIEW: 'assign_project',
        ADD_UPDATE: 'write_project',
        DELETE: 'write_project'
    },
    USER_GROUP: {
        ALL: [
            'assign_project',
            'write_project'
        ],
        VIEW: 'assign_project',
        ADD_UPDATE: 'write_project',
        DELETE: 'write_project'
    },
    ROLE_LIST: {
        ALL: [
            'assign_project',
            'write_project'
        ],
        VIEW: 'assign_project',
        ADD_UPDATE: 'write_project',
        DELETE: 'write_project'
    }
};

export const ACTIVITY_LOG_APERMISSIONS = {
    ACTIVITY_LOG: {
        ALL: [
            'read_audit_log'
        ],
        VIEW: 'read_audit_log'
    }
};

export const SETTINGS_APERMISSIONS = {
    SETTINGS: {
        ALL: [
            'read_setting',
            'write_setting'
        ],
        VIEW: 'read_setting',
        ADD_UPDATE: 'write_setting'
    }
};

export const PROJECT_LIST_PERMISSIONS = {
    ALL: [
        'assign_project',
        'write_project',
    ],
    ASSIGN: 'assign_project',
    ADD_UPDATE: 'write_project',
    DELETE_PROJECT: 'delete_project',
}

export const IDENTITY_TYPE = {
    ROLE: 'Role',
    USER: 'User',
    GROUP: 'Group'
}