import { COMMON_CONSTANT } from '../../constants';

export interface IProject {
    id: string;
    name: string;
    applicationId: string;
    tenantId: string;
    subscriptionId: string;
}
export class Project implements IProject {
    id = COMMON_CONSTANT.EMPTY_GUID;
    name = '';
    applicationId = '';
    tenantId = '';
    subscriptionId = '';
}

export interface IProjectDetails {
    id: string;
    applicationId: string;
    name: string;
    color: string;
    status: string;
    assetCount: number;
    deviceCount: number;
    metricCount: number;
    createdUtc: string;
    updatedUtc: string;
    deleted: boolean;
    keepInterval: number;
    listIcon: IProjectIcon[];
}
export interface IProjectIcon {
    src: string;
    action: string;
    redirect: string;
    permission: string[];
}

export interface ISelectedProjectInfo {
    id: string;
    name: string;
    [key: string]: any;
}

export interface IProjectPayload {
    name: string;
    color?: string;
    applicationId: string;
    type: string;
}
