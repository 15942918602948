import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ErrorPageWrapper } from "./Styled";
import {observer} from "mobx-react-lite";
import { useRedirect } from 'hooks/useRedirect';
import { APP_ROUTES } from "../../../constants";

const Error404: FC = observer(() => {

  const { redirect } = useRedirect();
  const location: any = useLocation();
  const [backURL, setBackURL] = useState<any>('');

  useEffect(() => {
    if (location && location.state  && location.state.from_url) {
      setBackURL(location.state.from_url);
    }
  }, [location])

  const handleBack = () => {
    if (backURL) {
      redirect(backURL);
    } else {
      redirect(APP_ROUTES.DEFAULT, {ignoreUpdateParentPath: true})
    }
  };


  return (
    <ErrorPageWrapper>
      <div>
        <p className="error-code">404</p>
        <h1>Page Not Found</h1>
        <h4>This page doesn’t exit or is unavailable</h4>
        <Button type="primary" onClick={handleBack}>
          Back
        </Button>
      </div>
    </ErrorPageWrapper>
  );
});

export default Error404;
