import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ENV_CONFIG } from 'config';
import { useAuthenStore } from 'store/Auth/AuthStore';
import { isUuid } from 'utils/utils';
import Error404 from '../ErrorsPage/components/Error404';
import { useProjectManagementStore } from 'store/Home/ProjectManagementStore';
import { useAuth } from 'react-oidc-context';
import { handleError } from '_core/helpers/HandleError';

const ProjectDetailPage: React.FC = observer(() => {
    const { tenantId, subscriptionId, projectId } = useParams();
    const params: any = useParams();
    const authStore = useAuthenStore();
    const projectStore = useProjectManagementStore();
    const [updatedToken, setUpdatedToken] = useState<boolean>(false);
    const [isErrorUrl, setIsErrorUrl] = useState<boolean>(false);
    const auth = useAuth();

    useEffect(() => {
        updateTokenByProjectInfo();
        return () => {
            setUpdatedToken(false);
        };
    }, [projectId, subscriptionId]);

    useEffect(() => {
        for (const key in params) {
            if (!isUuid(params[key])) {
                setIsErrorUrl(true);
            }
        }
    }, [params]);

    const updateTokenByProjectInfo = async () => {
        // Handle when user typing the wrong url
        if (!tenantId || !subscriptionId || !projectId) {
            setIsErrorUrl(true);
            return;
        }
        if (!isUuid(tenantId) || !isUuid(subscriptionId) || !isUuid(projectId)) {
            setIsErrorUrl(true);
            return;
        }

        try {
            // Always renew token when go to project detail
            await authStore.updateAccessToken(tenantId, subscriptionId, projectId, true);
            if (!authStore.user.upn) await authStore.initUserInfo();
            await Promise.all([authStore.initSettingsData(), projectStore.getListProjectsByApplicationId(tenantId, subscriptionId)]);
            let prjInfo: any = {};
            if (!projectStore.selectFromPrjList) {
                prjInfo = await projectStore.getProjectById(projectId);
            }
            const projectInfo = {
                id: projectId,
                subscriptionId: subscriptionId,
                tenantId: tenantId,
                applicationId: ENV_CONFIG.REACT_APPLICATION_ID.toLowerCase(),
                ...prjInfo,
            };
            authStore.selectedProject = {
                ...authStore.selectedProject,
                ...projectInfo,
            };
            setUpdatedToken(true);
        } catch (e) {
            handleError(e);
        }
    };

    const render = () => {
        if (isErrorUrl) return <Error404 />;
        if (!updatedToken) return <></>;
        if (authStore.requireLogin) {
            auth.signoutRedirect({
                state: {
                    redirect: window.location.href.replace(window.location.origin, ''),
                },
            });
            return <></>;
        }
        if (authStore.visibleConsent) return <></>;
        return <Outlet key={Math.random()} />;
    };

    return <>{render()}</>;
});

export default ProjectDetailPage;
