export interface ILanguage {
    code: string;
    name: string;
    nativeName: string;
}

export class Language implements ILanguage {
    code = 'en-US';
    name = 'English';
    nativeName = 'English';
}
