import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import AuthorizedPage from '../AuthorizedPage';

const PARENT_ROUTE = 'data-forwarding';
const CHILD_ROUTES = {
  INDEX: '',
  CREATE: 'create',
  EDIT: ':id/edit',
}

const DataForwarding = lazy(() => import('pages/DataForwarding/DataForwarding'));
const AddEditDataForwarding = lazy(() => import('pages/DataForwarding/components/AddEditDataForwarding'));

const childRouteObjects: RouteObject[] = [
  {
    path: CHILD_ROUTES.INDEX,
    element: <Outlet />,
    children: [
      {
          index: true,
          element: (
              <AuthorizedPage
                  content={DataForwarding}
              />
          )
      },
      {
          path: CHILD_ROUTES.CREATE,
          element: (
              <AuthorizedPage
                  content={AddEditDataForwarding}
              />
          )
      },
      {
          path: CHILD_ROUTES.EDIT,
          element: (
              <AuthorizedPage
                  content={AddEditDataForwarding}
              />
          )
      },
    ]
  }
]


export const DataForwardingRoutes: RouteObject = {
  path: PARENT_ROUTE,
  element: <Outlet />,
  children: childRouteObjects
};