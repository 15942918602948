import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { observer } from 'mobx-react-lite';


export const SigninSilentCallback: React.FC = observer(() => {
    const auth = useAuth();

    useEffect(() => {
        console.log('auth', auth);
        
        auth.signinSilent();

    }, [auth.isAuthenticated]);
    return <></>;
});