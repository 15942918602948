import { APP_CONFIG, HTTP_STATUS } from '_core/constants/config';
import { ENV_CONFIG } from 'config';
import { useLayoutEffect } from 'react';
import { useRouteError } from 'react-router-dom';

const isExcludeRouter = (error: any) => {
    const excludeRedirectUrl = [ENV_CONFIG.REDIRECT_URL, ENV_CONFIG.SILENT_REDIRECT_URL, ENV_CONFIG.LOGOUT_REDIRECT_URL];
    let ignore: boolean = false;
    try {
        excludeRedirectUrl.forEach((redirectUrl) => {
            if (error?.error?.message?.includes(redirectUrl)) ignore = true;
        });
    } catch {}
    return ignore;
};

export const ErrorBoundaryRouter = () => {
    const error: any = useRouteError();
    useLayoutEffect(() => {
        if (error.status === HTTP_STATUS.NOT_FOUND && !isExcludeRouter(error)) {
            window.location.href = APP_CONFIG.ERROR_404_URL;
        }
    }, [error]);
    return <></>;
};
