export enum TYPE_CODE {
    ADTOS = 'ADTOS',
    TOS = 'TOS'
}

export interface ConsentDialog {
    termAndConditionId: string;
    termAndConditionCollectionId: string;
    name: string;
    required: boolean;
    isChecked?: boolean;
    typeCode: TYPE_CODE;
}

export interface ConsentDetailResponse {
    termAndConditionId: string;
    name: string;
    required: boolean;
}

export interface ConfirmConsentRequest {
    isAgree: boolean;
    termAndConditions: { termAndConditionId: string; state: boolean }[];
}

export interface ConsentDialogResponse {
    id: string;
    name: string;
    type: string;
    detail: string;
    status: boolean;
    timezoneId: number;
    timezoneName: string;
    schedules: any[];
    scheduleIds: string[];
    delayAfterTrigger: number;
    delayBetweenActions: number;
    retries: number;
    delayBetweenRetries: number;
    createdBy: string;
    createdUtc: string;
    updatedUtc: string;
    resourcePath: string;
    userId: string;
    userUpn: string;
    content?: string;
    contentPath?: string;
}
