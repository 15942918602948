import { UpOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { YEAR_FORMAT } from "_core/constants/config";
import { BaseBlackText } from "../Themes/baseStyles";
import { ReactComponent as YokogawaIconLogo } from '_core/icons/YokogawaIconLogo.svg';
import { ReactComponent as LogoText } from '_core/icons/YokogawaTextLogo.svg';
import { StyledFooter } from "./AdminLayout.styles";
import { TermItemModel, TERM_CODE } from "./TermsAndConditions/TermsAndCondionsModels";
import TermsAndConditionsDialog from "./TermsAndConditions/TermsAndConditionsDialog";
import { useTranslation } from "react-i18next";
import { ENV_CONFIG } from "config";
import { useAuthenStore } from "store/Auth/AuthStore";
import { Language } from '../../models/ILanguage';

const defaultLanguage = new Language();
const TermItemList: Array<TermItemModel> = [
    {
        name: 'COMMON.FOOTER.CLOUD_SERVICE_AGRREEMENT',
        code: TERM_CODE.CLOUD_SERVICE
    }, {
        name: 'COMMON.FOOTER.SPECIFIC_LICENSE_TERM',
        code: TERM_CODE.SPECIFIC_LICENSE
    }, {
        name: 'COMMON.FOOTER.DATA_PROCESSING_ADDENDUM',
        code: TERM_CODE.DATA_PROCESSING
    }, {
        name: 'COMMON.FOOTER.SERVICE_LEVEL_OBJECTIVE',
        code: TERM_CODE.SERVICE_LEVEL
    }, {
        name: 'COMMON.FOOTER.GENERAL_SPECIFICATION',
        code: TERM_CODE.GENERAL
    }
];

interface IProps {
    cdnEndpoint: string;
    displayICP: boolean;
    visibleConsent: boolean;
}

const PrivateFooter: React.FC<IProps> = (props: IProps) => {
    const { t } = useTranslation();
    const { displayICP = true, cdnEndpoint = '', visibleConsent = false } = props;
    const [year, setYear] = useState<string>('');
    // terms and conditions
    const [showTermDialog, setShowTermDialog] = useState<any>(false);
    const [termItem, setTermItem] = useState<any>(TermItemList[0]);
    const authStore = useAuthenStore();

    // effect
    useEffect(() => {
        const currentTime = new Date().getTime();
        const currentYear = moment(currentTime).format(YEAR_FORMAT);
        setYear(currentYear);
    }, []);

    // handle events
    const handleTermItemClicked = (item: TermItemModel) => {
        if (visibleConsent) return;
        setShowTermDialog(true);
        setTermItem(item);
    }

    // render
    const termsMenu = () => {
        const menuItemList = TermItemList.map((item: TermItemModel, i: number) => (
            <Menu.Item key={i} onClick={() => handleTermItemClicked(item)}>
                {t(item.name)}
            </Menu.Item>
        ))

        return (
            <Menu theme='light' className='profile-menu'>
                {menuItemList}
            </Menu>
        )
    }

    const getReleaseChangeLogUrl = () => {
        const { preferredLanguage = defaultLanguage } = authStore.user;
        const currentLanguageCode = preferredLanguage.code.split('-')[1] || 'US';
        const releaseChangeLogUrl = ENV_CONFIG.RELEASE_CHANGE_LOG_URL?.replace('{{countryCode}}', currentLanguageCode.toLowerCase());
        return releaseChangeLogUrl;
    };

    const renderReleaseChangeLog = (): JSX.Element => {
        if (!ENV_CONFIG.RELEASE_VERSION || !ENV_CONFIG.RELEASE_CHANGE_LOG_URL) return <></>;

        return <a className='release-change-log_link mr-3' href={getReleaseChangeLogUrl()} target='_blank' rel='noreferrer'>
            <BaseBlackText>{`R ${ENV_CONFIG.RELEASE_VERSION}`}</BaseBlackText>
        </a>;
    };

    const colClass = displayICP ? `col-sm-12 cl-md-12 col-lg-4 footer-col-items` : `col-sm-12 cl-md-12 col-lg-6 footer-col-items`;
    return (
        <StyledFooter>
            <div className="row pl-0 pr-0">
                <div className={`${colClass} footer-col-items-left pl-0 pr-0`}>
                    <div className="footer-item-menus" style={{ display: "inline-flex" }}>
                        <div className="menu-footer-item" style={{ marginTop: 2, display: "inline-flex" }}>
                            <LogoText /> <YokogawaIconLogo />
                        </div>
                        <div className='menu-footer-item'>
                            <Dropdown overlay={termsMenu}>
                                <div className='cursor-pointer'>
                                    <BaseBlackText>{t('COMMON.FOOTER.TERM_CONDITION')} <UpOutlined /></BaseBlackText>
                                </div>
                            </Dropdown>
                        </div>
                        <div className='menu-footer-item'>
                            {!visibleConsent ? (
                                <a href='https://yokogawa.com/ahi' target='_blank' rel='noreferrer'>
                                    <BaseBlackText>{t('COMMON.FOOTER.SITEMAP')}</BaseBlackText>
                                </a>
                            ) : (
                                <BaseBlackText>{t('COMMON.FOOTER.SITEMAP')}</BaseBlackText>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${colClass} footer-col-items-right pl-0 pr-0`}>
                    <div className={`menu-footer-item ${displayICP ? 'text-center' : 'text-right'}`} style={{ width: '100%' }}>
                        {renderReleaseChangeLog()}
                        {t('COMMON.FOOTER.COPPY_RIGHT')}
                    </div>
                </div>
                {displayICP ?
                    <div className={`${colClass} footer-col-items-right pl-0 pr-0`}>
                        <div className='menu-footer-item text-right' style={{ width: '100%' }}>
                            <a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=%5b19010412-3%5d' target='_blank' rel='noreferrer'>
                                <BaseBlackText>沪ICP备19010412号-3</BaseBlackText>
                            </a>
                            <span className='icp-logo-wrapper'>
                                <img
                                    src={`${cdnEndpoint}/term-conditions/assets/images/icp-logo.png`}
                                    className='icp-logo'
                                    alt=''
                                />
                                {`沪公网安备 号`}
                            </span>
                        </div>
                    </div>
                    : null
                }
            </div>
            <TermsAndConditionsDialog
                visible={showTermDialog}
                cdnEndpoint={cdnEndpoint}
                item={termItem}
                onHide={() => setShowTermDialog(false)}
            />
        </StyledFooter>
    )
}

export { PrivateFooter };

