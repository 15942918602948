// Import prime css
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

// import antd
import 'antd/dist/antd.css';

import '_core/scss/styles.scss';
import '_core/scss/icons/icons.scss';

import ReactDOM from "react-dom";
import App from './App';
import './i18n'

ReactDOM.render(
  <App />,
  document.getElementById("root")
)