import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { observer } from 'mobx-react-lite';


export const SignoutCallback: React.FC = observer(() => {
    const auth = useAuth();

    useEffect(() => {
        auth.clearStaleState();
        auth.signinRedirect();
    }, []);

    return <></>;
});