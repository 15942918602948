import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import AuthorizedPage from '../AuthorizedPage';

const PARENT_ROUTE = 'online-analytic';
const CHILD_ROUTES = {
    INDEX: '',
    CREATE: 'create',
    EDIT: 'edit/:onlineAnalyticId',
    RELEARNING: ':id/relearning',
    FORWARDING: ':id/forwarding',
    DETAIL: 'detail/:onlineAnalyticId/relearning/jobs/:relearningConfig',
    }

const OnlineAnalytic = lazy(() => import('pages/OnlineAnalytic/OnlineAnalytic'));
const CreateEditOnlineAnalytic = lazy(() => import('pages/OnlineAnalytic/CreateEditOnlineAnalytic'));
const ViewDetailOnlineAnalytic = lazy(() => import('pages/OnlineAnalytic/Relearning/RelearningHistoryViewDetail'));
const OnlineAnalyticForwarding = lazy(() => import('pages/OnlineAnalytic/Forwarding/OnlineAnalyticForwarding'));
const Relearning = lazy(() => import('pages/OnlineAnalytic/Relearning'));

const childRouteObjects: RouteObject[] = [
    {
        path: CHILD_ROUTES.INDEX,
        element: <Outlet />,
        children: [
        {
            index: true,
            element: (
                <AuthorizedPage
                    content={OnlineAnalytic}
                />
            )
        },
        {
            path: CHILD_ROUTES.CREATE,
            element: (
                <AuthorizedPage
                    content={CreateEditOnlineAnalytic}
                />
            )
        },
        {
            path: CHILD_ROUTES.EDIT,
            element: (
                <AuthorizedPage
                    content={CreateEditOnlineAnalytic}
                />
            )
        },
        {
            path: CHILD_ROUTES.RELEARNING,
            element: (
                <AuthorizedPage
                    content={Relearning}
                />
            )
        },
        {
            path: CHILD_ROUTES.FORWARDING,
            element: (
                <AuthorizedPage
                    content={OnlineAnalyticForwarding}
                />
            )
        },
        {
            path: CHILD_ROUTES.DETAIL,
            element: (
                <AuthorizedPage
                    content={ViewDetailOnlineAnalytic}
                />
            )
        },
        ]
    }
]


export const OnlineAnalyticRoutes: RouteObject = {
    path: PARENT_ROUTE,
    element: <Outlet />,
    children: childRouteObjects
};