import { notification } from 'antd';
import { v4 as uuid } from 'uuid';
import { SyncOutlined } from '@ant-design/icons';
import { ReactComponent as ToastErrorIcon } from '../../icons/ToastErrorIcon.svg';
import { ReactComponent as ToastWarningIcon } from '../../icons/ToastWarningIcon.svg';
import { ReactComponent as ToastSuccessIcon } from '../../icons/ToastSuccessIcon.svg';

const notificationId = uuid();

const notify = {
    success: (text: string, duration: number = 2) => {
        notification.info({
            key: notificationId,
            message: '',
            icon: <ToastSuccessIcon />,
            top: 80,
            description: text,
            duration,
            onClick: () => notification.close(notificationId),
            className: 'toast-success',
        });
    },
    error: (text: string, duration: number = 4) => {
        notification.info({
            key: notificationId,
            message: '',
            icon: <ToastErrorIcon />,
            top: 80,
            description: text,
            duration,
            onClick: () => notification.close(notificationId),
            className: 'toast-error',
        });
    },
    loading: (text: string, duration: number = 2) => {
        notification.info({
            key: notificationId,
            message: '',
            top: 80,
            icon: <SyncOutlined spin />,
            description: text,
            duration,
            onClick: () => notification.close(notificationId),
            className: 'toast-loading',
        });
    },
    warning: (text: string, duration: number = 4) => {
        notification.info({
            key: notificationId,
            message: '',
            icon: <ToastWarningIcon />,
            top: 80,
            description: text,
            duration,
            onClick: () => notification.close(notificationId),
            className: 'toast-warning',
        });
    }
}

export default notify;
