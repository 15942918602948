import { getPermissionList, getPermissionListNone } from '_core/helpers/PermissionUtils';
import { IProject } from '../models';
import { APP_STORAGE } from '../constants';
import { useMemo } from "react";

let md5 = require('md5');

const checkPermission = (rightFullString: any[]) => {
    let rightHashes: any = localStorage.getItem(APP_STORAGE.RIGHT_HASHES)
        ? localStorage.getItem(APP_STORAGE.RIGHT_HASHES)
        : '';
    let result = false;
    if (rightHashes) {
        rightHashes = JSON.parse(rightHashes);

        if (rightHashes.length && rightFullString.length) {
            rightFullString.forEach((right: any) => {
                let rightMd5 = md5(right);
                rightMd5 = rightMd5.toUpperCase();
                if (!result && rightHashes.includes(rightMd5)) {
                    result = true;
                }
            });
        }
    }
    return result;
};

export function hasPermissionByEntity(rights: any, entity: any, project: IProject) {
    if (checkPermission(getPermissionListNone(rights, entity, project))) {
        return false;
    }
    return checkPermission(getPermissionList(rights, entity, project));
}

export const useHasPermissionByEntity = (rights: any, entity: any, project: IProject) =>
    useMemo(() => hasPermissionByEntity(rights, entity, project), [project]);
