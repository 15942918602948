import React from 'react';
import { makeAutoObservable } from 'mobx';
import { IAppConfirmDialogConfig } from '../../pages/Home/ProjectManagement/AppService/AppConfirmDialog';
import { DefaultPromiseCallback } from '../../models/Common/PromiseCallback';
import { IAppServiceControl } from '../../models/AppService/AppServiceModel';

export class AppServiceStore {
    constructor() {
        makeAutoObservable(this);
    }

    confirmControl: IAppServiceControl = {
        active: false,
        config: null,
        callback: DefaultPromiseCallback,
    };

    confirm(config: IAppConfirmDialogConfig) {
        return new Promise<boolean>((resolve, reject) => {
            this.confirmControl = {
                active: true,
                config,
                callback: { resolve, reject },
            };
        }).catch(() => {
            return Promise.resolve(false);
        });
    }
}

export const appServiceStore = new AppServiceStore();
export const storeContext = React.createContext(appServiceStore);
export const useAppServiceStore = (): AppServiceStore => React.useContext(storeContext);
