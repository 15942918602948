import React from 'react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { t } from 'i18next';
import { APPLICATION_ID, HTTP_STATUS } from '../../config';
import { IProjectDetails, IProjectIcon, ISelectedProjectInfo } from '../../models';
import { PROJECT_LIST_MANAGEMENT } from '_core/constants/message';
import { getSettingConfig } from '_core/helpers/Utils';
import { isHasPermissionByTenant, isNoPermissionByTenant } from '_core/helpers/PermissionUtils';
import { handleError } from '_core/helpers/HandleError';
import {
    APERMISSION_OBJECTS,
    ASSET_ANALYTIC_APERMISSIONS,
    PROJECT_LIST_PERMISSIONS,
} from '_core/constants/permissionConfig';
import { ITenantInfo } from '../../models/ProjectManagement/ITenantInfo';
import projectManagementService from '../../services/Home/ProjectManagementService';
import { PERMISSION_OBJECTS } from "_core/constants/config";
import notify from "_core/helpers/notify";
import { last } from 'lodash';

export class ProjectManagementStore {
    listIcon: IProjectIcon[] = [
        {
            src: '/img/menuIcons/Home.svg',
            action: APERMISSION_OBJECTS.ASSET_ANALYTIC,
            redirect: '/data-model',
            permission: [ASSET_ANALYTIC_APERMISSIONS.DATA_MODEL.VIEW],
        },
    ];
    // hasPermissionDeleteProject: boolean = false; TODO: dose not support permission yet
    hasPermissionDeleteProject: boolean = true;
    listProjects: IProjectDetails[] = [];
    pageSize: number = 0;
    pageIndex: number = 0;
    loading: boolean = false;
    hasErrorRequest: boolean = false;
    forceReload: number = 0;
    hasPermissionCreateProject: boolean = true;
    selectFromPrjList: boolean = false;
    selectedProjectInfo: ISelectedProjectInfo = { id: '', name: '' };

    constructor() {
        makeObservable(this, {
            // Make observable for variable
            listProjects: observable,
            pageSize: observable,
            pageIndex: observable,
            loading: observable,
            setListProject: observable,
            emptyProjectList: observable,
            hasErrorRequest: observable,
            forceReload: observable,
            hasPermissionCreateProject: observable,
            setHasPermissionCreateProject: observable,
            hasPermissionDeleteProject: observable,
            setHasPermissionDeleteProject: observable,
            selectedProjectInfo: observable,
            selectFromPrjList: observable,

            // Make the function to flow (when get data) and to action(when handle logic)
            getListProjectsByApplicationId: action,
            createNewProject: action,
            setLoading: action,
            setHasErrorRequest: action,
            setForceReload: action,
            setSelectedProjectInfo: action,
        });
        this.pageSize = getSettingConfig().pageSize;
    }

    setSelectedProjectInfo = (value: ISelectedProjectInfo) => {
        this.selectedProjectInfo = value;
    };

    setHasPermissionDeleteProject = (value: boolean) => {
        this.hasPermissionDeleteProject = value;
    };

    setForceReload = () => {
        this.forceReload++;
    };

    setLoading = (value: boolean) => {
        this.loading = value;
    };

    setSelectFromPrjList = (value: boolean) => {
        this.selectFromPrjList = value;
    };

    setHasErrorRequest = (value: boolean) => {
        this.hasErrorRequest = value;
    };

    setHasPermissionCreateProject = (value: boolean) => {
        this.hasPermissionCreateProject = value;
    };

    setListProject = (response: any, tenantInfo: any) => {
        this.listProjects = response.data.data.map((val: IProjectDetails) => {
            tenantInfo.projectId = val.id;
            val.listIcon = [];
            this.listIcon.forEach((icon) => {
                const isRestricted = isNoPermissionByTenant(icon.permission, icon.action, tenantInfo);
                const hasPermission = isHasPermissionByTenant(icon.permission, icon.action, tenantInfo);
                if (!isRestricted && hasPermission) {
                    val.listIcon.push(icon);
                }
            });
            return val;
        });
    };

    emptyProjectList = () => {
        this.listProjects = [];
    };

    getListProjectsByApplicationId = async (tenantId: string, subscriptionId: string) => {
        const tenantInfo: ITenantInfo = {
            tenantId,
            subscriptionId,
            applicationId: APPLICATION_ID.toLowerCase(),
        };

        // TODO: dose not support permission yet
        // this.setHasPermissionCreateProject(
        //     this.hasPermission([PROJECT_LIST_PERMISSIONS.ADD_UPDATE], PERMISSION_OBJECTS.PROJECT_LIST, tenantInfo)
        // );
        // this.setHasPermissionDeleteProject(
        //     this.hasPermission([PROJECT_LIST_PERMISSIONS.DELETE_PROJECT], PERMISSION_OBJECTS.PROJECT_LIST, tenantInfo)
        // );

        try {
            this.setLoading(true);
            const response = await projectManagementService.getProjectByApplicationId(tenantInfo.applicationId);
            if (response?.status === HTTP_STATUS.SUCCESS) {
                runInAction(() => {
                    if (response?.data?.data) {
                        this.setListProject(response, tenantInfo);
                    }
                });
            }
        } catch (error) {
            handleError(error);
        } finally {
            this.setLoading(false);
        }
    };

    hasPermission = (rights: string[], entity: string, tenantInfo: ITenantInfo) => {
        return (
            !isNoPermissionByTenant(rights, entity, tenantInfo) && isHasPermissionByTenant(rights, entity, tenantInfo)
        );
    };

    createNewProject = async (data: any) => {
        try {
            const response = await projectManagementService.createProject(data);
            if (response?.status === HTTP_STATUS.CREATED) {
                notify.success(t('MESSAGE.COMMON.CREATING_PROJECT'));
            }
        } catch (error: any) {
            this.setHasErrorRequest(true);
            if (error?.response?.data?.message === PROJECT_LIST_MANAGEMENT.MSG_CODE_DUPLICATE) {
                notify.error(t('MESSAGE.COMMON.NAME.EXIST'));
            } else handleError(error);
        }
    };

    deleteProject = async (project: any, onSuccess: () => void, onError: (error: any) => void, token: string) => {
        this.setLoading(true);
        try {
            await projectManagementService.deleteProject(project.id, token);
            onSuccess();
        } catch (error) {
            onError(error);
        }
        this.setLoading(false);
    };

    getProjectById = async (projectId: string) => {
        try {
            const response = await projectManagementService.getProjectById(projectId);
            return {
                name: response?.data.name,
                logoUrl: response?.data.logoUrl
            };
        } catch (e) {
            handleError(e);
        }
    };
}

export const projectManagementStore = new ProjectManagementStore();
export const storeContext = React.createContext(projectManagementStore);
export const useProjectManagementStore = (): ProjectManagementStore => React.useContext(storeContext);
