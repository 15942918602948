import { NavigateOptions, useNavigate } from 'react-router-dom';
import { useAuthenStore } from 'store/Auth/AuthStore';

export type RedirectOptions = NavigateOptions & {
    ignoreUpdateParentPath?: boolean;
};

const useRedirect = () => {
    const navigate = useNavigate();
    const authStore = useAuthenStore();

    const redirect = (router: string, opts?: RedirectOptions, replace = false) => {
        const newRouter = opts?.ignoreUpdateParentPath ? router : generateRouter(router);
        navigate(newRouter, {...opts, replace});
    };

    const generateRouter = (router: string) => {
        const selectedProject = authStore.selectedProject;
        const parentPath = `/${selectedProject.tenantId}/${selectedProject.subscriptionId}/${selectedProject.id}`;
        return `${parentPath}${router}`;
    };

    return { redirect, generateRouter };
};

export { useRedirect };

