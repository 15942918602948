import { REGEX_CONFIG } from 'config';
import * as _ from 'lodash';

export const cleanLocalCache = (prefix: string) => {
  _.forIn(window.localStorage, (_value: string, objKey: string) => {
      if (_.startsWith(objKey, prefix)) {
          window.localStorage.removeItem(objKey);
      }
  });
};

export const isUuid = (str: string) => {
  if (!str) return false;
  return REGEX_CONFIG.UUID.test(str.trim().toLowerCase());
};