import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { SIGNALR_METHODS, SIGNALR_TYPES } from 'config';
import { useSignalRStore } from 'store/Shared/signalRStore';
import { useAuthenStore } from 'store/Auth/AuthStore';
import { SignalRMessage } from 'models/SignalRMessage';

const SubscribeUserChange: FC = observer(() => {
    const authStore = useAuthenStore();
    const signalRStore = useSignalRStore();
    const [reloadPageCountDown, setReloadPageCountDown] = useState(5);

    useEffect(() => {
        if (!signalRStore.isConnected || !authStore.user.upn) return;

        signalRStore.registerAndReceiveMessage(
            SIGNALR_METHODS.REGISTER_USER_UPN,
            authStore.user.upn,
            SIGNALR_METHODS.RECEIVE_USER_MESSAGE,
            (response: SignalRMessage) => {
                if (!response.upn || response.type !== SIGNALR_TYPES.USER_ACCESS_CONTROL) return;

                // for role base
                if (!authStore.user.upn || authStore.user.upn !== response.upn) return;
                authStore.setPermissionChanged(true);

                // TODO: apply for object base. check url
            }
        );

        return () => {
            signalRStore.unRegisterAndReceiveMessage(
                SIGNALR_METHODS.UNREGISTER_USER_UPN,
                authStore.user.upn,
                SIGNALR_METHODS.RECEIVE_USER_MESSAGE
            );
        };
    }, [signalRStore.isConnected, authStore.user.upn]);

    useEffect(() => {
        if (authStore.permissionChanged) {
            let count = reloadPageCountDown;
            setInterval(() => {
                count = count - 1;
                setReloadPageCountDown(count);
            }, 1000);
        }

        if (authStore.permissionChanged && reloadPageCountDown === 0) {
            reloadPage();
        }
    }, [authStore.permissionChanged, reloadPageCountDown]);

    const reloadPage = async () => {
        // setNotifyPermissionChanged(false);
        // setReloadPageCountDown(5);
        window.location.reload();
    };

    const renderFooterChangeRole = () => {
        return (
            <div>
                <Button label="Reload Now" icon="pi pi-check" onClick={reloadPage} />
            </div>
        );
    };

    return (
        <Dialog
            header={'Updated Permission'}
            className="custom-dialog"
            visible={authStore.permissionChanged}
            footer={renderFooterChangeRole}
            onHide={reloadPage}
        >
            <div className="row m-0 p-0">
                <div className="col-12 p-0 m-0">
                    Your permissions have been changed.
                    <br />
                    The page will be reloaded after {reloadPageCountDown} seconds.
                </div>
            </div>
        </Dialog>
    );
});

export default SubscribeUserChange;
