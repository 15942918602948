import { Button } from "antd";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ErrorPageWrapper } from "./Styled";
import {observer} from "mobx-react-lite";
import { useRedirect } from 'hooks/useRedirect';

const Error500: FC = observer(() => {

  const { redirect } = useRedirect();
  const location: any = useLocation();
  const [backURL, setBackURL] = useState<any>('');

  useEffect(() => {
    if (location && location.state  && location.state.from_url) {
      setBackURL(location.state.from_url);
    }
  }, [location])

  const handleTryAgain = () => {
    redirect(backURL);
  };


  return (
    <ErrorPageWrapper>
      <div>
        <p className="error-code">500</p>
        <h1>Something wents wrong</h1>
        <h4>This page isn’t working. Please try again!</h4>
        <Button type="primary" onClick={handleTryAgain}>
          Try Again
        </Button>
      </div>
    </ErrorPageWrapper>
  );
});

export default Error500;
