import { APP_ROUTES } from '../constants';

export function getMenuProjectItems(project: any) {
    return [
        {
            to: APP_ROUTES.DEFAULT,
            icon: '/img/menuIcons/Home.svg',
            title: 'MLS_COMMON.HOME',
            fontIcon: 'bi-app-indicator',
            isProjectMenu: true,
            children: [],
        },
        {
            to: APP_ROUTES.DATA_MODEL.INDEX,
            icon: '/img/menuIcons/DataModel.svg',
            title: 'MLS.DATA_MODEL.DATA_MODEL',
            fontIcon: 'bi-app-indicator',
            isProjectMenu: true,
        },
        {
            to: APP_ROUTES.MODEL_MAMAGEMENT.INDEX,
            icon: '/img/menuIcons/ModelManagement.svg',
            title: 'MLS.DATA_MODEL.MLMODEL',
            fontIcon: 'bi-app-indicator',
            isProjectMenu: true,
        },
        {
            to: APP_ROUTES.ONLINE_ANALYTIC.INDEX,
            icon: '/img/menuIcons/OnlineAnalytic.svg',
            title: 'MLS.ONLINE_ANALYTIC.ONLINE_ANALYTIC',
            fontIcon: 'bi-app-indicator',
            isProjectMenu: true,
        },
        {
            to: APP_ROUTES.DATA_FORWARDING.INDEX,
            icon: '/img/menuIcons/DataForwarding.svg',
            title: 'MLS.DATA_FORWARDING',
            fontIcon: 'bi-layers',
            isProjectMenu: true,
        },
        {
            to: APP_ROUTES.DATA_PREPROCESSING.INDEX,
            icon: '/img/menuIcons/DataPreprocessing.svg',
            title: 'MLS.DATA_PREPROCESSING.DATA_PREPROCESSING',
            fontIcon: 'bi-layers',
            isProjectMenu: true,
            children: [
                {
                    to: APP_ROUTES.DATA_PREPROCESSING.DATA_FLOW_TEMPLATE.INDEX,
                    icon: '/img/menuIcons/DataflowTemplate.svg',
                    title: 'MLS.DATA_PREPROCESSING.DATA_FLOW_TEMPLATE',
                    fontIcon: 'bi-layers',
                    isProjectMenu: true,
                },
            ],
        },
        // {
        //     to: APP_ROUTES.SECURITY.INDEX,
        //     icon: '/img/menuIcons/Security.svg',
        //     title: 'Security',
        //     fontIcon: 'bi-layers',
        //     isProjectMenu: true,
        //     children: [
        //         {
        //             to: APP_ROUTES.SECURITY.USER.INDEX,
        //             icon: '/img/menuIcons/SecurityUser.svg',
        //             title: 'User',
        //             fontIcon: 'bi-layers',
        //             isProjectMenu: true,
        //         },
        //         {
        //             to: APP_ROUTES.SECURITY.ROLE.INDEX,
        //             icon: '/img/menuIcons/SecurityRole.svg',
        //             title: 'Role',
        //             fontIcon: 'bi-layers',
        //             isProjectMenu: true,
        //         },
        //     ],
        // },
    ];
};
