import { KUBEFLOW_STATUS_LABEL, KUBE_STATUS_CODE, ONLINE_ANALYTIC_STATUS_CODE } from '_core/constants/config';
import { ANALYTIC_STATUS, APP_CONFIG, CONNECTION_STATUS, KUBEFLOW_STATUS, MATH_EXPRESSIONS, REGEX_CONFIG } from 'config/index';
import { t } from 'i18next';
import * as _ from 'lodash';
import { cloneDeep, isNumber } from 'lodash';
import { toJS } from 'mobx';
import { IRegexSettings, ISettings } from 'models/Setting/ISettings';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { QUERY_TYPE } from '../../constants';
import { ITreeNode } from '../../models/DataPreProcessing/DataflowTemplate/IDataflowTemplate';
import authorizedRequest from '../../services/authoriedRequest';
import { RAW_DATA } from '../constants/message';
import { APP_STORAGE } from './../../../src/constants';
import { handleError } from './HandleError';
import notify from './notify';

// capitalize first character of string
export function capitalizeFirstLetter(str: any) {
    if (!str) {
        return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isValidURL(str: string) {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // fragment locator
    return !!pattern.test(str);
}

export function convertByeToKB(size: any) {
    let fileSize = (size / 1024).toFixed(2);
    return fileSize;
}

// get indexOf a json object from array json
export function indexOfJson(rows: any[], row: any): any {
    let index = -1;
    for (var i = 0; i < rows.length; i++) {
        if (JSON.stringify(rows[i]) === JSON.stringify(row)) {
            index = i;
        }
    }
    return index;
}

export function getColumnShowSettings(headerColumns = []) {
    let lastIndexShow = -1;
    for (let i = headerColumns.length - 1; i >= 0; i--) {
        if (lastIndexShow === -1) {
            let element: any = headerColumns[i];
            if (!element.hidden) {
                lastIndexShow = i;
            }
        }
    }
    return lastIndexShow;
}

// trim for all values of object
export function trimAllValuesOfObjects(object: any) {
    let objectTemp: any = {};
    for (const property in object) {
        if (typeof object[property] === 'string') {
            objectTemp[property] = object[property] ? object[property].trim() : '';
        } else {
            objectTemp[property] = object[property];
        }
    }
    return objectTemp;
}

// convert string to expression
export function convertStringToExpression(str: any): any {
    if (!str) {
        return '';
    }

    let resultArr = [];
    let i;
    let lastIsAttr = false;
    let skip = false;
    for (i = 0; i < str.length + 1; i++) {
        let itemString = str.charAt(i);
        let isPushed = false;

        if (skip) {
            skip = false;
            continue;
        }

        if (itemString === '@') {
            lastIsAttr = true;
            isPushed = true;
            resultArr.push('${');
        } else if (itemString === ';' && lastIsAttr) {
            resultArr.push('}$ ;');
            isPushed = true;
            lastIsAttr = false;
        } else if (itemString === '+' && lastIsAttr) {
            resultArr.push('}$+');
            isPushed = true;
            lastIsAttr = false;
        } else if (itemString === '*' && lastIsAttr) {
            resultArr.push('}$*');
            isPushed = true;
            lastIsAttr = false;
        } else if (itemString === '/' && lastIsAttr) {
            resultArr.push('}$');
            lastIsAttr = false;
        } else if (itemString === ',' && lastIsAttr) {
            resultArr.push('}$');
            lastIsAttr = false;
        } else if (itemString === '-' && lastIsAttr) {
            resultArr.push('}$-');
            isPushed = true;
            lastIsAttr = false;
        }

        // For Boolean
        else if (itemString === '=' && lastIsAttr && (str.charAt(i - 1) != '>' || str.charAt(i - 1) != '<')) {
            if (str.charAt(i + 1) == '=') {
                resultArr.push('}$==');
                isPushed = true;
                lastIsAttr = false;
                skip = true;
            }
        } else if (itemString === '>' && lastIsAttr) {
            if (str.charAt(i + 1) == '=') {
                resultArr.push('}$>=');
                isPushed = true;
                lastIsAttr = false;
                skip = true;
            } else {
                resultArr.push('}$>');
                isPushed = true;
                lastIsAttr = false;
            }
        } else if (itemString === '<' && lastIsAttr) {
            if (str.charAt(i + 1) == '=') {
                resultArr.push('}$<=');
                isPushed = true;
                lastIsAttr = false;
                skip = true;
            } else {
                resultArr.push('}$<');
                isPushed = true;
                lastIsAttr = false;
            }
        } else if (itemString === '&' && lastIsAttr) {
            if (str.charAt(i + 1) != '&') {
                resultArr.push('}$&');
                isPushed = true;
                lastIsAttr = false;
            } else {
                skip = true;
                resultArr.push('}$&&');
                isPushed = true;
                lastIsAttr = false;
            }
        } else if (itemString === '|' && lastIsAttr) {
            if (str.charAt(i + 1) != '|') {
                resultArr.push('}$|');
                isPushed = true;
                lastIsAttr = false;
            } else {
                skip = true;
                resultArr.push('}$||');
                isPushed = true;
                lastIsAttr = false;
            }
        }

        //
        else if (itemString === ')' && lastIsAttr) {
            resultArr.push('}$)');
            isPushed = true;
            lastIsAttr = false;
        }

        if (!isPushed) {
            resultArr.push(itemString);
        }
    }

    // add }} if last is attribute
    if (lastIsAttr) {
        resultArr.push('}$');
    }

    const resultStr = resultArr.join('');
    return resultStr;
}

export const bytesToKB = (totalsize: any) => {
    let totalSizeKB: any = 0;
    if (totalsize) {
        totalSizeKB = totalsize / Math.pow(1024, 1);
    }
    return parseFloat(totalSizeKB).toFixed(2);
};

// convert expression with name to id
export const convertExpressionWithNameToIds = (expressionParam: any, listData: any) => {
    let result = '';
    let expressionTemp = _.cloneDeep(expressionParam);
    expressionTemp = removeSplaceOfItemOperator(expressionTemp);

    let expressionArrStart = expressionTemp.split('${');
    let attributesListTemp = toJS(listData) !== undefined ? toJS(listData) : [];
    expressionArrStart.forEach((attrStart: any) => {
        if (attrStart.includes('}$')) {
            let subExpression = '';
            let attrEnds = attrStart.split('}$');
            let i = 0;
            for (; i < attrEnds.length; i++) {
                let itemFor: any = attrEnds[i];
                if (i === 0) {
                    let attArr = attributesListTemp.filter((attr: any) => {
                        return attr.name.trim() === itemFor.trim();
                    });
                    if (attArr.length) {
                        itemFor = '${' + attArr[0].id + '}$';
                    }
                }
                subExpression += itemFor;
            }
            result += subExpression;
        } else {
            result += attrStart;
        }
    });

    return result;
};

// customize replace all function
export function customReplaceAll(str: any, find: any, replace: any): any {
    return str.replace(new RegExp(find, 'g'), replace);
}

export function getMaxHeightTableOfListScreen(toolbarHeight: any, widthScreen: any): any {
    return `calc(100vh - ${toolbarHeight}px - 286px)`;
}

export function getMaxHeightTableOfListScreenHasFilter(toolbarHeight: any, _widthScreen: any): any {
    return `calc(100vh - ${toolbarHeight}px - 36px - 286px)`;
}

export function getMaxHeightTableOfBreadcrumb(toolbarHeight: any, _widthScreen: any): any {
    return `calc(100vh - ${toolbarHeight}px - 36px - 321px)`;
}

export function getMaxHeightBigTableOfMultiTableScreen(toolbarHeight: any, widthScreen: any): any {
    return `calc(100vh - ${toolbarHeight}px - 258px)`;
}

export function getMaxHeightSmallTableOfMultiTableScreen(toolbarHeight: any, widthScreen: any): any {
    return `calc(50vh - ${toolbarHeight / 2}px - 143px)`;
}

export function getMaxHeightTableOfListScreenFormDialog(toolbarHeight: any, widthScreen: any): any {
    return `calc(100vh - ${toolbarHeight}px - 304px)`;
}

export function getMaxHeightBigTableAboveOfAssetControl(toolbarHeight: any, widthScreen: any): any {
    return `calc(50vh - ${toolbarHeight}px - 37px)`;
}

export function getMaxHeightBigTableUnderOfAssetControl(toolbarHeight: any, widthScreen: any): any {
    return `calc(50vh - ${toolbarHeight}px - 219px)`;
}

export function removeSplaceOfItemOperator(express: any): any {
    let result: any = '';
    let expressUPdated = express.split('}$');
    expressUPdated.forEach((item: any) => {
        if (item.toString().includes('${')) {
            result += `${item.toString().trim()}}$ `;
        } else {
            result += `${item.toString().trim()}`;
        }
    });
    return result;
}

// customize replace all function
export function prepareShowMathExpress(str: any): any {
    let strTemp = _.cloneDeep(str);
    MATH_EXPRESSIONS.forEach((element) => {
        let find = element.substring(0, element.length - 1);
        find = find + '\\(';
        strTemp = strTemp.replace(new RegExp(find, 'g'), `#${element}`);
    });
    return strTemp;
}

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

export async function downloadFileByURL(url: string, includeToken?: any) {
    try {
        let urlDownloadFile = url;

        if (!includeToken) {
            // Get token for download file
            let response = await authorizedRequest.post(`/sta/files/token`, {});
            if (response && response.data) {
                const token = response.data.token;
                urlDownloadFile += `?token=${token}`;
            } else {
                notify.error(t('MESSAGE.COMMON.ERROR'));
            }
        }

        // 1. Create blob link to download
        const link: any = document.createElement('a');
        link.href = urlDownloadFile;
        link.setAttribute('target', '_blank');

        // // 2. Append to html page
        document.body.appendChild(link);

        // // 3. Force download
        link.click();

        // 4. Clean up and remove the link
        link.parentNode.removeChild(link);
    } catch (err: any) {
        handleError(err);
    }
}

export function caculateTimeWithNow(datetime: any) {
    // created_at
    let strResult = '';
    if (datetime) {
        const dateJSON: any = getDateSprate(datetime);
        let date = new Date(dateJSON.year, dateJSON.month, dateJSON.day, dateJSON.hours, dateJSON.minutes, dateJSON.seconds);
        date.setHours(date.getHours() + -(date.getTimezoneOffset() / 60));
        let now = new Date();

        // if same year and month
        if (date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth()) {
            let dateDifference = date.getDate() - now.getDate();

            // same day
            if (Math.abs(dateDifference) == 0) {
                if (Math.abs(date.getHours() - now.getHours()) <= 1) {
                    if (Math.abs(date.getMinutes() - now.getMinutes()) <= 1) {
                        if (Math.abs(date.getSeconds() - now.getSeconds()) <= 1) {
                            strResult = 'just now';
                        } else {
                            strResult = moment(date).startOf('second').fromNow();
                        }
                    } else {
                        strResult = moment(date).startOf('minute').fromNow();
                    }
                } else {
                    strResult = moment(date).startOf('hour').fromNow();
                }
            }
            // same week
            else if (dateDifference < 7) {
                strResult = moment(date).startOf('day').fromNow();
            }
            //else
            else {
                strResult = moment(date).format('MMMM Do YYYY, h:mm a');
            }
        } else {
            strResult = moment(date).format('MMMM Do YYYY, h:mm a');
        }
    }
    return strResult;
}

export function convertOffsetToString(offset: any) {
    if (!offset) {
        let local = new Date();
        return local.getTimezoneOffset() / 60;
    }

    if (offset === '0') {
        return 0;
    }

    let offsets = offset.split(':');
    let result: any = '';
    if (offsets.length && offsets.length === 2) {
        let hourNumber = offsets[0];
        let minute = offsets[1];

        result = parseInt(hourNumber) + parseInt(minute) / 60;
    } else {
        result = parseInt(offset);
    }
    return result;
}

export function isFinalUploadFiles(files: any) {
    if (files.length === 0) {
        return true;
    }

    let complatedArr = files.filter((item: any) => {
        return item.isCompleted;
    });
    if (complatedArr.length !== files.length) {
        return true;
    }
    const errorFiles = files.filter((item: any) => {
        return item.isError;
    });
    const successFiles = files.filter((item: any) => {
        return item.completed && item.completed == 100 && !item.isError;
    });

    if (successFiles.length === 0) {
        return true;
    }
    return successFiles.length + errorFiles.length !== files.length;
}

export function isValidFormatWithRegex(regex: any, value: any): boolean {
    if (value && regex) {
        const REGEXT: RegExp = new RegExp(regex);
        return REGEXT.test(value);
    }
    return false;
}

export function getSettingConfig(): ISettings {
    let storageDataSetting: any = localStorage.getItem(APP_STORAGE.SETTINGS_CONFIG) ? localStorage.getItem(APP_STORAGE.SETTINGS_CONFIG) : null;
    storageDataSetting = storageDataSetting ? JSON.parse(storageDataSetting) : {};
    // TODO: get data from localStorage and return

    storageDataSetting.pageIndex = storageDataSetting.pageIndex ? parseInt(storageDataSetting.pageIndex) : APP_CONFIG.PAGE_INDEX;
    storageDataSetting.pageSize = storageDataSetting.pageSize ? parseInt(storageDataSetting.pageSize) : APP_CONFIG.PAGE_SIZE;
    storageDataSetting.unlockTimeout = storageDataSetting.unlockTimeout ? parseInt(storageDataSetting.unlockTimeout) : 60000;
    storageDataSetting.timezoneNumber = storageDataSetting.timezoneNumber ? parseInt(storageDataSetting.timezoneNumber) : 7;
    storageDataSetting.retentionDays = storageDataSetting.retentionDays ? parseInt(storageDataSetting.retentionDays) : 90;
    storageDataSetting.datetimeFormat = storageDataSetting.datetimeFormat ? storageDataSetting.datetimeFormat : '';

    let regex: IRegexSettings = {
        nameRule: REGEX_CONFIG.NAME_INPUT,
    };

    if (storageDataSetting.regex && !_.isEmpty(storageDataSetting.regex)) {
        let regexStorage = storageDataSetting.regex;
        regex.generalRule = regexStorage.generalRule ? regexStorage.generalRule : '';
        regex.deviceRule = regexStorage.deviceRule ? regexStorage.deviceRule : '';
        regex.attributeRule = regexStorage.attributeRule ? regexStorage.attributeRule : '';
        regex.metricRule = regexStorage.metricRule ? regexStorage.metricRule : '';
        regex.tableRule = regexStorage.tableRule ? regexStorage.tableRule : '';
        regex.codeRule = regexStorage.codeRule ? regexStorage.codeRule : '';
        if (!_.isEmpty(regexStorage.nameRule)) regex.nameRule = regexStorage.nameRule;
        regex.abbreviationRule = regexStorage.abbreviationRule ? regexStorage.abbreviationRule : '';
        regex.expressRule = regexStorage.expressRule ? regexStorage.expressRule : '';
    }

    storageDataSetting.regex = regex;
    return storageDataSetting;
}

export function formatFileSize(size: any) {
    var s = '' + Math.floor(size),
        d = size % 1,
        i = s.length,
        r = '';
    while ((i -= 3) > 0) {
        r = ' ' + s.substr(i, 3) + r;
    }
    return s.substr(0, i + 3) + r + (d ? '.' + Math.round(d * Math.pow(10, 2)) : '');
}

export function getPageSizeOptions() {
    const settings = getSettingConfig();
    let number1 = settings.pageSize;
    let number2 = number1 * 2;
    let number3 = number2 * 2;
    let number4 = number3 * 2;
    return [number1, number2, number3, number4];
}

export function searchContains(data = [], field = '', value = '') {
    if (!field || !value) {
        return data;
    }
    let lowerCaseValue = value.toString().toLowerCase();
    const result = data.filter((item: any) => {
        return item[field] && item[field].toString().toLowerCase().includes(lowerCaseValue);
    });
    return result;
}

export function getCondittionFilterOneField(value: any, queryKey: any, queryType = 'text') {
    const config = getSettingConfig();
    let filters: any = {
        and: [
            {
                operation: 'contains',
                queryType: queryType,
                queryKey: `${queryKey}`,
                queryValue: value ? value.toString().toLowerCase() : '',
            },
        ],
    };

    let condittion = {
        pageIndex: 0,
        pageSize: config.pageSize,
        filter: JSON.stringify(filters),
    };

    return condittion;
}

export function handleSearchBody(conditions: IPage) {
    const conditionUpdated = cloneDeep(conditions);
    const filter = conditions.filter && typeof conditions.filter === 'string' ? JSON.parse(conditions.filter) : conditions.filter ? conditions.filter : {};
    const ANDFilters: IQueryFilter[] | undefined = filter?.and;

    if (ANDFilters?.length) {
        const filters: IQueryFilter[] = ANDFilters.map((item: IQueryFilter) => {
            if (item.queryType === QUERY_TYPE.TEXT) {
                item.queryKey = `${item.queryKey}.ToLower()`;
                item.queryValue = item.queryValue ? item.queryValue.toString().toLowerCase() : '';
            }

            if (item.queryType === QUERY_TYPE.DATETIME && item.queryValue) {
                item.queryValue = formatDateForFilter(item.queryValue);
            }

            return item;
        });
        conditionUpdated.filter = JSON.stringify({ and: filters });
    }

    return conditionUpdated;
}

export function handleToLowerSort(condittionUpdated: any, cols: any = []) {
    const sorts = condittionUpdated.sorts ? condittionUpdated.sorts : '';
    let muiltiCol = '';
    if (sorts) {
        let sortCols = sorts.split(',');
        if (sortCols.length > 1) {
            sortCols.forEach((colItem: any) => {
                let sortCol = colItem.split('=');
                if (sortCol.length >= 2) {
                    let colName = sortCol[0];
                    let colItems = cols.filter((itemCol: any) => {
                        return itemCol.queryKey === colName;
                    });

                    // skip for boolean, number, datetime
                    if (colItems.length) {
                        if (colItems[0]?.queryType !== 'boolean' && colItems[0]?.queryType !== 'datetime' && colItems[0]?.queryType !== 'number') {
                            muiltiCol += `${sortCol[0]}.ToLower()=${sortCol[1]},`;
                        } else {
                            muiltiCol += `${sortCol[0]}=${sortCol[1]},`;
                        }
                    }
                }
            });
            return muiltiCol.substring(0, muiltiCol.length - 1);
        } else if (sortCols.length === 1) {
            let sortCol = sortCols[0].split('=');
            if (sortCol.length >= 2) {
                let colName = sortCol[0];
                let colItems = cols.filter((itemCol: any) => {
                    return itemCol.queryKey === colName;
                });

                // skip for boolean, number, datetime
                if (colItems.length) {
                    if (colItems[0]?.queryType !== 'boolean' && colItems[0]?.queryType !== 'datetime' && colItems[0]?.queryType !== 'number') {
                        condittionUpdated.sorts = `${sortCol[0]}.ToLower()=${sortCol[1]}`;
                    }
                }
            }
        }
    }

    return condittionUpdated.sorts;
}

export function equalsText(value1: any, value2: any) {
    if (!value1 && !value2) {
        return true;
    }
    return value1 && value2 && value1.toString().toLowerCase() === value2.toString().toLowerCase();
}

export function includesValues(array: any, value: any) {
    return array.includes(value);
}

export function formatDateForFilter(value: any) {
    const config = getSettingConfig();
    if (!value) {
        return '';
    }
    return moment(value).subtract(config.offset, 'h').format('YYYY-MM-DD HH:mm:ss').replaceAll(' ', 'T') + ':0000';
}

export function isNumberOnly(value: any) {
    if (!value.match(REGEX_CONFIG.INTEGER)) {
        return false;
    } else if (['+', '.', 'e'].includes(value)) {
        return false;
    }
    return true;
}

export function isNumberFloat(value: any) {
    if (value.match(REGEX_CONFIG.FLOAT)) {
        return true;
    }
    return false;
}

export function validateEmail(email: string) {
    return String(email).toLowerCase().match(REGEX_CONFIG.EMAIL);
}

export function getLengthFromDataTypeName(value: any) {
    let textLength = value.split('(');
    if (textLength.length > 1) {
        let textSplit1 = textLength[1].split(')');
        if (textSplit1.length) {
            return parseInt(textSplit1[0]);
        }
    }
    return 255;
}

export function isValidDefaultFormatDate(value: any) {
    if (!value) {
        return false;
    }
    let strs = value.split('/');
    if (strs.length === 5) {
        let year = strs[0];
        let month = strs[1];
        let dayHour = strs[2];
        let dayHours = dayHour.split(' ');
        if (dayHours.length === 2) {
            let day = dayHours[0];
            let hour = dayHours[1];
            let minutes = strs[3];
            let seconds = strs[4];

            if (
                year > 0 &&
                year > 1970 &&
                month >= 1 &&
                month <= 12 &&
                day >= 1 &&
                day <= 31 &&
                hour >= 0 &&
                hour < 24 &&
                minutes >= 0 &&
                minutes < 60 &&
                seconds >= 0 &&
                seconds < 60
            ) {
                return true;
            }
        }
    }
    return false;
}

export function convertFloat(value: any) {
    if (isNumberFloat(value)) {
        return value;
    }
    return '';
}

export function convertToInt(value: any) {
    if (isNumberOnly(value)) {
        return value;
    }
    return '';
}

export function handleGetPageIndex(curentPage: any) {
    let page = toJS(curentPage);
    if (page === 0 || page === '0') {
        return 0;
    }
    return page - 1;
}

export function handleReverseOrderSort(field = '', condittions: any) {
    let condittionsClone = _.cloneDeep(condittions);
    const sorts = condittionsClone.sorts ? condittionsClone.sorts : '';
    const sortList = sorts.split(',');

    let result = '';
    sortList.forEach((e: any) => {
        let eClone = _.cloneDeep(e);
        if (eClone) {
            let itemArr = e?.split('=');
            if (itemArr.length && itemArr.length >= 2) {
                if (itemArr[0] === field && itemArr[1] === 'asc') {
                    eClone = `${field}=desc`;
                } else if (itemArr[0] === field && itemArr[1] === 'desc') {
                    eClone = `${field}=asc`;
                }
            }
        }
        result += eClone + ',';
    });

    if (result) {
        condittionsClone.sorts = result.trim().substring(0, result.length - 1);
    }
    return condittionsClone;
}

export function getFileNameFromUrl(url: any) {
    let name = '';
    if (url) {
        // Use regex to match the name from url
        const regex = url.toString().match(/[^/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/);
        if (regex && regex.length > 1) {
            name = regex[0];
        }
    }
    return name;
}

export function convertSortArrayToString(sorts: any) {
    var result: any = '';
    if (!sorts || (sorts && !sorts.length)) {
        return null;
    }
    sorts.forEach((sort: any) => {
        if (sort.order !== 0) {
            result += `${sort.field}=${sort.order > 0 ? 'asc' : 'desc'},`;
        }
    });
    // clear , character
    result = result.substring(0, result.length - 1);
    return result;
}

export function getDateSprate(str: any) {
    let year: any, month: any, day: any, hours: any, minutes: any, seconds: any, miliseconds: any;
    if (!str) {
        return null;
    } else if (str && !str.toString().startsWith('0001-01-01')) {
        // init year, month, day
        let arr1 = str?.split('T');
        if (arr1.length) {
            let arr2 = arr1[0]?.split('-');
            if (arr2.length >= 3) {
                year = parseInt(arr2[0]);
                month = parseInt(arr2[1]) > 0 ? parseInt(arr2[1]) - 1 : parseInt(arr2[1]);
                day = parseInt(arr2[2]);
            }
        }

        // init hours, minutes, seconds, miliseconds
        if (arr1.length >= 2) {
            let arr3 = arr1[1]?.split(':');
            if (arr3.length >= 3) {
                hours = parseInt(arr3[0]);
                minutes = parseInt(arr3[1]);
                seconds = parseInt(arr3[2]);
                miliseconds = arr3[3];

                if (miliseconds && miliseconds.toString().length > 3) {
                    miliseconds = miliseconds.toString().substring(0, 3);
                }
            }
        }

        return {
            year,
            month,
            day,
            hours,
            minutes,
            seconds,
            miliseconds,
        };
    }
    return null;
}

export function convertLocalDateToUTC(date: any) {
    let currentTimeZone = date.getTimezoneOffset() / 60;
    if (currentTimeZone > 0) {
        date.setHours(date.getHours() - currentTimeZone);
    } else if (currentTimeZone < 0) {
        date.setHours(date.getHours() + currentTimeZone);
    }

    return date;
}

export function formatDatetimeBySettings(date: any) {
    const dateJSON = getDateSprate(date);
    if (!dateJSON) return date;

    let dateFormated = _.cloneDeep(date);
    const config = getSettingConfig();
    const { datetimeFormat, offset } = config;

    if (datetimeFormat) {
        const utlDate: any = Date.UTC(dateJSON.year, dateJSON.month, dateJSON.day, dateJSON.hours, dateJSON.minutes, dateJSON.seconds, dateJSON.miliseconds);
        dateFormated = moment(utlDate)
            .utcOffset(offset || 0)
            .format(datetimeFormat);
    } else {
        const localDatetime = new Date(dateJSON.year, dateJSON.month, dateJSON.day, dateJSON.hours, dateJSON.minutes, dateJSON.seconds, dateJSON.miliseconds);
        dateFormated = offset ? moment(localDatetime).utcOffset(offset).toLocaleString() : moment(localDatetime).toLocaleString();
    }

    return dateFormated;
}

export const stringFormat = (str: string, ...args: any[]) => {
    // Function for string format
    // Ex: stringFormat('this is {0}', 'dog') => this is dog
    return str.replace(/{(\d+)}/g, (match, num: number) => {
        return typeof args[num] !== 'undefined' ? args[num] : match;
    });
};

export const convertFloat4ToInt = (value: any) => {
    if (!value) {
        return '';
    }
    const temp = value.toString();
    if (temp && temp.includes('e')) {
        const arr = temp.split('e');
        const arr2 = arr[0].split('.');
        if (arr2[1]) {
            var length = arr2[1].length;
            let a = (arr[0] * Math.pow(10, length)).toString();
            if (arr[1].charAt(0) === '-') {
                let pow = arr[1].slice(1);
                let b = '0.';
                for (var i = 1; i <= pow - length; i++) {
                    b = b + '0';
                }
                a = b + a;
                return a;
            }
            if (arr[1].charAt(0) === '+') {
                let pow = arr[1].slice(1);
                for (var i = 1; i <= pow - length; i++) {
                    a = a + '0';
                }
                return a;
            }
        } else {
            let a = arr2[0];
            if (arr[1].charAt(0) === '-') {
                let pow = arr[1].slice(1);
                let b = '0.';
                for (var i = 1; i <= pow - 1; i++) {
                    b = b + '0';
                }
                a = b + a;
                return a;
            }
            if (arr[1].charAt(0) === '+') {
                let pow = arr[1].slice(1);
                for (var i = 1; i <= pow; i++) {
                    a = a + '0';
                }
                return a;
            }
        }
    } else {
        return temp;
    }
};

export const convertToFloat = (value: any) => {
    let totalDecimals = 0;
    if (!(Math.floor(value) === value)) {
        totalDecimals = value.toString().split('.')[1].length;
    }

    return value.toFixed(totalDecimals);
};

export const sortIdBeforeExport = (originArr: any, filterArr: any) => {
    if (originArr && originArr.length && originArr[0].code) {
        const conditionArr = originArr.filter((x: { code: string }) => filterArr.includes(x.code));
        return conditionArr.map((x: { code: string }) => x.code);
    } else {
        const conditionArr = originArr.filter((x: { id: string }) => filterArr.includes(x.id));
        return conditionArr.map((x: { id: string }) => x.id);
    }
};

export function getRandomInt(max: any) {
    return Math.floor(Math.random() * max);
}

export function getFirstChar(str: any) {
    if (!str) {
        return '';
    }
    return str.toString().charAt(0);
}

export function randomNormalDistribution(min: number, max: number, skew: number) {
    let u = 0,
        v = 0;
    while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
    while (v === 0) v = Math.random();
    let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);

    num = num / 10.0 + 0.5; // Translate to 0 -> 1
    if (num > 1 || num < 0) num = randomNormalDistribution(min, max, skew);
    // resample between 0 and 1 if out of range
    else {
        num = Math.pow(num, skew); // Skew
        num *= max - min; // Stretch to fill range
        num += min; // offset to min
    }
    return num;
}

export const handleSize = (size: any) => {
    if (!isNumber(size)) return size;
    return Number(size.toFixed(2)) + 'MB';
};

export const convertKubeflowStatusToTooltip = (status: string) => {
    if (!status) return '';
    if (status === KUBEFLOW_STATUS.ERROR) {
        return 'MLS.DATA_PREPROCESSING.STATUS_TESTING.ERROR';
    } else if (status === KUBEFLOW_STATUS.STOPPED) {
        return 'MLS.DATA_PREPROCESSING.STATUS_TESTING.STOPPED';
    } else if (status === KUBEFLOW_STATUS.IN_PROGRESS) {
        return 'MLS.DATA_PREPROCESSING.STATUS_TESTING.INPROGRESS';
    } else if (status === KUBEFLOW_STATUS.STOPPING) {
        return 'MLS.DATA_PREPROCESSING.STATUS_TESTING.STOPPING';
    } else if (status === KUBEFLOW_STATUS.SUCCESSFUL) {
        return 'MLS.DATA_PREPROCESSING.STATUS_TESTING.SUCCESSFUL';
    } else if (status === KUBEFLOW_STATUS.INITIALIZING) {
        return 'MLS.DATA_PREPROCESSING.STATUS_TESTING.INITIALIZING';
    } else {
        return '';
    }
};

export const convertConnectionStatus = (status: string) => {
    if (!status) return '';
    if (status === CONNECTION_STATUS.CONNECTED) {
        return 'MLS.CONNECTION_STATUS.CONNECTED';
    } else if (status === CONNECTION_STATUS.DISCONNECTED) {
        return 'MLS.CONNECTION_STATUS.DISCONNECTED';
    } else if (status === CONNECTION_STATUS.NA) {
        return 'MLS.CONNECTION_STATUS.NA';
    } else {
        return '';
    }
};

export const getCountStatus = (data: any, isModel = false) => {
    const statusCount = ['inProgressCount', 'initializingCount', 'stoppingCount'];
    if (isModel) statusCount.push('successfulCount');

    let total = 0;
    statusCount.forEach((v) => {
        if (data && isNumber(data[v])) total += Number(data[v]);
    });
    return total;
};

export const handleErrorFromAM = (error: any) => {
    if (!error) return;
    const message = error?.response?.data?.message;
    if (!error?.response) {
        notify.error(t('MESSAGE.COMMON.ERROR'));
        return;
    }
    switch (message) {
        case RAW_DATA.DATASET_CONNECTION_GET_FAILED:
            notify.error(t(RAW_DATA.CONNECTION_DELETED));
            break;
        case RAW_DATA.DATASET_ASSET_GET_FAILED:
            notify.error(t(RAW_DATA.INVALID_ASSET));
            break;
        case RAW_DATA.DATASET_ASSET_ATTRIBUTE_GET_FAILED:
            notify.error(t(RAW_DATA.INVALID_ATTRIBUTE));
            break;
        case RAW_DATA.DATASET_ASSET_TIMESERIES_GET_FAILED:
            notify.error(t(RAW_DATA.INVALID_AM_CONNECTION));
            break;

        default:
            notify.error(t(RAW_DATA.INVALID_AM_CONNECTION));
            break;
    }
};

export function cleanLocalCache() {
    _.forIn(window.localStorage, (value: string, objKey: string) => {
        if (_.startsWith(objKey, APP_STORAGE.PREFIX)) {
            window.localStorage.removeItem(objKey);
        }
    });
}

function fallbackCopyTextToClipboard(text: string) {
    var textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (err) {}
    document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    return navigator.clipboard.writeText(text);
}

export function getStringSizeInBytes(str: string): number {
    return new TextEncoder().encode(str).length;
}

export function createSearchCriterial(queryKey: string, queryType: string, operation: string, queryValue: any) {
    return {
        queryKey,
        queryType,
        operation,
        queryValue,
    };
}

export function showDateFormatForFilter(value: any, format: string = 'YYYY-MM-DD HH:mm:ss') {
    if (!value) {
        return '';
    }
    return moment(value).format(format);
}

export function useDebounce(value: string, delay: number = 100) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

export const getNodeById = (treeData: ITreeNode[], nodeId: string) => {
    let stack: ITreeNode[] = [...treeData];
    while (stack.length > 0) {
        let node = stack.pop();
        if (node?.key === nodeId || node?.id === nodeId) {
            return node;
        } else if (node?.children?.length) {
            for (let nodeChildren of node.children) {
                stack.push(nodeChildren);
            }
        }
    }
    return null;
};

export const convertKubeStatusIdToName = (id: string) => {
    let kubeStatusName = KUBEFLOW_STATUS.INITIALIZING;
    switch (id?.toUpperCase()) {
        case KUBE_STATUS_CODE.STOPPED:
            kubeStatusName = KUBEFLOW_STATUS.STOPPED;
            break;
        case KUBE_STATUS_CODE.ERROR:
            kubeStatusName = KUBEFLOW_STATUS.ERROR;
            break;
        case KUBE_STATUS_CODE.STOPPING:
            kubeStatusName = KUBEFLOW_STATUS.STOPPING;
            break;
        case KUBE_STATUS_CODE.SUCCESSFUL:
            kubeStatusName = KUBEFLOW_STATUS.SUCCESSFUL;
            break;
        case KUBE_STATUS_CODE.IN_PROGRESS:
            kubeStatusName = KUBEFLOW_STATUS.IN_PROGRESS;
            break;
        default:
            kubeStatusName = KUBEFLOW_STATUS.INITIALIZING;
            break;
    }
    return kubeStatusName;
};

export const convertOnlineAnalyticStatusIdToName = (id: string) => {
    let statusName = ANALYTIC_STATUS.NOT_PREDICTED;
    switch (id?.toUpperCase()) {
        case ONLINE_ANALYTIC_STATUS_CODE.ERROR:
            statusName = ANALYTIC_STATUS.ERROR;
            break;
        case ONLINE_ANALYTIC_STATUS_CODE.SUCCESSFUL:
            statusName = ANALYTIC_STATUS.SUCCESSFUL;
            break;
        default:
            statusName = ANALYTIC_STATUS.NOT_PREDICTED;
            break;
    }
    return statusName;
};

export const convertFileSizeUnit = (bytes: number) => {
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    let i = 0;
    while (bytes >= 1024 && i < units.length - 1) {
        bytes /= 1024;
        i++;
    }

    if (Number.isInteger(bytes)) {
        return bytes + ' ' + units[i];
    } else {
        return bytes.toFixed(2) + ' ' + units[i];
    }
};

export const convertKubeStatusLabelToName = (label: string) => {
    let kubeStatusName = ""
    switch (label) {
        case KUBEFLOW_STATUS_LABEL.STOPPED:
            kubeStatusName = "MLS.DATA_PREPROCESSING.STATUS_TESTING.STOPPED";
            break;
        case KUBEFLOW_STATUS_LABEL.ERROR:
            kubeStatusName = "MLS.DATA_PREPROCESSING.STATUS_TESTING.ERROR";
            break;
        case KUBEFLOW_STATUS_LABEL.STOPPING:
            kubeStatusName = "MLS.DATA_PREPROCESSING.STATUS_TESTING.STOPPING";
            break;
        case KUBEFLOW_STATUS_LABEL.SUCCESSFUL:
            kubeStatusName = "MLS.DATA_PREPROCESSING.STATUS_TESTING.SUCCESSFUL";
            break;
        case KUBEFLOW_STATUS_LABEL.IN_PROGRESS:
            kubeStatusName = "MLS.DATA_PREPROCESSING.STATUS_TESTING.INPROGRESS";
            break;
        default:
            kubeStatusName = "MLS.DATA_PREPROCESSING.STATUS_TESTING.INITIALIZING";
            break;
    }
    return kubeStatusName;
};

