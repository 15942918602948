import { IProject, IProjectPayload } from '../../models';
import { ISearchResult } from 'models/ISearchResult';
import authorizedRequest from '../authoriedRequest';
import { ENV_CONFIG } from '../../config';
import httpDynamicClient from '../httpDynamicClient';
import {PROJECT_TYPE} from "../../constants";

class ProjectManagementService {
    getProjectByApplicationId = async (applicationID: any) => {
        return authorizedRequest.get(`/prj/applications/${applicationID}/projects?type=${PROJECT_TYPE.ASSET_ANALYTIC}&status=ac,pd`);
    };

    searchProjectByApplicationId = async (applicationID: any, body: any) => {
        return authorizedRequest.post(`/prj/applications/${applicationID}/projects?type=${PROJECT_TYPE.ASSET_ANALYTIC}`, body);
    };

    createProject = async (data: any) => {
        return authorizedRequest.post(`/prj/projects`, data);
    };

    deleteProject = async (projectId: string, token: string) => {
        return authorizedRequest.delete(`/prj/projects/${projectId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
    };

    updateProject = async (projectId: string, payload: IProjectPayload) => {
        return authorizedRequest.put(`/prj/projects/${projectId}`, payload);
    };

    searchProjects = (token: string) => {
        httpDynamicClient.setToken(token);
        const REACT_APPLICATION_ID = ENV_CONFIG.REACT_APPLICATION_ID.toLowerCase();
        return httpDynamicClient.get<ISearchResult<IProject>>(
            `/prj/applications/${REACT_APPLICATION_ID}/projects?type=${PROJECT_TYPE.ASSET_ANALYTIC}&status=ac,pd`
        );
    };

    getProjectById = async (projectId: string) => {
        return authorizedRequest.get(`/prj/projects/${projectId}`);
    };
}

const projectManagementService = new ProjectManagementService();

export default projectManagementService;
