export interface ITimezone {
    description: string;
    id: number;
    name: string;
    offset: string;
}

export class Timezone implements ITimezone {
    description = '(UTC+08:00) Kuala Lumpur, Singapore';
    id = 108;
    name = 'Singapore Standard Time';
    offset = '+08:00';
}